import AppTypography from '@components/_common/AppTypography';

const useFormUtilities = () => {
  const renderLabelChildren = ({ label, children }) => (
    <>
      <AppTypography mb={0.75} i={label} />
      {children}
    </>
  );

  const renderLabelValue = ({ label, value }) =>
    renderLabelChildren({
      label,
      children: (
        <AppTypography
          variant="subtitle1"
          children={value || '-'}
          sx={{ whiteSpace: 'pre-wrap' }}
        />
      ),
    });

  return {
    renderLabelChildren,
    renderLabelValue,
  };
};

export default useFormUtilities;
