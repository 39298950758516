import { Route, Routes } from 'react-router-dom';
import FooterPage from './Footer';
import withPage from '@hocs/with-page';

const Footer = () => {
  return (
    <Routes>
      <Route index Component={FooterPage} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '網頁底部',
    pathname: '/footer',
  },
};

export default withPage(page)(Footer);
