import React from 'react';
import { Typography } from '@mui/material';
import useI18n from '@hooks/use-i18n';

const AppTypography = ({
  i,
  center,
  uppercase,
  bold,
  sx,
  children,
  ...props
}) => {
  const { __i } = useI18n();
  return (
    <Typography
      sx={{
        ...sx,
        textAlign: center ? 'center' : undefined,
        textTransform: uppercase ? 'uppercase' : undefined,
        fontWeight: bold ? 700 : undefined,
      }}
      {...props}
    >
      {i ? __i(i) : children}
    </Typography>
  );
};

export default AppTypography;
