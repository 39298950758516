const articleCategories = [
  {
    label: '醫病錢',
    value: 'Treatment',
  },
  {
    label: '護理錢',
    value: 'Care',
  },
  {
    label: '點講數',
    value: 'Discuss',
  },
];

export default articleCategories;
