import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import BaseLayout from '@components/layouts/BaseLayout';
import AppButton from '@components/_common/AppButton';
import { Box } from '@mui/material';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';
import FormSelectOptions from '@components/forms/FormSelectOptions';
import Reorder from '@components/_common/Reorder';

const SelectOptionCategoryList = ({ title, category }) => {
  const appService = useAppService();
  const [selectOptions, setSelectOptions] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);

  const getSelectOptionList = useCallback(() => {
    appService.selectOption
      .getSelectOptionList({ category, pageSize: 99999 })
      .then(res => {
        setSelectOptions(res.items);
      }, appService.handleError);
  }, [category, appService]);

  useEffect(() => {
    getSelectOptionList();
  }, [getSelectOptionList]);

  const getReorderContents = () => {
    return (
      selectOptions?.map(x => ({
        key: x.id,
        name: x.name,
      })) || []
    );
  };

  const handleReorder = reorderedContents => {
    const names = reorderedContents.map(x => x.name);
    appService.selectOption
      .updateSelectOptions({
        category,
        selectOptions: names, // prettier-ignore
      })
      .then(setSelectOptions)
      .catch(appService.handleError);
  };

  console.log('selectOptions', selectOptions);
  return !selectOptions ? (
    <AppLoadingSpinner mt={5} />
  ) : (
    <Formik
      initialValues={{
        selectOptions: selectOptions.map(x => x.name).join(','), // prettier-ignore
      }}
      onSubmit={(values, { setSubmitting }) => {
        appService.selectOption
          .updateSelectOptions({
            category,
            selectOptions: values.selectOptions.split(',').filter(x => !!x), // prettier-ignore
          })
          .then(res => {
            setSubmitting(false);
            setEditMode(false);
            enqueueSnackbar(`選項已更新`);
            setSelectOptions(res);
          })
          .catch(err => {
            setSubmitting(false);
            appService.handleError(err);
          });
      }}
      enableReinitialize
    >
      {formProps => {
        const { values, setFieldValue, handleSubmit, isSubmitting } = formProps;
        return (
          <BaseLayout
            title={title}
            headerRight={
              <>
                {editMode ? (
                  <AppButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                  >
                    更新
                  </AppButton>
                ) : (
                  <>
                    <Reorder
                      iconSize="medium"
                      title="更改排序"
                      items={getReorderContents()}
                      onUpdate={handleReorder}
                    />
                    <AppButton onClick={() => setEditMode(true)}>
                      編輯
                    </AppButton>
                  </>
                )}
              </>
            }
            noMb
          >
            <form onSubmit={handleSubmit}>
              <Box mt={1.5}>
                <FormSelectOptions
                  selectOptions={values.selectOptions
                    .split(',')
                    .filter(x => !!x)
                    .map(x => x.trim())}
                  onChange={selectOptions =>
                    setFieldValue('selectOptions', selectOptions.join(','))
                  }
                  disabled={!editMode}
                />
              </Box>
            </form>
          </BaseLayout>
        );
      }}
    </Formik>
  );
};

export default SelectOptionCategoryList;
