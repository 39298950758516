import { Navigate, Route, Routes } from 'react-router-dom';
import MemberList from '@pages/member/MemberList';
import MemberDetails from '@pages/member/details';
import withPage from '@hocs/with-page';

const Member = () => {
  return (
    <Routes>
      <Route path="list" Component={MemberList} />
      <Route path=":id/*" Component={MemberDetails} />
      <Route path="*" element={<Navigate to="list" />} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '會員',
    pathname: '/member/list',
  },
};

export default withPage(page)(Member);
