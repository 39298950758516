import { Chip } from '@mui/material';

const UserStatusList = [
  {
    status: 'SignUp',
    color: 'warning',
    label: '未驗證電話號碼',
  },
  {
    status: 'SignUpVerified',
    color: 'warning',
    label: '未完成個人資料',
  },
  {
    status: 'Active',
    color: 'success',
    label: '註冊完成',
  },
];

const CmsUserStatusList = [
  {
    status: 'Active',
    color: 'success',
    label: '正常',
  },
  {
    status: 'Inactive',
    color: 'warning',
    label: '停用',
  },
];

const ChargeStatusList = [
  {
    status: 'Active',
    color: 'success',
    label: '正常',
  },
  {
    status: 'Inactive',
    color: 'warning',
    label: '隱藏',
  },
];

const ArticleStatusList = [
  {
    status: 'Draft',
    color: 'warning',
    label: '草稿',
  },
  {
    status: 'PendingReview',
    color: 'warning',
    label: '待審批',
  },
  {
    status: 'Reviewed',
    color: 'success',
    label: '已審批',
  },
  {
    status: 'Published',
    color: 'success',
    label: '已發佈 / 待發佈',
  },
  {
    status: 'Hidden',
    color: 'error',
    label: '隱藏',
  },
];

const getStatusList = type => {
  return (
    {
      User: UserStatusList,
      CmsUser: CmsUserStatusList,
      Charge: ChargeStatusList,
      Article: ArticleStatusList,
    }[type] || []
  );
};

const getStatus = (type, status) => {
  const statusList = getStatusList(type);
  return statusList.find(x => x.status === status) || {};
};

const getStatusChip = (type, status, sx) => {
  const { color, label } = getStatus(type, status);
  return <Chip size="small" color={color} label={label} sx={sx} />;
};

export { getStatusList, getStatus, getStatusChip };
