import React, { useEffect, useState } from 'react';
import useAppService from '@hooks/use-app-service';
import { useParams } from 'react-router-dom';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';
import useAppNavigate from '@hooks/use-app-navigate';
import useRoutes from '@hooks/redux/use-routes';
import { getBracketParams } from '@utilities/common';

const useFetchModel = (
  id,
  serviceName,
  promiseName,
  fallbackUrl,
  title,
  page
) => {
  const navigate = useAppNavigate();
  const appService = useAppService();
  const { update: updateRoutes } = useRoutes();
  const [model, setModel] = useState(null);
  useEffect(() => {
    const promise = appService[serviceName][promiseName];
    promise(id)
      .then(_model => {
        if (_model) {
          setModel(_model);

          // Set route for breadcrumb
          if (title && page) {
            let pageTitle = title;
            if (typeof pageTitle === 'function') {
              pageTitle = pageTitle(_model);
            }
            const params = getBracketParams(pageTitle);
            params.forEach(param => {
              pageTitle = pageTitle.replace(`{${param}}`, _model[param]);
            });

            const { level, route } = page;
            updateRoutes(level, {
              ...route,
              title: pageTitle,
              pathname: route.pathname.replace(':id', id),
              fetching: false,
            });
          }
        } else {
          navigate(fallbackUrl);
        }
      })
      .catch(() => {
        navigate(fallbackUrl);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appService,
    id,
    serviceName,
    promiseName,
    fallbackUrl,
    title,
    page,
    updateRoutes,
  ]);
  return model;
};

const withFetchModelWithId =
  ({ serviceName, promiseName, fallbackUrl, title, page }) =>
  Component => {
    return props => {
      let { id } = useParams();
      const model = useFetchModel(
        id,
        serviceName,
        promiseName,
        fallbackUrl,
        title,
        page
      );
      return model ? (
        <Component fetchedModel={model} {...props} />
      ) : (
        <AppLoadingSpinner />
      );
    };
  };

export default withFetchModelWithId;
