import TabLayout from '@components/layouts/TabLayout';
import ArticleListTab from '@pages/page/article-list/_subs/ArticleListTab';

const ArticleList = ({ tab }) => {
  const tabs = [
    {
      title: '醫病錢',
      key: 'treatment',
      url: `/article-list/treatment`,
      component: () => {
        return (
          <ArticleListTab
            overridePageName="article-list/Treatment"
            category="Treatment"
          />
        );
      },
    },
    {
      title: '護理錢',
      key: 'care',
      url: `/article-list/care`,
      component: () => {
        return (
          <ArticleListTab
            overridePageName="article-list/Care"
            category="Care"
          />
        );
      },
    },
    {
      title: '點講數',
      key: 'discuss',
      url: `/article-list/discuss`,
      component: () => {
        return (
          <ArticleListTab
            overridePageName="article-list/Discuss"
            category="Discuss"
          />
        );
      },
    },
    {
      title: '標籤',
      key: 'hashtag',
      url: `/article-list/hashtag`,
      component: () => {
        return (
          <ArticleListTab
            overridePageName="article-list/Hashtag"
            category="Hashtag"
          />
        );
      },
    },
  ];
  return (
    <TabLayout initialValue={tabs.map(x => x.key).indexOf(tab)} tabs={tabs} />
  );
};

export default ArticleList;
