import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import withFetchModelWithId from '@hocs/with-fetch-model-with-id';
import withPage from '@hocs/with-page';
import QuestionnaireForm from '@pages/questionnaire/QuestionnaireForm';

const QuestionnaireDetails = ({ fetchedModel, ...props }) => {
  const [questionnaire, setQuestionnaire] = useState(fetchedModel);
  const _props = {
    ...props,
    questionnaire,
    setQuestionnaire,
  };

  // prettier-ignore
  return (
    <Routes>
      <Route path="edit" element={<QuestionnaireForm.Edit {..._props} />} />
      <Route path="*" element={<Navigate to="edit" />} />
    </Routes>
  );
};

const page = {
  level: 1,
  route: {
    title: '',
    pathname: '/questionnaire/:id',
    fetching: true,
  },
};

export default withPage(page)(
  withFetchModelWithId({
    serviceName: 'questionnaire',
    promiseName: 'getQuestionnaire',
    fallbackUrl: '/questionnaire/list',
    title: '{name}',
    page: page,
  })(QuestionnaireDetails)
);
