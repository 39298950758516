const languages = [
  {
    code: 'tc',
    text: '繁體中文',
  },
  {
    code: 'en',
    text: 'English',
  },
];

export default languages;
