import useAppService from '@hooks/use-app-service';
import { Formik } from 'formik';
import schemaForgotPassword from '@validations/forgot-password.schema';
import { Box, styled } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import { useState } from 'react';

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: theme.spacing(5, 2.5),
  width: 400,
  borderRadius: theme.spacing(2),
  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 2px 12px 0px;',
}));

const ForgotPasswordForm = ({ onSubmitSuccess, ...props }) => {
  const appService = useAppService();
  return (
    <Formik
      initialValues={{
        email: process.env.NODE_ENV !== 'development' ? '' : 'davis@xnotech.com', // prettier-ignore
      }}
      onSubmit={(values, { setSubmitting }) => {
        appService.authentication
          .forgotPassword(values.email)
          .then(res => {
            onSubmitSuccess(res);
          })
          .catch(err => {
            setSubmitting(false);
            appService.handleError(err);
          });
      }}
      validateOnMount={true}
      validationSchema={schemaForgotPassword}
    >
      {formProps => {
        const { isSubmitting, handleSubmit, isValid } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <FormInputField
              id="email"
              label="form.email"
              formProps={formProps}
            />

            <AppButton
              mt={5}
              large
              type="submit"
              sx={{ mt: 5, width: 1 }}
              i="forgotPassword.submitButtonTitle"
              onClick={handleSubmit}
              disabled={isValid && isSubmitting}
              showLoading={isValid && isSubmitting}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      <Box component="main" py={15}>
        <AppTypography variant="h2" i="forgotPassword.title" center />
        <AppTypography
          mt={1.5}
          variant="h5"
          color="text.secondary"
          i="forgotPassword.body"
          center
        />
        <Root>
          {!submitted ? (
            <ForgotPasswordForm onSubmitSuccess={() => setSubmitted(true)} />
          ) : (
            <AppTypography
              variant="h5"
              align="center"
              i="forgotPassword.emailHasBeenSent"
            />
          )}
        </Root>
      </Box>
    </>
  );
};

export default ForgotPassword;
