import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';
import { Grid } from '@mui/material';
import FormRichText from '@components/forms/FormRichText';

const LessonQuoteModule = props => {
  const { data, setFieldValue, editMode } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormRichText
          label="引用"
          height={400}
          value={data.quote}
          onChange={content => setFieldValue({ quote: content })}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormRichText
          label="作者"
          height={200}
          value={data.author}
          onChange={content => setFieldValue({ author: content })}
          disabled={!editMode}
        />
      </Grid>
    </Grid>
  );
};

export default withModuleWrapper('引用')(LessonQuoteModule);
