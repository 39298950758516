import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import AppTypography from '@components/_common/AppTypography';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';
import { deleteListItem } from '@utilities/common';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import { useEffect, useState } from 'react';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import ClearIcon from '@mui/icons-material/Clear';

const SelectOption = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  height: 38,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const SelectOptionAdd = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  width: 38,
  height: 38,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: '#F8F8F8',
  },
}));

const AddSelectOptionDialog = props => {
  const { open, initialValue, onClickCancel, onClickConfirm, ...otherProps } =
    props;
  const [value, setValue] = useState('');
  useEffect(() => {
    if (open) {
      if (initialValue) {
        setValue(initialValue);
      } else {
        setValue('');
      }
    } else {
      setTimeout(() => {
        setValue('');
      }, 250);
    }
  }, [open, initialValue]);

  return (
    <Dialog open={open} onClose={onClickCancel} {...otherProps}>
      <DialogTitle>新增選項</DialogTitle>
      <DialogContent style={{ minWidth: 400, overflow: 'visible' }}>
        <Box position="relative">
          <FormInputField
            label="選項"
            value={value}
            onChange={e => setValue(e.target.value)}
            autoFocus
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="inherit">
          取消
        </Button>
        <AppButton
          children="新增"
          disabled={value.length === 0}
          onClick={() => onClickConfirm && onClickConfirm(value)}
        />
      </DialogActions>
    </Dialog>
  );
};

const FormSelectOptions = ({ type, selectOptions, onChange, disabled }) => {
  const [openCreateSelectOptionDialog, setOpenCreateSelectOptionDialog] =
    useState(false);

  const handleCreateSelectOption = hashtag => {
    setOpenCreateSelectOptionDialog(false);
    if (!selectOptions.includes(hashtag.trim())) {
      onChange([...selectOptions, hashtag]);
    }
  };
  const handleDeleteSelectOption = index => e => {
    e.stopPropagation();
    const updatedSelectOptions = deleteListItem(selectOptions, index);
    onChange(updatedSelectOptions);
  };

  return (
    <>
      <Box m={-1} display="flex" flexWrap="wrap">
        {disabled && selectOptions.length === 0 ? (
          <AppTypography px={1}>沒有選項</AppTypography>
        ) : (
          selectOptions.map((x, index) => (
            <SelectOption key={x}>
              <AppTypography mr={1} variant="subtitle1" children={x} />
              {!disabled && (
                <TooltipIconButton
                  title="Remove"
                  size="small"
                  Icon={ClearIcon}
                  onClick={handleDeleteSelectOption(index)}
                />
              )}
            </SelectOption>
          ))
        )}
        {!disabled && (
          <SelectOptionAdd
            component="button"
            type="button"
            onClick={() => setOpenCreateSelectOptionDialog(true)}
          >
            <AppTypography variant="h5" children="+" />
          </SelectOptionAdd>
        )}
      </Box>

      <AddSelectOptionDialog
        open={openCreateSelectOptionDialog}
        onClickCancel={() => setOpenCreateSelectOptionDialog(false)}
        onClickConfirm={handleCreateSelectOption}
      />
    </>
  );
};

export default withFormControlWrapper(FormSelectOptions);
