import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AppTypography from '@components/_common/AppTypography';

const FabAdd = ({ text, onClick }) => {
  return (
    <Fab
      type="button"
      color="primary"
      size="medium"
      aria-label="add"
      variant="extended"
      onClick={onClick}
    >
      <AddIcon />
      {text && (
        <AppTypography
          ml={0.5}
          mr={0.5}
          variant="subtitle1"
          color="text.primary"
        >
          {text}
        </AppTypography>
      )}
    </Fab>
  );
};

export default FabAdd;
