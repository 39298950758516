import useAppService from '@hooks/use-app-service';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import AppDatatable from '@components/_common/AppDatatable';
import { format, parseISO } from 'date-fns';

const SelectArticleDialog = props => {
  const { open, onSelect, onClose } = props;
  const appService = useAppService();
  const pageSize = 20;
  const handleDatatableOnClick = row => {
    onSelect && onSelect(row);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle children="選擇文章" />
      <DialogContent>
        <DialogContentText component="div">
          <AppDatatable
            defaultSortField="updateDate"
            defaultSortAsc={false}
            columns={[
              {
                name: '標題',
                selector: row => row.title,
                sortable: true,
                sortField: 'title',
              },
              {
                name: '發佈日期',
                selector: row => row.publishDate,
                width: '200px',
                sortable: true,
                sortField: 'publishDate',
                cell: row => {
                  return row.publishDate
                    ? format(parseISO(row.publishDate), 'yyyy-MM-dd HH:mm:ss')
                    : '沒有';
                },
              },
            ]}
            pageSize={pageSize}
            onClickRow={row => handleDatatableOnClick(row)}
            onSelectedRows={rows => console.log(rows)}
            onLayout$={appService.article.getArticleList}
            // otherParams={{
            //   status: 1,
            //   excludeIds: excludeIds && excludeIds.join(','),
            // }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} children="取消" />
      </DialogActions>
    </Dialog>
  );
};

export default SelectArticleDialog;
