import { Box, keyframes, styled } from '@mui/material';

const flashingDark = keyframes`
  0% {
    background-color: #4E4C45;
  }
  50% {
    background-color: #4E4C454D;
  }
  100% {
    background-color: #4E4C454D;
  }
`;

const flashingWhite = keyframes`
  0% {
    background-color: #FFFFFF;
  }
  50% {
    background-color: #FFFFFF4D;
  }
  100% {
    background-color: #FFFFFF4D;
  }
`;

const Root = styled(Box)(({ theme }) => ({
  width: 34,
  '& > *': {
    position: 'relative',
    transform: 'translate(13px, 0)',
    animation: `${flashingDark} 0.8s infinite linear alternate`,
    '&, &::before, &::after': {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: '#4E4C45',
    },
    '&::before, &::after': {
      position: 'absolute',
      content: "''",
      display: 'inline-block',
      top: 0,
      animation: `${flashingDark} 0.8s infinite linear alternate`,
    },

    '&::before': {
      left: -13,
      animationDelay: '0s',
    },
    animationDelay: '.5s',
    '&::after': {
      left: 13,
      animationDelay: '1s',
    },
  },
}));

const RootWhite = styled(Root)(({ theme }) => ({
  '& > *': {
    animationName: flashingWhite,
    '&, &::before, &::after': {
      backgroundColor: '#FFFFFF',
    },
    '&::before, &::after': {
      animationName: flashingWhite,
    },
  },
}));

const AppLoadingDot = ({ white, ...props }) => {
  const Component = white ? RootWhite : Root;
  return (
    <Component component="span" display="flex" {...props}>
      <div />
    </Component>
  );
};

export default AppLoadingDot;
