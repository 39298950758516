import { FormControl, InputLabel, Select, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AppFormControlSelect = ({
  width,
  sx,
  label,
  value,
  children,
  onChange,
}) => {
  const theme = useTheme();
  const handleChange = e => {
    onChange(e.target.value);
  };
  return (
    <FormControl
      variant="outlined"
      sx={{
        ...sx,
        width,
        '.MuiFormLabel-root:not(.Mui-focused)': {
          top: 0,
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        size="small"
        onChange={handleChange}
        label={label}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              mt: 0.75,
            },
            '.MuiList-root': {
              py: 0,
              borderRadius: '6px',
            },
            '.MuiMenuItem-root': {
              py: 1.25,
              '&:hover': {
                backgroundColor: `rgba(207, 125, 240, 0.2) !important`,
              },
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: theme.palette.common.white,
              },
            },
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default AppFormControlSelect;
