import React, { useState } from 'react';
import withModuleWrapper from '../hoc/with-module-wrapper';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteListItem, swapListItem } from '@utilities/common';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import FormCheckboxGroup from '@components/forms/FormCheckboxGroup';
import FormInputField from '@components/forms/FormInputField';
import FormRichText from '@components/forms/FormRichText';
import AppButton from '@components/_common/AppButton';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';

const LessonAccordionModule = props => {
  const { data, setFieldValue, setSubFieldValue, editMode, onChange } = props;
  const [indexToBeDeleted, setIndexToBeDeleted] = useState(null);

  const handleClickAddItem = () => {
    onChange &&
      onChange({
        ...data,
        items: [
          ...data.items,
          {
            title: '',
            body: '',
          },
        ],
      });
  };
  const handleSwap = (fromIndex, toIndex) => {
    const _data = data;
    _data.items = swapListItem(_data.items, fromIndex, toIndex);
    onChange && onChange(_data);
  };
  const handleDeleteItem = () => {
    const _data = data;
    _data.items = deleteListItem(_data.items, indexToBeDeleted);
    onChange && onChange(_data);
    setIndexToBeDeleted(null);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormCheckboxGroup
            options={[
              {
                label: '不折疊',
                value: 'true',
              },
            ]}
            value={data.forceExpand ? ['true'] : []}
            onChange={value =>
              setFieldValue({ forceExpand: value.includes('true') })
            }
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputField
            label="標題"
            value={data.title}
            onChange={e =>
              setFieldValue({
                title: e.target.value,
              })
            }
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12}>
          {data.items.map((item, index) => (
            <React.Fragment key={index}>
              <Divider sx={{ my: 2 }} />

              <Box display="flex">
                <Box flex={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormInputField
                        label="標題"
                        value={item.title}
                        onChange={e =>
                          setSubFieldValue(index, 'items', {
                            title: e.target.value,
                          })
                        }
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormRichText
                        label="內容"
                        height={250}
                        value={item.body}
                        onChange={content =>
                          setSubFieldValue(index, 'items', {
                            body: content,
                          })
                        }
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {editMode && (
                  <Box ml={2} display="flex" flexDirection="column">
                    <IconButton
                      aria-label="move up"
                      onClick={() => handleSwap(index, index - 1)}
                      disabled={index === 0}
                    >
                      <KeyboardArrowUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="move down"
                      onClick={() => handleSwap(index, index + 1)}
                      disabled={index >= data.items.length - 1}
                    >
                      <KeyboardArrowDownIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setIndexToBeDeleted(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </React.Fragment>
          ))}

          {editMode && (
            <AppButton
              mt={data.items.length > 0 ? 2 : 0}
              children="新增折叠內容 Item"
              onClick={handleClickAddItem}
            />
          )}
        </Grid>
      </Grid>

      <ConfirmDialog
        open={indexToBeDeleted != null}
        title={'確認移除折叠內容 Item?'}
        onClose={() => setIndexToBeDeleted(null)}
        onConfirm={handleDeleteItem}
      />
    </>
  );
};

export default withModuleWrapper('折叠內容')(LessonAccordionModule);
