import * as Yup from 'yup';

const schema = Yup.object().shape({
  keyVisualImageLink: Yup.string().matches(
    /(^(http|https):\/\/|^\/)/,
    'validation.linkInvalid'
  ),

  highlights: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      backgroundColor: Yup.string().required('請輸入背景顏色'),
      title: Yup.string().required('請輸入標題'),
      action: Yup.string(),
      actionLink: Yup.string().when('action', {
        is: value => value === 'Link',
        then: schema =>
          schema
            .required('請輸入連結')
            .matches(/(^(http|https):\/\/|^\/)/, 'validation.linkInvalid'),
      }),
    })
  ),

  footerButtonLink: Yup.string().matches(
    /(^(http|https):\/\/|^\/)/,
    'validation.linkInvalid'
  ),
});

export default schema;
