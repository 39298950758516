import { Route, Routes } from 'react-router-dom';
import BreastCancerPage from './BreastCancer';
import withPage from '@hocs/with-page';

const BreastCancer = () => {
  const indexSubpages = ['settings'];
  return (
    <Routes>
      <Route index element={<BreastCancerPage tab="settings" />} />
      {indexSubpages.map(x => (
        <Route path={x} key={x} element={<BreastCancerPage tab={x} />} />
      ))}
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '乳癌計算機',
    pathname: '/breast-cancer',
  },
};

export default withPage(page)(BreastCancer);
