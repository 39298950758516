import BaseLayout from '@components/layouts/BaseLayout';
import { Box, Chip, Divider, Grid } from '@mui/material';
import useFormUtilities from '@hooks/use-form-utilities';
import { getStatusChip } from '@constants/status';
import AppTypography from '@components/_common/AppTypography';
import FormHashtags from '@components/forms/FormHashtags';
import { format, parseISO } from 'date-fns';
import FormSeoKeywords from '@components/forms/FormSeoKeywords';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import articleCategories from '@configurations/article-categories';

const ArticleDetailsSummary = ({ article }) => {
  const { renderLabelChildren, renderLabelValue } = useFormUtilities();
  const { enqueueSnackbar } = useSnackbar();
  const renderStatusChip = () => {
    if (article.status === 'Published') {
      if (
        article.expiryDate != null &&
        parseISO(article.expiryDate).getTime() < new Date().getTime()
      ) {
        return <Chip size="small" color="warning" label="已下架" />;
      }
      if (parseISO(article.publishDate).getTime() > new Date().getTime()) {
        return <Chip size="small" color="warning" label="待發佈" />;
      }
      return <Chip size="small" color="success" label="已發佈" />;
    }
    return getStatusChip('Article', article.status, { mt: -0.5 });
  };

  const category = articleCategories.find(
    x => x.value === article.category
  )?.label;
  return (
    <BaseLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderLabelChildren({
            label: '狀態',
            children: renderStatusChip(),
          })}
        </Grid>
        <Grid item xs={6}>
          {renderLabelValue({
            label: 'form.category',
            value: category,
          })}
        </Grid>
        <Grid item xs={6}>
          {renderLabelValue({
            label: 'form.hideFromArticleList',
            value: article.hideFromArticleList ? '隱藏' : '不隱藏',
          })}
        </Grid>
        <Grid item xs={12}>
          {renderLabelChildren({
            label: '網址',
            children: (
              <Box mt={-1.25} display="flex" alignItems="center">
                <AppTypography variant="subtitle1" children={article.slug} />
                <Box width={8} />
                <TooltipIconButton
                  title="查看"
                  color="primary"
                  Icon={VisibilityIcon}
                  onClick={() => {
                    window
                      .open(
                        `${process.env.REACT_APP_WEB_URL}/${category}/${article.slug}`,
                        '_blank'
                      )
                      .focus();
                  }}
                />
                <TooltipIconButton
                  title="複制連結"
                  color="primary"
                  Icon={FileCopyIcon}
                  onClick={() => {
                    copy(
                      `${process.env.REACT_APP_WEB_URL}/${category}/${article.slug}`
                    );
                    enqueueSnackbar('已複制連結');
                  }}
                />
              </Box>
            ),
          })}
        </Grid>
        <Grid item xs={12}>
          {renderLabelValue({ label: 'form.title', value: article.title })}
        </Grid>
        <Grid item xs={12}>
          {renderLabelValue({ label: 'form.author', value: article.author })}
        </Grid>
        <Grid item xs={12}>
          <AppTypography mb={1} children={'標籤'} />
          {(article.hashtags || '').split(',').filter(x => !!x).length === 0 ? (
            <AppTypography variant="subtitle1" children="N/A" />
          ) : (
            <FormHashtags
              type="article"
              hashtags={article.hashtags
                .split(',')
                .filter(x => !!x)
                .map(x => x.trim())}
              disabled
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {renderLabelValue({
            label: 'form.publishDate',
            value: format(parseISO(article.publishDate), 'yyyy-MM-dd HH:mm'),
          })}
        </Grid>
        <Grid item xs={6}>
          {renderLabelValue({
            label: 'form.expiryDate',
            value: article.expiryDate
              ? format(parseISO(article.expiryDate), 'yyyy-MM-dd HH:mm')
              : '沒有',
          })}
        </Grid>
        <Grid item xs={12}>
          {renderLabelValue({
            label: '觀看次數',
            value: `${article.viewCount}`,
          })}
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <AppTypography mb={2} variant="h4" children="SEO" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderLabelValue({
            label: 'form.seoTitle',
            value: `${article.seoTitle}`,
          })}
        </Grid>
        <Grid item xs={12}>
          {renderLabelValue({
            label: 'form.seoDescription',
            value: `${article.seoDescription}`,
          })}
        </Grid>
        <Grid item xs={12}>
          <AppTypography mb={1} i="form.seoKeywords" />
          {(article.seoKeywords || '').split(',').filter(x => !!x).length ===
          0 ? (
            <AppTypography variant="subtitle1" children="N/A" />
          ) : (
            <FormSeoKeywords
              seoKeywords={article.seoKeywords
                .split(',')
                .filter(x => !!x)
                .map(x => x.trim())}
              disabled
            />
          )}
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default ArticleDetailsSummary;
