const authentication = request => ({
  login: (email, password) =>
    request({
      method: 'post',
      endpoint: '/cms-user/login',
      data: { email, password },
    }),

  forgotPassword: email =>
    request({
      method: 'post',
      endpoint: '/cms-user/forget-password',
      data: { email },
    }),

  checkResetPasswordToken: token =>
    request({
      method: 'get',
      endpoint: '/cms-user/reset-password',
      params: { token },
    }),

  resetPassword: (token, password) =>
    request({
      method: 'post',
      endpoint: `/cms-user/reset-password`,
      data: { token, password },
    }),

  changePassword: body =>
    request({
      method: 'post',
      endpoint: `/cms-user/change-password`,
      data: body,
    }),

  logout: () =>
    request({
      method: 'post',
      endpoint: '/cms-user/logout',
    }),

  getLoggedInUser: () =>
    request({
      method: 'get',
      endpoint: '/cms-user/me',
    }),
});

export default authentication;
