import AppDatatable from '@components/_common/AppDatatable';
import useAppService from '@hooks/use-app-service';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MenuItem } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import BaseLayout from '@components/layouts/BaseLayout';
import { getStatusChip } from '@constants/status';
import AppFormControlSelect from '@components/_common/AppFormControlSelect';
import { formatNumber } from '@utilities/common';
import LinkIcon from '@mui/icons-material/Link';
import AppButton from '@components/_common/AppButton';
import Reorder from '@components/_common/Reorder';
import chargeTypes from '@configurations/charge-types';
import useQueryParams from '@hooks/use-query-params';

const ChargeListGlobal = ({ ...props }) => {
  const appService = useAppService();
  return (
    <BaseLayout title="統一收費" {...props}>
      <AppDatatable
        offsetHeaderTop
        negativeMx
        noPagination
        columns={[
          {
            name: '種類',
            selector: row => row.type,
            sortable: false,
          },
          {
            name: '價錢 (HK$)',
            selector: row => row.cost,
            sortable: false,
            cell: row => {
              if (!row.cost) {
                return row.remarks ? `(${row.remarks})` : '-';
              }
              let costLabel = `$${formatNumber(row.cost)}`;
              if (row.costMax) {
                costLabel += ` - ${formatNumber(row.costMax)}`;
              }
              return costLabel + row.remarks;
            },
          },
          {
            id: 'action',
            name: '',
            width: '60px',
            right: true,
            cell: row => (
              <TooltipIconButton
                title="前往"
                color="primary"
                Icon={NavigateNextIcon}
                to={`/charge/${row.id}`}
              />
            ),
          },
        ]}
        pageSize={99999}
        onLayout$={appService.charge.getChargeList}
        otherParams={{ category: null }}
      />
    </BaseLayout>
  );
};

const ChargeListByCategory = ({ ...props }) => {
  const {
    value: { category: queryCategory, type: queryType },
  } = useQueryParams();
  const appService = useAppService();
  const [charges, setCharges] = useState(null);
  const [chargeCategory] = useState(queryCategory || 'Any');
  const [chargeType, setChargeType] = useState(queryType || '私家醫院門診');
  const [filter, setFilter] = useState({
    category: chargeCategory,
    type: chargeType,
  });
  const datatableRef = useRef();
  const pageSize = 99999;

  useEffect(() => {
    if (filter.type !== chargeType) {
      setFilter({
        category: chargeCategory,
        type: chargeType,
      });
    }
  }, [chargeCategory, chargeType, filter.type]);

  const getReorderContents = () => {
    return (
      charges
        ?.sort((a, b) => a.sequence - b.sequence)
        ?.map(x => {
          return {
            key: x.id,
            name: `${x.region}  |  ${x.place}`,
          };
        }) || []
    );
  };

  const handleReorder = reorderedContents => {
    const ids = reorderedContents.map(x => x.key);
    appService.charge
      .reorderChargeList(ids)
      .then(() => {
        datatableRef.current.reload();
      })
      .catch(appService.handleError);
  };

  const renderHeaderLeft = () => {
    return (
      <>
        <AppFormControlSelect
          width={240}
          label="收費種類"
          value={chargeType}
          onChange={setChargeType}
        >
          {chargeTypes.map(x => (
            <MenuItem key={x.value} value={x.value}>
              {x.label}
            </MenuItem>
          ))}
        </AppFormControlSelect>
      </>
    );
  };

  const renderHeaderRight = () => (
    <>
      <Reorder
        iconSize="medium"
        title="更改排序"
        items={getReorderContents()}
        onUpdate={handleReorder}
      />
      <AppButton to={`/charge/create?type=${chargeType}`}>新增收費</AppButton>
    </>
  );
  return (
    <BaseLayout
      title="收費種類"
      headerLeft={renderHeaderLeft()}
      headerRight={renderHeaderRight()}
      {...props}
    >
      <AppDatatable
        ref={datatableRef}
        negativeMx
        noPagination
        syncQueryParams
        defaultSortFieldId="sequence"
        defaultSortAsc
        columns={[
          {
            name: '排序',
            width: '80px',
            selector: row => row.sequence,
            sortable: true,
            sortField: 'sequence',
          },
          {
            name: '地區',
            width: '120px',
            selector: row => row.region,
            sortable: true,
            sortField: 'region',
          },
          {
            name: '地點',
            grow: 3,
            selector: row => row.place,
            sortable: true,
            sortField: 'place',
          },
          {
            name: '價錢 (HK$)',
            grow: 3,
            selector: row => row.cost,
            sortable: true,
            sortField: 'cost',
            cell: row => {
              if (!row.cost) {
                return row.remarks ? `（${row.remarks}）` : '-';
              }
              let costLabel = `$${formatNumber(row.cost)}`;
              if (row.costMax) {
                costLabel += ` - ${formatNumber(row.costMax)}`;
              }
              if (!row.remarks) {
                return `${costLabel}`;
              }
              return `${costLabel}（${row.remarks}）`;
            },
          },
          {
            name: '連結',
            width: '80px',
            selector: row => row.link,
            cell: row => {
              if (row.link) {
                return (
                  <TooltipIconButton
                    title="開啟連結"
                    color="primary"
                    Icon={LinkIcon}
                    to={row.link}
                  />
                );
              }
              return null;
            },
          },
          {
            name: '狀態',
            width: '100px',
            selector: row => row.status,
            sortable: true,
            sortField: 'status',
            cell: row => getStatusChip('Charge', row.status),
          },
          {
            id: 'action',
            name: '',
            width: '60px',
            right: true,
            cell: row => (
              <TooltipIconButton
                title="前往"
                color="primary"
                Icon={NavigateNextIcon}
                to={`/charge/${row.id}`}
              />
            ),
          },
        ]}
        pageSize={pageSize}
        onLayout$={appService.charge.getChargeList}
        onLayout={setCharges}
        otherParams={filter}
      />
    </BaseLayout>
  );
};

const ChargeList = () => {
  return (
    <>
      <ChargeListGlobal />
      <ChargeListByCategory />
    </>
  );
};

export default ChargeList;
