import withPage from '@hocs/with-page';
import TabLayout from '@components/layouts/TabLayout';
import ProfileSummary from '@pages/profile/_subs/ProfileSummary';
import ProfileChangePassword from '@pages/profile/_subs/ProfileChangePassword';

const Profile = props => {
  const tabs = [
    {
      title: '概要',
      key: 'summary',
      component: () => {
        return <ProfileSummary {...props} />;
      },
    },
    {
      title: '更改密碼',
      key: 'change-password',
      component: () => {
        return <ProfileChangePassword {...props} />;
      },
    },
  ];
  return <TabLayout tabs={tabs} />;
};

const page = {
  level: 0,
  route: {
    title: '登入用戶資料',
    pathname: '/profile',
  },
};

export default withPage(page)(Profile);
