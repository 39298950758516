import { Route, Routes } from 'react-router-dom';
import HomePage from './Home';
import withPage from '@hocs/with-page';

const Home = () => {
  return (
    <Routes>
      <Route index Component={HomePage} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '主頁',
    pathname: '/home',
  },
};

export default withPage(page)(Home);
