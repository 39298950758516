import BaseLayout from '@components/layouts/BaseLayout';
import withFetchPage from '@hocs/with-fetch-page';
import { useMemo, useState } from 'react';
import { Formik } from 'formik';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import { Box, Divider, Grid, IconButton, useTheme } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import FormTextareaField from '@components/forms/FormTextareaField';
import FormSeoKeywords from '@components/forms/FormSeoKeywords';
import AppButton from '@components/_common/AppButton';
import AppTypography from '@components/_common/AppTypography';
import FormHashtags from '@components/forms/FormHashtags';
import { swapListItem } from '@utilities/common';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import FormSelectField from '@components/forms/FormSelectField';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FabAdd from '@components/_common/FabAdd';

const Cards = ({ formProps, editMode }) => {
  const theme = useTheme();
  const { values, errors, submitCount, setValues, setFieldValue } = formProps;
  const key = 'cards';
  const handleAddHighlight = () => {
    const defaultHighlight = {
      backgroundColor: '',
      image: null,
      badgeLabel: '',
      title: '',
      action: 'None',
      actionLink: '',
    };
    setValues({
      ...values,
      [key]: [...values[key], defaultHighlight],
    });
  };
  const handleMoveUp = index => () => {
    const newNavigations = swapListItem(values[key], index, index - 1);
    setValues({
      ...values,
      [key]: newNavigations,
    });
  };
  const handleMoveDown = index => () => {
    const newNavigations = swapListItem(values[key], index, index + 1);
    setValues({
      ...values,
      [key]: newNavigations,
    });
  };
  const handleDelete = index => () => {
    let newNavigations = [...values[key]];
    newNavigations.splice(index, 1);
    setValues({
      ...values,
      [key]: newNavigations,
    });
  };
  return (
    <>
      {values[key].map((highlight, index) => (
        <Box
          key={index}
          mb={2}
          px={2}
          py={2}
          display="flex"
          alignItems="flex-start"
          sx={{
            borderRadius: '6px',
            border: `1px solid ${theme.palette.divider}}`,
          }}
        >
          <Box flex={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormAssetUpload
                  image
                  withAssetDescription
                  label="圖片 (560 x 420)"
                  ratio={280 / 210}
                  asset={highlight.image}
                  onUploadedFile={asset =>
                    setFieldValue(`${key}.${index}.image`, asset)
                  }
                  errorMessage={
                    submitCount > 0 &&
                    errors[key] &&
                    errors[key][index] &&
                    errors[key][index].image
                  }
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextareaField
                      id={`${key}.${index}.title`}
                      label="標題"
                      rows={2}
                      formProps={formProps}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextareaField
                      id={`${key}.${index}.body`}
                      label="簡介"
                      rows={4}
                      formProps={formProps}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormSelectField
                      id={`${key}.${index}.action`}
                      label="點搫處理"
                      options={[
                        {
                          label: '沒有',
                          value: 'None',
                        },
                        {
                          label: '開啟連結',
                          value: 'Link',
                        },
                        {
                          label: '乳癌計算機',
                          value: 'BreastCancerCalculator',
                        },
                        {
                          label: '乳癌治療計算機',
                          value: 'BreastCancerSurgeryCalculator',
                        },
                      ]}
                      formProps={formProps}
                      disabled={!editMode}
                    />
                  </Grid>
                  {values[key][index].action === 'Link' && (
                    <Grid item xs={12}>
                      <FormInputField
                        id={`${key}.${index}.actionLink`}
                        label="連結"
                        formProps={formProps}
                        disabled={!editMode}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {editMode && (
            <Box
              width={60}
              flexShrink={0}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <IconButton
                aria-label="move up"
                onClick={handleMoveUp(index)}
                disabled={index === 0}
              >
                <KeyboardArrowUpIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="move down"
                onClick={handleMoveDown(index)}
                disabled={index === values[key].length - 1}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
              <IconButton aria-label="delete" onClick={handleDelete(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}

      {editMode && <FabAdd text="新增卡" onClick={handleAddHighlight} />}
    </>
  );
};

const Home = ({ page, setPage, pageName }) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);
  const initialValues = useMemo(
    () => ({
      hashtags: page?.hashtags || '',
      cards: page.cards || [], // prettier-ignore
      seoTitle: page?.seoTitle || '',
      seoDescription: page?.seoDescription || '',
      seoKeywords: page?.seoKeywords || '',
    }),
    [page]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        appService.page
          .updatePage({
            pagePath: pageName,
            body: values,
          })
          .then(res => {
            enqueueSnackbar(`頁面已更新`);
            setSubmitting(false);
            setPage(res);
            setEditMode(false);
          }, appService.handleError);
      }}
      enableReinitialize
      validateOnMount
    >
      {formProps => {
        const { values, setFieldValue, handleSubmit, isSubmitting } = formProps;
        return (
          <BaseLayout
            title="主頁"
            headerRight={
              <>
                {editMode ? (
                  <AppButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                  >
                    更新
                  </AppButton>
                ) : (
                  <AppButton onClick={() => setEditMode(true)}>編輯</AppButton>
                )}
              </>
            }
            noMb
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormHashtags
                    label="form.hashtags"
                    type="article"
                    hashtags={values.hashtags
                      .split(',')
                      .filter(x => !!x)
                      .map(x => x.trim())}
                    onChange={hashtags =>
                      setFieldValue('hashtags', hashtags.join(','))
                    }
                    disabled={!editMode}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h6">
                內容卡
              </AppTypography>

              <Cards formProps={formProps} editMode={editMode} />

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h4" children="SEO" />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormInputField
                    id="seoTitle"
                    label="form.seoTitle"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextareaField
                    id="seoDescription"
                    label="form.seoDescription"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSeoKeywords
                    label={'SEO 關鍵字'}
                    seoKeywords={values.seoKeywords
                      .split(',')
                      .filter(x => !!x)
                      .map(x => x.trim())}
                    onChange={seoKeywords =>
                      setFieldValue('seoKeywords', seoKeywords.join(','))
                    }
                    disabled={!editMode}
                  />
                </Grid>
              </Grid>
            </form>
          </BaseLayout>
        );
      }}
    </Formik>
  );
};

export default withFetchPage({
  pageName: 'home',
})(Home);
