import withModuleWrapper from '../hoc/with-module-wrapper';
import { deleteListItem, swapListItem } from '@utilities/common';
import { Box, Grid, IconButton } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppTypography from '@components/_common/AppTypography';
import Portlet from '@components/layouts/Portlet';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import FormTextareaField from '@components/forms/FormTextareaField';
import AppButton from '@components/_common/AppButton';

const LessonChecklistModule = props => {
  const { data, setFieldValue, setSubFieldValue, editMode, onChange } = props;
  const handleClickAddGroup = () => {
    onChange &&
      onChange({
        ...data,
        groups: [
          ...data.groups,
          {
            title: '',
            items: '',
          },
        ],
      });
  };
  const handleSwap = (fromIndex, toIndex) => {
    const _data = data;
    _data.groups = swapListItem(_data.groups, fromIndex, toIndex);
    onChange && onChange(_data);
  };
  const handleDeleteGroup = index => {
    const _data = data;
    _data.groups = deleteListItem(_data.groups, index);
    onChange && onChange(_data);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputField
            label="標題"
            value={data.title}
            onChange={e =>
              setFieldValue({
                title: e.target.value,
              })
            }
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12}>
          <AppTypography mb={2} variant="h4" children="清單組別" />

          {data.groups.length > 0 && (
            <Grid container spacing={2}>
              {data.groups.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Portlet
                    titleComponent={
                      <Box flex={1}>
                        <FormInputField
                          placeholder="標題"
                          value={item.title}
                          onChange={e =>
                            setSubFieldValue(index, 'groups', {
                              title: e.target.value,
                            })
                          }
                          disabled={!editMode}
                        />
                      </Box>
                    }
                    headerRight={
                      editMode && (
                        <Box>
                          <IconButton
                            aria-label="move up"
                            onClick={() => handleSwap(index, index - 1)}
                            disabled={index === 0}
                          >
                            <KeyboardArrowUpIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="move down"
                            onClick={() => handleSwap(index, index + 1)}
                            disabled={index >= data.groups.length - 1}
                          >
                            <KeyboardArrowDownIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteGroup(index)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      )
                    }
                    paddingContent
                    doubleShadow
                  >
                    <FormTextareaField
                      rows={5}
                      placeholder="清單項目"
                      hint="隔行以分隔清單項目"
                      value={item.items}
                      onChange={e =>
                        setSubFieldValue(index, 'groups', {
                          items: e.target.value,
                        })
                      }
                      disabled={!editMode}
                    />
                  </Portlet>
                </Grid>
              ))}
            </Grid>
          )}

          {editMode && (
            <AppButton
              mt={data.groups.length > 0 ? 2 : 0}
              children="新增清單組別"
              onClick={handleClickAddGroup}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withModuleWrapper('清單')(LessonChecklistModule);
