const questionnaire = request => ({
  getQuestionnaireList: params =>
    request({
      method: 'get',
      endpoint: `/questionnaire/list`,
      params: params,
    }),

  getAllQuestionnaireList: () =>
    request({
      method: 'get',
      endpoint: `/questionnaire/list-all`,
    }),

  getQuestionnaire: id =>
    request({
      method: 'get',
      endpoint: `/questionnaire/${id}`,
    }),

  createQuestionnaire: body =>
    request({
      method: 'post',
      endpoint: '/questionnaire',
      data: body,
    }),

  updateQuestionnaire: (id, body) =>
    request({
      method: 'put',
      endpoint: `/questionnaire/${id}`,
      data: body,
    }),

  publishQuestionnaire: id =>
    request({
      method: 'post',
      endpoint: `/questionnaire/${id}/publish`,
    }),

  hideQuestionnaire: id =>
    request({
      method: 'post',
      endpoint: `/questionnaire/${id}/hide`,
    }),
});

export default questionnaire;
