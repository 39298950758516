import BaseLayout from '@components/layouts/BaseLayout';
import { format, parseISO } from 'date-fns';
import AppDatatable from '@components/_common/AppDatatable';
import useAppService from '@hooks/use-app-service';

const ChargeDetailsChangeLogs = ({ charge }) => {
  const appService = useAppService();
  const pageSize = 20;
  return (
    <BaseLayout>
      <AppDatatable
        offsetHeaderTop
        negativeMx
        noPagination
        defaultSortFieldId="createDate"
        columns={[
          {
            name: '描述',
            selector: row => row.description,
            sortable: false,
          },
          {
            name: '更改人',
            width: '140px',
            selector: row => row.user.name,
            sortable: true,
            sortField: 'user.name',
          },
          {
            name: '更改時間',
            width: '200px',
            selector: row =>
              format(parseISO(row.createDate), 'yyyy-MM-dd HH:mm'),
            sortable: true,
            sortField: 'createDate',
          },
        ]}
        pageSize={pageSize}
        onLayout$={appService.charge.getChargeLogList(charge.id)}
      />
    </BaseLayout>
  );
};

export default ChargeDetailsChangeLogs;
