const calculator = request => ({
  getBreastCancerCalculator: () =>
    request({
      method: 'get',
      endpoint: `/calculator/breast-cancer`,
    }),

  updateBreastCancerCalculator: data =>
    request({
      method: 'put',
      endpoint: `/calculator/breast-cancer`,
      data,
    }),

  getBreastCancerSurgeryCalculator: () =>
    request({
      method: 'get',
      endpoint: `/calculator/breast-cancer-surgery`,
    }),

  updateBreastCancerSurgeryCalculator: data =>
    request({
      method: 'put',
      endpoint: `/calculator/breast-cancer-surgery`,
      data,
    }),
});

export default calculator;
