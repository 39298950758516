import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import { Box, Collapse, Dialog, IconButton } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import { format } from 'date-fns';
import EventIcon from '@mui/icons-material/Event';
import { DateCalendar } from '@mui/x-date-pickers';
import { useState } from 'react';

const FormDatePicker = ({ value, onChange, popup, disabled }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const handleOnChangeDate = date => {
    onChange && onChange(date);
    setShowDatePicker(false);
  };
  return (
    <>
      <Box mt={-0.5} display="flex" alignItems="center">
        <AppTypography
          mr={1}
          component="span"
          variant="h6"
          sx={{ transform: 'translate(0, -2px)' }}
        >
          {!value ? (
            'N/A'
          ) : (
            <>
              {typeof value === 'object' && format(value, 'yyyy-MM-dd')}
              {typeof value === 'string' && value}
            </>
          )}
        </AppTypography>
        {!disabled && (
          <IconButton
            color={showDatePicker ? 'primary' : 'default'}
            onClick={() => setShowDatePicker(!showDatePicker)}
          >
            <EventIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>

      {!popup ? (
        <Collapse in={showDatePicker}>
          <Box width={1} maxWidth={325}>
            <DateCalendar value={value} onChange={handleOnChangeDate} />
          </Box>
        </Collapse>
      ) : (
        <Dialog open={showDatePicker} onClose={() => setShowDatePicker(false)}>
          <DateCalendar value={value} onChange={handleOnChangeDate} />
          <Box height={20} />
        </Dialog>
      )}
    </>
  );
};

export default withFormControlWrapper(FormDatePicker);
