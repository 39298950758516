import * as Yup from 'yup';

const schema = Yup.object().shape({
  bannerRows: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      items: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          image: Yup.object().required('請選擇圖片'),
          imageWidth: Yup.string().required('請輸入圖片闊度'),
          imageHeight: Yup.string().required('請輸入圖片高度'),
          title: Yup.string().required('請輸入標題'),
          titleOffsetY: Yup.string().required('請輸入標題上下微調'),
          action: Yup.string(),
          actionLink: Yup.string().when('action', {
            is: value => value === 'Link',
            then: schema =>
              schema
                .required('請輸入連結')
                .matches(/(^(http|https):\/\/|^\/)/, 'validation.linkInvalid'),
          }),
        })
      ),
    })
  ),
});

export default schema;
