import BaseLayout from '@components/layouts/BaseLayout';
import { Formik } from 'formik';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import useAppNavigate from '@hooks/use-app-navigate';
import { Box, Divider, Grid } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import withPage from '@hocs/with-page';
import schemaArticle from '@validations/article.schema';
import { parseISO } from 'date-fns';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import FormHashtags from '@components/forms/FormHashtags';
import FormDateTimePicker from '@components/forms/FormDateTimePicker';
import AppTypography from '@components/_common/AppTypography';
import FormCheckboxGroup from '@components/forms/FormCheckboxGroup';
import FormTextareaField from '@components/forms/FormTextareaField';
import FormSeoKeywords from '@components/forms/FormSeoKeywords';
import FormSelectField from '@components/forms/FormSelectField';
import articleCategories from '@configurations/article-categories';

const ArticleForm = ({ article, setArticle }) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useAppNavigate();
  return (
    <BaseLayout>
      <Formik
        initialValues={{
          seoTitle: article?.seoTitle || '',
          seoDescription: article?.seoDescription || '',
          seoKeywords: article?.seoKeywords || '',
          category: article?.category || 'Treatment',
          hideFromArticleList: article?.hideFromArticleList ? 'yes' : 'no',
          slug: article?.slug || '',
          author: article?.author || '',
          image: article?.image || '',
          title: article?.title || '',
          hashtags: article?.hashtags || '',
          publishDate: article?.publishDate
            ? parseISO(article.publishDate)
            : new Date(),
          noExpiryDate: article?.expiryDate == null,
          expiryDate: article?.expiryDate
            ? parseISO(article.expiryDate)
            : new Date(),
        }}
        onSubmit={(values, { setSubmitting }) => {
          const requestBody = {
            ...values,
          };
          requestBody.hideFromArticleList =
            requestBody.hideFromArticleList === 'yes';
          if (values.noExpiryDate) {
            requestBody.expiryDate = null;
          }
          const submit$ = article
            ? appService.article.updateArticle(article.id, requestBody)
            : appService.article.createArticle(requestBody);
          submit$
            .then(res => {
              let successMessage = `文章\`${requestBody.title}\`已新增`;
              if (article) {
                successMessage = `文章Z\`${requestBody.title}\`已更新`;
                setArticle(res);
              }
              enqueueSnackbar(successMessage);
              navigate(`/article/${res.id}`);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
        validationSchema={schemaArticle}
      >
        {formProps => {
          const {
            values,
            errors,
            setFieldValue,
            submitCount,
            handleSubmit,
            isSubmitting,
          } = formProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormSelectField
                        id="category"
                        width={1}
                        label="form.category"
                        options={articleCategories}
                        formProps={formProps}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormSelectField
                        id="hideFromArticleList"
                        width={1}
                        label="form.hideFromArticleList"
                        options={[
                          {
                            label: '不隱藏',
                            value: 'no',
                          },
                          {
                            label: '隱藏',
                            value: 'yes',
                          },
                        ]}
                        formProps={formProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormInputField
                    id="slug"
                    label="form.slug"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputField
                    id="author"
                    label="form.author"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box width={400}>
                    <FormAssetUpload
                      image
                      freeFormCrop
                      withAssetDescription
                      label="圖片"
                      ratio={16 / 9}
                      asset={values.image}
                      onUploadedFile={asset => setFieldValue('image', asset)}
                      errorMessage={submitCount > 0 && errors.image}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormInputField
                    id="title"
                    label="form.title"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormHashtags
                    label="form.hashtags"
                    type="article"
                    hashtags={values.hashtags
                      .split(',')
                      .filter(x => !!x)
                      .map(x => x.trim())}
                    onChange={hashtags =>
                      setFieldValue('hashtags', hashtags.join(','))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormDateTimePicker
                    label={'發佈日期及時間'}
                    value={values.publishDate}
                    onChange={date => setFieldValue('publishDate', date)}
                    errorMessage={submitCount > 0 && errors.publishDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AppTypography children="下架日期及時間" />
                  <Box display="flex" alignItems="center">
                    <FormCheckboxGroup
                      options={[
                        {
                          label: '',
                          value: 'true',
                        },
                      ]}
                      value={values.noExpiryDate ? [] : ['true']}
                      onChange={value =>
                        setFieldValue('noExpiryDate', !value.includes('true'))
                      }
                    />
                    {!values.noExpiryDate ? (
                      <FormDateTimePicker
                        mt={0.75}
                        value={values.expiryDate}
                        onChange={date => setFieldValue('expiryDate', date)}
                        errorMessage={submitCount > 0 && errors.expiryDate}
                      />
                    ) : (
                      <AppTypography component="span" variant="h6">
                        沒有
                      </AppTypography>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 1, mb: 2 }} />

              <AppTypography mb={2} variant="h4" children="SEO" />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormInputField
                    id="seoTitle"
                    label="form.seoTitle"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextareaField
                    id="seoDescription"
                    label="form.seoDescription"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSeoKeywords
                    label={'SEO 關鍵字'}
                    seoKeywords={values.seoKeywords
                      .split(',')
                      .filter(x => !!x)
                      .map(x => x.trim())}
                    onChange={seoKeywords =>
                      setFieldValue('seoKeywords', seoKeywords.join(','))
                    }
                  />
                </Grid>
              </Grid>

              <Box mt={2} display="flex" justifyContent="flex-end">
                <AppButton
                  type="submit"
                  i={article ? 'article.update' : 'article.create'}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  showLoading={isSubmitting}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
    </BaseLayout>
  );
};

const ArticleFormVariants = {
  Create: withPage({
    level: 1,
    route: {
      title: '新增文章',
      pathname: '/article/create',
    },
  })(ArticleForm),
  Edit: withPage({
    level: 2,
    route: {
      title: '編輯文章',
      pathname: `/article/:id/edit`,
    },
  })(ArticleForm),
};

export default ArticleFormVariants;
