import { Box, styled } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';

const Root = styled(Box)(({ theme }) => ({
  padding: 16,
}));

const Header = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flex: 1,
  zIndex: 1,
  '& > *:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 12,
  },
  '& > *:last-of-type': {
    marginLeft: 16,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
}));

const BaseLayout = ({
  title,
  titleVariant = 'h5',
  headerLeft,
  headerRight,
  children,
  noMb,
  ...props
}) => {
  const renderTitle = () => {
    return <AppTypography variant={titleVariant}>{title}</AppTypography>;
  };
  return (
    <Root {...props}>
      {title && headerLeft && <Box mb={!noMb ? 2 : 0}>{renderTitle()}</Box>}
      {(title || headerLeft || headerRight) && (
        <Header mb={!headerLeft && !noMb ? 2 : 0}>
          <div>{headerLeft || renderTitle()}</div>
          <div>{headerRight}</div>
        </Header>
      )}
      {children}
    </Root>
  );
};

export default BaseLayout;
