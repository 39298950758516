import { Box } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import { getObjectPathValue } from '@utilities/common';

const withFormControlWrapper = Component => props => {
  const {
    id,
    mt,
    ml,
    mr,
    mb,
    pt,
    pl,
    pr,
    pb,
    width,
    label,
    hint,
    value,
    errorMessage,
    formProps,
    ...otherProps
  } = props;
  const values = formProps?.values || {};
  const getValue = () => {
    if (!formProps) {
      return value;
    }
    return getObjectPathValue(values, id);
  };
  const getErrorMessage = () => {
    if (!formProps) {
      return errorMessage;
    }
    const touched = getObjectPathValue(formProps?.touched, id);
    return touched ? getObjectPathValue(formProps?.errors, id) : null;
  };
  return (
    <Box {...{ mt, ml, mr, mb, pt, pl, pr, pb, width }}>
      {label && <AppTypography mb={1} i={label} />}

      <Component
        id={id}
        value={getValue()}
        formProps={formProps}
        hasError={!!getErrorMessage()}
        {...otherProps}
      />

      {hint && <AppTypography mt={1} i={hint} />}

      {getErrorMessage() && (
        <AppTypography mt={1} color="error.main" i={getErrorMessage()} />
      )}
    </Box>
  );
};

export default withFormControlWrapper;
