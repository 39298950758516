const chargeTypes = [
  {
    label: '私家醫院門診',
    value: '私家醫院門診',
  },
  {
    label: '私家醫院專科門診',
    value: '私家醫院專科門診',
  },
  {
    label: '乳房X光',
    value: '乳房X光',
  },
  {
    label: '乳房超聲波',
    value: '乳房超聲波',
  },
  {
    label: '私家醫院乳房磁力共振',
    value: '私家醫院乳房磁力共振',
  },
  {
    label: '乳房活組織檢查（粗針）',
    value: '乳房活組織檢查（粗針）',
  },
  {
    label: '乳房腫瘤切除手術',
    value: '乳房腫瘤切除手術',
  },
  {
    label: '全乳切除手術',
    value: '全乳切除手術',
  },
  {
    label: '淋巴切片檢查（乳房腫瘤切除）',
    value: '淋巴切片檢查（乳房腫瘤切除）',
  },
  {
    label: '腋下淋巴切除手術（乳房腫瘤切除）',
    value: '腋下淋巴切除手術（乳房腫瘤切除）',
  },
  {
    label: '淋巴切片檢查（全乳切除）',
    value: '淋巴切片檢查（全乳切除）',
  },
  {
    label: '腋下淋巴切除手術（全乳切除）',
    value: '腋下淋巴切除手術（全乳切除）',
  },
  {
    label: '前置化療',
    value: '前置化療',
  },
  {
    label: '化療',
    value: '化療',
  },
  {
    label: '電療/放射治療',
    value: '電療/放射治療',
  },
  {
    label: '標靶藥物',
    value: '標靶藥物',
  },
  {
    label: '物理治療',
    value: '物理治療',
  },
];

export default chargeTypes;
