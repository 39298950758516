import BaseLayout from '@components/layouts/BaseLayout';
import useAppService from '@hooks/use-app-service';
import ModulePage from '@components/module-page/ModulePage';

const ArticleDetailsModules = ({ article, setArticle }) => {
  const appService = useAppService();
  const saveModulesPromise = modules => {
    return appService.article.updateArticleModulePageModules(
      article.id,
      modules
    );
  };
  const handleSaved = () => {
    return appService.article.getArticle(article.id).then(setArticle);
  };
  return (
    <BaseLayout sx={{ p: 0 }}>
      <ModulePage
        title="模組"
        editButtonTitle="編輯模組"
        saveButtonTitle="儲存模組"
        successMessage="模組已更新"
        modulePage={article.modulePage}
        availableTypes={[
          'text',
          'image-text',
          'image-text-background',
          'quote',
          'showcase-style-a',
          'showcase-style-b',
          'showcase-style-c',
          'point',
          'tab',
          'step',
          'table',
          'accordion',
          'questionnaire',
          'checklist',
          'zoomable-image',
          'video',
          'banner',
        ]}
        saveModulesPromise={saveModulesPromise}
        onSaved={handleSaved}
      />
    </BaseLayout>
  );
};

export default ArticleDetailsModules;
