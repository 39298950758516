import { useEffect, useState } from 'react';
import { moveListItem } from '@utilities/common';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AppTypography from '@components/_common/AppTypography';
import AppButton from '@components/_common/AppButton';

const ReorderDialog = props => {
  const { open, title, items, onClickUpdate, onClickCancel } = props;
  const [initialItemList, setInitialItemList] = useState(null);
  const [itemList, setItemList] = useState(null);

  useEffect(() => {
    if (open && items) {
      setInitialItemList(items);
      setItemList(items);
    }
  }, [open, items]);

  const isReordered = () => {
    if (initialItemList && itemList) {
      return (
        JSON.stringify(initialItemList.map(x => x.key)) !==
        JSON.stringify(itemList.map(x => x.key))
      );
    }
    return false;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    setItemList(moveListItem(itemList, fromIndex, toIndex));
  };
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClickCancel}>
      <DialogTitle children={title} />
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {itemList?.map((item, index) => (
                  <Draggable
                    key={item.key}
                    draggableId={item.key}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          userSelect: 'none',
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                        {...provided.dragHandleProps}
                      >
                        <Box
                          sx={{
                            width: '100vw',
                            maxWidth: '100%',
                            minHeight: 48,
                            padding: '4px 16px',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: '6px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <AppTypography children={item.name} />
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClickCancel} children="取消" />

        <AppButton
          ml={2}
          children="更改排序"
          onClick={() => onClickUpdate && onClickUpdate(itemList)}
          disabled={false && !isReordered()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReorderDialog;
