import { Grid } from '@mui/material';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import AppTypography from '@components/_common/AppTypography';
import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';

const LessonZoomableImageModule = props => {
  const { data, setFieldValue, editMode } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <FormAssetUpload
          image
          freeFormCrop
          withAssetDescription
          label="圖片"
          ratio={4 / 3}
          asset={data.image}
          onUploadedFile={asset => {
            setFieldValue({ image: asset });
          }}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={7}>
        <FormAssetUpload
          image
          imageLarge
          freeFormCrop
          withAssetDescription
          label="圖片 (大)"
          ratio={4 / 3}
          asset={data.imageLarge}
          onUploadedFile={asset => {
            setFieldValue({ imageLarge: asset });
          }}
          disabled={!editMode}
        />
        <AppTypography mt={0.5} ml={0.5} children="放大縮小用" />
      </Grid>
    </Grid>
  );
};

export default withModuleWrapper('圖片 (放大縮小)')(LessonZoomableImageModule);
