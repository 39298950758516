import BaseLayout from '@components/layouts/BaseLayout';
import { Grid } from '@mui/material';
import useFormUtilities from '@hooks/use-form-utilities';
import useUser from '@hooks/redux/use-user';

const ProfileSummary = () => {
  const { value: user } = useUser();
  const { renderLabelValue } = useFormUtilities();
  return (
    <BaseLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderLabelValue({ label: '用戶身分', value: user.role.name })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLabelValue({ label: '用戶名稱', value: user.name })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLabelValue({ label: '電郵地址', value: user.email })}
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default ProfileSummary;
