import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { logout } from '@redux/slices/app';

const useLogout = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
};

export default useLogout;
