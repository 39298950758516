import BaseLayout from '@components/layouts/BaseLayout';
import { Formik } from 'formik';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import useAppNavigate from '@hooks/use-app-navigate';
import schemaCharge from '@validations/charge.schema';
import { Box, Grid } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import FormSelectField from '@components/forms/FormSelectField';
import withPage from '@hocs/with-page';
import chargeTypes from '@configurations/charge-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const ChargeForm = ({ charge, setCharge }) => {
  const location = useLocation();
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useAppNavigate();
  const queryType = queryString.parse(location.search).type;
  const isGlobalCharge = !!charge && !charge.category;
  return (
    <BaseLayout>
      <Formik
        initialValues={{
          global: isGlobalCharge,
          type:
            charge?.type ||
            chargeTypes.find(x => x.value === queryType)?.value ||
            chargeTypes[0].value,
          region: charge?.region || '',
          place: charge?.place || '',
          cost: charge?.cost || '',
          costMax: charge?.costMax || '',
          remarks: charge?.remarks || '',
          link: charge?.link || '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          const requestBody = {
            ...values,
            ...values,
            cost: values.cost ? Number(values.cost) : null,
            costMax: values.costMax ? Number(values.costMax) : null,
          };
          const submit$ = charge
            ? appService.charge.updateCharge(charge.id, requestBody)
            : appService.charge.createCharge(requestBody);
          submit$
            .then(res => {
              let name = `${values.type}`;
              if (!isGlobalCharge) {
                name = `${values.type} | ${values.place}`;
              }
              let successMessage = `收費\`${name}\`已新增`;
              if (charge) {
                successMessage = `收費\`${name}\`已更新`;
                setCharge(res);
              }
              enqueueSnackbar(successMessage);
              navigate(`/charge/${res.id}`);
            })
            .catch(err => {
              setSubmitting(false);
              appService.handleError(err);
            });
        }}
        validationSchema={schemaCharge}
      >
        {formProps => {
          const { handleSubmit, isSubmitting } = formProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} hidden={isGlobalCharge}>
                  <FormSelectField
                    id="type"
                    width={240}
                    label="form.chargeType"
                    options={chargeTypes}
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={6} hidden={isGlobalCharge}>
                  <FormInputField
                    id="region"
                    label="form.chargeRegion"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={6} hidden={isGlobalCharge}>
                  <FormInputField
                    id="place"
                    label="form.chargePlace"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInputField
                    id="cost"
                    label="form.chargeCost"
                    formProps={formProps}
                    currency
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInputField
                    id="costMax"
                    label="form.chargeCostMax"
                    formProps={formProps}
                    currency
                  />
                </Grid>
                <Grid item xs={12} hidden={isGlobalCharge}>
                  <FormInputField
                    id="remarks"
                    label="form.remarks"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={12} hidden={isGlobalCharge}>
                  <FormInputField
                    id="link"
                    label="form.link"
                    formProps={formProps}
                  />
                </Grid>
              </Grid>

              <Box mt={2} display="flex" justifyContent="flex-end">
                <AppButton
                  type="submit"
                  i={charge ? 'charge.update' : 'charge.create'}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  showLoading={isSubmitting}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
    </BaseLayout>
  );
};

const ChargeFormVariants = {
  Create: withPage({
    level: 1,
    route: {
      title: '新增收費',
      pathname: '/charge/create',
    },
  })(ChargeForm),
  Edit: withPage({
    level: 2,
    route: {
      title: '編輯收費',
      pathname: `/charge/:id/edit`,
    },
  })(ChargeForm),
};

export default ChargeFormVariants;
