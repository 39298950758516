import BaseLayout from '@components/layouts/BaseLayout';
import withFetchPage from '@hocs/with-fetch-page';
import { useMemo, useState } from 'react';
import { Formik } from 'formik';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import { Divider, Grid } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import AppTypography from '@components/_common/AppTypography';
import FormRichText from '@components/forms/FormRichText';

const Home = ({ page, setPage, pageName }) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);
  const initialValues = useMemo(
    () => ({
      aboutUsTitle: page?.aboutUsTitle || '',
      aboutUsBody: page?.aboutUsBody || '',
      privacyPolicyTitle: page?.privacyPolicyTitle || '',
      privacyPolicyBody: page?.privacyPolicyBody || '',
      termsAndConditionsTitle: page?.termsAndConditionsTitle || '',
      termsAndConditionsBody: page?.termsAndConditionsBody || '',
    }),
    [page]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        appService.page
          .updatePage({
            pagePath: pageName,
            body: values,
          })
          .then(res => {
            enqueueSnackbar(`頁面已更新`);
            setSubmitting(false);
            setPage(res);
            setEditMode(false);
          }, appService.handleError);
      }}
      enableReinitialize
      validateOnMount
    >
      {formProps => {
        const { setFieldValue, handleSubmit, isSubmitting } = formProps;
        return (
          <BaseLayout
            title="共用內容"
            headerRight={
              <>
                {editMode ? (
                  <AppButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                  >
                    更新
                  </AppButton>
                ) : (
                  <AppButton onClick={() => setEditMode(true)}>編輯</AppButton>
                )}
              </>
            }
            noMb
          >
            <form onSubmit={handleSubmit}>
              <AppTypography mb={2} variant="h4" children="關於我們" />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormInputField
                    id="aboutUsTitle"
                    label="標題"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormRichText
                    id="aboutUsBody"
                    label="內容"
                    height={400}
                    onChange={content => setFieldValue(`aboutUsBody`, content)}
                    formProps={formProps}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h4" children="私隱聲明" />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormInputField
                    id="privacyPolicyTitle"
                    label="標題"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormRichText
                    id="privacyPolicyBody"
                    label="內容"
                    height={400}
                    onChange={content =>
                      setFieldValue(`privacyPolicyBody`, content)
                    }
                    formProps={formProps}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h4" children="條款及細則" />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormInputField
                    id="termsAndConditionsTitle"
                    label="標題"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormRichText
                    id="termsAndConditionsBody"
                    label="內容"
                    height={400}
                    onChange={content =>
                      setFieldValue(`termsAndConditionsBody`, content)
                    }
                    formProps={formProps}
                  />
                </Grid>
              </Grid>
            </form>
          </BaseLayout>
        );
      }}
    </Formik>
  );
};

export default withFetchPage({
  pageName: 'common',
})(Home);
