import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const FormCheckboxGroup = ({ options, value, onChange, disabled }) => {
  return (
    <FormGroup row>
      {options.map(option => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              checked={value.includes(option.value)}
              name={option.label}
              color="primary"
              onChange={e => {
                if (value.includes(option.value)) {
                  onChange && onChange(value.filter(id => id !== option.value));
                } else {
                  onChange && onChange([...value, option.value]);
                }
              }}
              disabled={disabled}
            />
          }
          label={option.label}
        />
      ))}
    </FormGroup>
  );
};

export default withFormControlWrapper(FormCheckboxGroup);
