import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

const ConfirmDialog = ({
  title,
  description,
  confirmButtonColor,
  onConfirm,
  onClose,
  ...props
}) => {
  const handleConfirm = () => {
    onClose();
    onConfirm();
  };
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          取消
        </Button>
        <Button onClick={handleConfirm} color={confirmButtonColor}>
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
