import { Box, Collapse, Drawer, styled } from '@mui/material';
import logoFull from '@assets/images/logo-full.svg';
import { useEffect, useState } from 'react';
import AppTypography from '@components/_common/AppTypography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { NavLink, useLocation } from 'react-router-dom';
import useI18n from '@hooks/use-i18n';
import routes from '@configurations/routes';
import Breadcrumbs from '@components/_common/Breadcrumbs';
import useAppService from '@hooks/use-app-service';
import useOpenMenuDrawer from '@hooks/redux/use-open-menu-drawer';
import useLogout from '@hooks/redux/use-logout';
import useUser from '@hooks/redux/use-user';

const Aside = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  height: '100vh',
  flexShrink: 0,
  display: { sm: 'none', md: 'block' },
  borderRight: `1px solid ${theme.palette.divider}`,
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavigationMenu = styled(Box)(() => ({
  width: 250,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& > *:first-of-type': {
    padding: '20px 16px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  '& > *:last-of-type': {
    padding: '0 16px 12px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: 1,
  },
}));

const StyledNavigationLink = styled(Box)(() => ({
  '> *': {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    borderRadius: 6,
    '&.active': {
      backgroundColor: '#F4F4F4',
    },
  },
  '> :not(.MuiCollapse-root)': {
    padding: '10px 8px',
  },
  'svg:first-of-type': {
    marginRight: 8,
  },
}));

const Main = styled(Box)(({ theme }) => ({
  paddingLeft: 251,
  width: '100%',
  minHeight: '100vh',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

const NavigationLink = ({ title, url, icon, routes, onClick }) => {
  const location = useLocation();
  const { getLocalizedUrl } = useI18n();
  const [openCollapseMenu, setOpenCollapseMenu] = useState(
    routes?.some(x => location.pathname.includes(x.url))
  );
  const Icon = icon;

  const handleClickNavigationItem = e => {
    if (routes) {
      setOpenCollapseMenu(!openCollapseMenu);
    }
    if (onClick) {
      onClick();
    }
  };

  const content = (
    <>
      {icon && <Icon />}
      <AppTypography color="textPrimary" children={title} />
      {routes && (
        <>
          <Box flex={1} />
          {openCollapseMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </>
      )}
    </>
  );
  return (
    <StyledNavigationLink>
      {url ? (
        <NavLink
          to={getLocalizedUrl(url)}
          onClick={handleClickNavigationItem}
          children={content}
        />
      ) : (
        <Box
          component="button"
          type="button"
          width={1}
          onClick={handleClickNavigationItem}
          children={content}
        />
      )}

      {routes && (
        <Collapse in={openCollapseMenu} style={{ flexShrink: 0 }}>
          <Box pl={4.5}>
            {routes.map(route => (
              <NavigationLink key={route.title} {...route} />
            ))}
          </Box>
        </Collapse>
      )}
    </StyledNavigationLink>
  );
};

const MainLayout = ({ children }) => {
  const appService = useAppService();
  const location = useLocation();
  const { value: openMenuDrawer, setValue: setOpenMenuDrawer } = useOpenMenuDrawer(); // prettier-ignore
  const { value: user } = useUser();
  const logout = useLogout();

  useEffect(() => {
    setOpenMenuDrawer(false);
  }, [location, setOpenMenuDrawer]);

  const handleLogout = () => {
    appService.authentication.logout().then().catch();
    logout();
  };

  const renderMenu = () => (
    <NavigationMenu>
      <Box>
        <Box component="img" height={64} width="auto" src={logoFull} />
      </Box>
      <Box>
        {routes.map(route =>
          route.path || route.routes ? (
            <NavigationLink key={route.title} {...route} />
          ) : (
            <AppTypography
              key={route.title}
              variant="subtitle1"
              children={route.title}
              sx={{ p: 1 }}
            />
          )
        )}
        <Box flex={1} />
        <NavigationLink
          title={`${user.name}`}
          url="/profile"
          icon={PersonIcon}
        />
        <NavigationLink title="登出" icon={LogoutIcon} onClick={handleLogout} />
      </Box>
    </NavigationMenu>
  );

  return (
    <>
      <Aside component="aside">{renderMenu()}</Aside>
      <Main component="main">
        <Breadcrumbs />
        <Box flex={1}>{children}</Box>
      </Main>
      <Drawer
        anchor="left"
        open={openMenuDrawer}
        onClose={() => setOpenMenuDrawer(false)}
      >
        {renderMenu()}
      </Drawer>
    </>
  );
};

export default MainLayout;
