import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import MemberDetailsPage from './MemberDetails';
import withFetchModelWithId from '@hocs/with-fetch-model-with-id';
import withPage from '@hocs/with-page';

const MemberDetails = ({ fetchedModel, ...props }) => {
  const [member, setMember] = useState(fetchedModel);
  const _props = {
    ...props,
    member,
    setMember,
  };

  return (
    <Routes>
      <Route index element={<MemberDetailsPage tab="summary" {..._props} />} />
      <Route
        path="summary"
        element={<MemberDetailsPage tab="summary" {..._props} />}
      />
    </Routes>
  );
};

const page = {
  level: 1,
  route: {
    title: '',
    pathname: '/member/:id',
    fetching: true,
  },
};

export default withPage(page)(
  withFetchModelWithId({
    serviceName: 'user',
    promiseName: 'getUser',
    fallbackUrl: '/user/list',
    title: '{nickname}',
    page: page,
  })(MemberDetails)
);
