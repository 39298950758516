const charge = request => ({
  getChargeList: params =>
    request({
      method: 'get',
      endpoint: `/charge/list`,
      params: params,
    }),

  getCharge: id =>
    request({
      method: 'get',
      endpoint: `/charge/${id}`,
    }),

  getChargeLogList: id => params =>
    request({
      method: 'get',
      endpoint: `/charge/${id}/log/list`,
      params: params,
    }),

  createCharge: body =>
    request({
      method: 'post',
      endpoint: '/charge',
      data: body,
    }),

  updateCharge: (id, body) =>
    request({
      method: 'put',
      endpoint: `/charge/${id}`,
      data: body,
    }),

  updateChargeStatus: (
    id,
    action // publish | archive | delete
  ) =>
    request({
      method: 'post',
      endpoint: `/charge/${id}/${action}`,
    }),

  reorderChargeList: body =>
    request({
      method: 'post',
      endpoint: '/charge/reorder',
      data: body,
    }),
});

export default charge;
