import { Box, useTheme } from '@mui/material';
import withFormControlWrapper from '@hocs/with-form-control-wrapper';

const FormTextareaField = ({ id, hasError, formProps, disabled, ...props }) => {
  const {
    values = {},
    isSubmitting,
    handleChange,
    handleBlur,
  } = formProps || {};
  const theme = useTheme();
  return (
    <>
      <Box
        id={id}
        component="textarea"
        width={1}
        value={values[id]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled || isSubmitting}
        sx={{
          ...theme.typography.body1,

          padding: theme.spacing(1.25, 2),
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '6px',
          outline: 0,
          margin: 0,
          transition: 'background-color .2s, border-color .2s',
          WebkitAppearance: 'none',
          '&::placeholder': {
            color: theme.palette.common.grey,
          },
          '&:hover': {
            backgroundColor: '#F8F8F8',
            border: '1px solid ' + theme.palette.primary.main,
          },
          '&:focus': {
            backgroundColor: '#F8F8F8',
            padding: theme.spacing(1.125, 1.875),
            border: '2px solid ' + theme.palette.primary.main,
          },
          '&:disabled': {
            backgroundColor: theme.palette.common.greyLight,
            border: '1px solid ' + theme.palette.common.greyLight,
            cursor: 'not-allowed',
          },
          ...(hasError && {
            borderColor: 'error.main',
            borderWidth: 2,
          }),
        }}
        {...props}
      />
    </>
  );
};

export default withFormControlWrapper(FormTextareaField);
