import { Box } from '@mui/material';
import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import FormDatePicker from './FormDatePicker';
import FormTimePicker from './FormTimePicker';

const FormDateTimePicker = ({ value, onChange }) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        <FormDatePicker width={150} popup value={value} onChange={onChange} />
        <FormTimePicker width={120} popup value={value} onChange={onChange} />
      </Box>
    </>
  );
};

export default withFormControlWrapper(FormDateTimePicker);
