const selectOption = request => ({
  getSelectOptionList: params =>
    request({
      method: 'get',
      endpoint: `/select-option/list`,
      params,
    }),

  updateSelectOptions: data =>
    request({
      method: 'put',
      endpoint: `/select-option/category`,
      data,
    }),
});

export default selectOption;
