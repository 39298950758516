import { Box, IconButton, useTheme } from '@mui/material';
import useI18n from '@hooks/use-i18n';
import useRoutes from '@hooks/redux/use-routes';
import AppTypography from '@components/_common/AppTypography';
import AppLoadingDot from '@components/_common/AppLoadingDot';
import { NavLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import useOpenMenuDrawer from '@hooks/redux/use-open-menu-drawer';
import { useEffect } from 'react';

const Breadcrumbs = () => {
  const theme = useTheme();
  const { getLocalizedUrl } = useI18n();
  const { value: routes, setValue: setRoutes } = useRoutes();
  const { setValue: setOpenMenuDrawer } = useOpenMenuDrawer();

  // Remove child routes when parent route is clicked
  const location = useLocation();
  const pathname = location.pathname.split('?')[0].substring(3);
  useEffect(() => {
    const index = routes.map(x => x.pathname).indexOf(pathname);
    if (index >= 0 && index < routes.length - 1) {
      const updatedRoutes = [...routes];
      updatedRoutes.splice(index + 1);
      setRoutes(updatedRoutes);
    }
  }, [pathname, routes, setRoutes]);

  return (
    <Box
      px={2}
      height={64}
      display="flex"
      alignItems="center"
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}}`,
        'p > span:not(:first-of-type)': {
          ml: 1,
        },
      }}
    >
      <Box display={{ xs: 'block', md: 'none' }} ml={-1.25} mr={0.5}>
        <IconButton
          aria-label="Open Menu"
          onClick={() => setOpenMenuDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Box
        component="ul"
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          a: {
            transition: 'all 0.2s ' + theme.transitions.easing.easeInOut,
            '&:hover': {
              opacity: 0.5,
            },
          },
        }}
      >
        {routes.map((route, index) => {
          const isLast = index === routes.length - 1;
          const renderTitle = () => (
            <AppTypography component="span" variant={index === 0 ? 'h3' : 'h6'}>
              {route.title}
            </AppTypography>
          );
          return (
            <Box
              component="li"
              key={route.title}
              sx={
                index > 0
                  ? {
                      position: 'relative',
                      ml: 1.25,
                      pl: 1.75,
                      '&::before': {
                        content: '"/"',
                        position: 'absolute',
                        left: 0,
                        bottom: 3,
                      },
                    }
                  : {}
              }
            >
              {route.fetching ? (
                <AppLoadingDot py={0.875} />
              ) : isLast ? (
                renderTitle()
              ) : (
                <NavLink to={getLocalizedUrl(route.pathname)}>
                  {renderTitle()}
                </NavLink>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Breadcrumbs;
