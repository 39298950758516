import { Grid } from '@mui/material';
import FormRichText from '@components/forms/FormRichText';
import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';

const LessonTableModule = props => {
  const { data, setFieldValue, editMode } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormRichText
          height={400}
          value={data.table}
          onChange={content => setFieldValue({ table: content })}
          disabled={!editMode}
        />
      </Grid>
    </Grid>
  );
};

export default withModuleWrapper('表格')(LessonTableModule);
