import BaseLayout from '@components/layouts/BaseLayout';
import { Grid } from '@mui/material';
import useFormUtilities from '@hooks/use-form-utilities';
import { getStatusChip } from '@constants/status';

const ChargeDetailsSummary = ({ charge }) => {
  const { renderLabelChildren, renderLabelValue } = useFormUtilities();
  const isGlobalCharge = !charge.category;
  return (
    <BaseLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} hidden={isGlobalCharge}>
          {renderLabelChildren({
            label: '狀態',
            children: getStatusChip('Charge', charge.status, { mt: -0.5 }),
          })}
        </Grid>
        <Grid item xs={12}>
          {renderLabelValue({ label: 'form.chargeType', value: charge.type })}
        </Grid>
        <Grid item xs={12} sm={6} hidden={isGlobalCharge}>
          {renderLabelValue({
            label: 'form.chargeRegion',
            value: charge.region,
          })}
        </Grid>
        <Grid item xs={12} sm={6} hidden={isGlobalCharge}>
          {renderLabelValue({ label: 'form.chargePlace', value: charge.place })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLabelValue({ label: 'form.chargeCost', value: charge.cost })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLabelValue({
            label: 'form.chargeCostMax',
            value: charge.costMax,
          })}
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default ChargeDetailsSummary;
