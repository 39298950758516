import React from 'react';
import { Grid } from '@mui/material';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import FormRichText from '@components/forms/FormRichText';
import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';

const LessonImageTextModule = props => {
  const { sameTypeIndex, data, setFieldValue, editMode } = props;
  const swap = sameTypeIndex % 2 === 1;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} style={{ order: swap ? 1 : 0 }}>
        <FormAssetUpload
          image
          freeFormCrop
          withAssetDescription
          label="圖片"
          ratio={16 / 9}
          asset={data.image}
          onUploadedFile={asset => {
            setFieldValue({ image: asset });
          }}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={6} style={{ order: swap ? 0 : 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormRichText
              label="標題"
              height={200}
              value={data.title}
              onChange={content => setFieldValue({ title: content })}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormRichText
              label="文字"
              height={350}
              value={data.body}
              onChange={content => setFieldValue({ body: content })}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withModuleWrapper('左圖右字')(LessonImageTextModule);
