import React, { createContext, useState } from 'react';

const LocalizationContext = createContext(null);

const LocalizationProvider = ({ language, localizedStrings, children }) => {
  const [persistedLocalizedStrings] = useState(localizedStrings);
  persistedLocalizedStrings.setLanguage(language);
  return (
    <LocalizationContext.Provider value={persistedLocalizedStrings}>
      {children}
    </LocalizationContext.Provider>
  );
};

export { LocalizationContext, LocalizationProvider };
