import { Navigate, Route, Routes } from 'react-router-dom';
import UserList from '@pages/user/UserList';
import UserDetails from '@pages/user/details';
import withPage from '@hocs/with-page';
import UserForm from '@pages/user/UserForm';

const User = () => {
  return (
    <Routes>
      <Route path="list" Component={UserList} />
      <Route path="create" Component={UserForm.Create} />
      <Route path=":id/*" Component={UserDetails} />
      <Route path="*" element={<Navigate to="list" />} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: 'CMS 用戶',
    pathname: '/cms-user/list',
  },
};

export default withPage(page)(User);
