import FormAssetUpload from '@components/forms/FormAssetUpload';
import { Box, Grid } from '@mui/material';
import FormRichText from '@components/forms/FormRichText';
import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';

const LessonTextModule = props => {
  const { data, setFieldValue, editMode } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box width={400}>
          <FormAssetUpload
            image
            freeFormCrop
            withAssetDescription
            label="圖片"
            ratio={16 / 9}
            asset={data.image}
            onUploadedFile={asset => {
              setFieldValue({ image: asset });
            }}
            disabled={!editMode}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormRichText
          label="副標題"
          height={200}
          value={data.subtitle}
          onChange={content => setFieldValue({ subtitle: content })}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormRichText
          label="標題"
          height={200}
          value={data.title}
          onChange={content => setFieldValue({ title: content })}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormRichText
          label="內容"
          height={400}
          value={data.body}
          onChange={content => setFieldValue({ body: content })}
          disabled={!editMode}
        />
      </Grid>
    </Grid>
  );
};

export default withModuleWrapper('標題及內容')(LessonTextModule);
