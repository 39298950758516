import * as Yup from 'yup';

const schema = Yup.object().shape({
  email: Yup.string()
    .required('validation.emailRequired')
    .email('validation.emailInvalid'),
  password: Yup.string().required('validation.passwordRequired'),
});

export default schema;
