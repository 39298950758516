import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import FormCheckboxGroup from '@components/forms/FormCheckboxGroup';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';

const withModuleWrapper = moduleTitle => Component => props => {
  const {
    data,
    isFirst,
    isLast,
    editMode,
    onMoveUp,
    onMoveDown,
    onChange,
    onDelete,
  } = props;
  const [title, setTitle] = useState(moduleTitle);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const setFieldValue = fieldValueMap => {
    onChange &&
      onChange({
        ...data,
        ...fieldValueMap,
      });
  };
  const setSubFieldValue = (index, subListField, fieldValueMap) => {
    const _data = data;
    _data[subListField][index] = {
      ..._data[subListField][index],
      ...fieldValueMap,
    };
    onChange && onChange(_data);
  };
  const handleChange = (field, value) => {
    onChange &&
      onChange({
        ...data,
        [field]: value,
      });
  };
  const handleClickMoveUp = () => {
    onMoveUp && onMoveUp();
  };
  const handleClickMoveDown = () => {
    onMoveDown && onMoveDown();
  };
  const handleClickDelete = () => {
    setShowConfirmDeleteDialog(true);
  };
  return (
    <Box
      sx={{
        borderRadius: '6px',
        overflow: 'hidden',
        boxShadow:
          '0 2px 4px -1px rgba(0,0,0,.02), 0 4px 5px 0 rgba(0,0,0,.04), 0 1px 10px 0 rgba(0,0,0,.16)',
        backgroundColor: 'white',
        padding: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <AppTypography variant="h5" children={title} />
          <Box width={24} />
          <FormCheckboxGroup
            options={[
              {
                label: '貼近前一個模組',
                value: 'true',
              },
            ]}
            value={data.closeToPreviousModule ? ['true'] : []}
            onChange={value =>
              handleChange('closeToPreviousModule', value.includes('true'))
            }
            disabled={!editMode}
          />
        </Box>

        {editMode && (
          <Box>
            <IconButton
              aria-label="move up"
              onClick={handleClickMoveUp}
              disabled={isFirst}
            >
              <KeyboardArrowUpIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="move down"
              onClick={handleClickMoveDown}
              disabled={isLast}
            >
              <KeyboardArrowDownIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="delete" onClick={handleClickDelete}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box mt={editMode ? 1 : 2}>
        <Component
          setTitle={setTitle}
          setFieldValue={setFieldValue}
          setSubFieldValue={setSubFieldValue}
          {...props}
        />
      </Box>

      <ConfirmDialog
        open={showConfirmDeleteDialog}
        title={'確認移除模組?'}
        onClose={() => setShowConfirmDeleteDialog(false)}
        onConfirm={() => {
          setShowConfirmDeleteDialog(false);
          onDelete && onDelete();
        }}
      />
    </Box>
  );
};

export default withModuleWrapper;
