import { Box, keyframes, useTheme } from '@mui/material';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124;
  }
`;

// Reference : https://codepen.io/jczimm/pen/vEBpoL
const AppLoadingSpinner = ({ size = 48, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        '& > svg': {
          width: size,
          height: size,
          animation: `${rotate} 2s linear infinite`,
          transformOrigin: 'center center',
          '& .path': {
            strokeDasharray: '1, 200',
            strokeDashoffset: 0,
            animation: `${dash} 1.5s ease-in-out infinite`,
            strokeLinecap: 'round',
            stroke: theme.palette.primary.main,
          },
        },
      }}
      {...props}
    >
      <svg viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="5"
          strokeMiterlimit="10"
        />
      </svg>
    </Box>
  );
};

export default AppLoadingSpinner;
