import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setUser } from '@redux/slices/app';

const useUser = () => {
  const dispatch = useDispatch();
  const value = useSelector(state => state.user);
  const setValue = useCallback(
    newValue => {
      dispatch(setUser(newValue));
    },
    [dispatch],
  );
  return {
    value,
    setValue,
  };
};

export default useUser;
