import React, { useState } from 'react';
import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import { format } from 'date-fns';
import AppTypography from '@components/_common/AppTypography';
import { Box, Collapse, Dialog, IconButton } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimeClock } from '@mui/x-date-pickers';

const FormTimePicker = ({ value, onChange, popup, disabled }) => {
  const [showTimePicker, setShowTimePicker] = useState(false);
  const handleOnChangeDate = date => {
    onChange && onChange(date);
  };
  return (
    <>
      <Box mt={-0.5} display="flex" alignItems="center">
        <AppTypography
          mr={1}
          component="span"
          variant="h6"
          sx={{ transform: 'translate(0, -2px)' }}
        >
          {!value ? (
            'N/A'
          ) : (
            <>
              {typeof value === 'object' && format(value, 'HH:mm')}
              {typeof value === 'string' && value}
            </>
          )}
        </AppTypography>
        {!disabled && (
          <IconButton
            color={showTimePicker ? 'primary' : 'default'}
            onClick={() => setShowTimePicker(!showTimePicker)}
          >
            <AccessTimeIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>

      {!popup ? (
        <Collapse in={showTimePicker}>
          <Box width={1}>
            <TimeClock
              ampm={false}
              views={['hours', 'minutes']}
              value={value || new Date()}
              onChange={handleOnChangeDate}
            />
          </Box>
        </Collapse>
      ) : (
        <Dialog open={showTimePicker} onClose={() => setShowTimePicker(false)}>
          <TimeClock
            ampm={false}
            views={['hours', 'minutes']}
            value={value || new Date()}
            onChange={handleOnChangeDate}
          />
        </Dialog>
      )}
    </>
  );
};

export default withFormControlWrapper(FormTimePicker);
