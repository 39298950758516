import { Navigate, Route, Routes } from 'react-router-dom';
import ArticleList from '@pages/article/ArticleList';
import ArticleForm from '@pages/article/ArticleForm';
import ArticleDetails from '@pages/article/details';
import withPage from '@hocs/with-page';

const Article = () => {
  return (
    <Routes>
      <Route path="list" Component={ArticleList} />
      <Route path="create" Component={ArticleForm.Create} />
      <Route path=":id/*" Component={ArticleDetails} />
      <Route path="*" element={<Navigate to="list" />} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '文章',
    pathname: '/article/list',
  },
};

export default withPage(page)(Article);
