import React, { useEffect, useState } from 'react';
import withModuleWrapper from '../hoc/with-module-wrapper';
import { deleteListItem, swapListItem } from '@utilities/common';
import FormInputField from '@components/forms/FormInputField';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import FormRichText from '@components/forms/FormRichText';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import AppButton from '@components/_common/AppButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import AppTypography from '@components/_common/AppTypography';

const LessonPointModule = props => {
  const {
    data,
    setTitle,
    setFieldValue,
    setSubFieldValue,
    editMode,
    onChange,
  } = props;
  const [indexToBeDeleted, setIndexToBeDeleted] = useState(null);

  useEffect(() => {
    if (data.style === 'StylePoint') {
      setTitle('Point');
    } else if (data.style === 'StyleStep') {
      setTitle('Step');
    }
  }, [data.style, setTitle]);

  const handleClickAddItem = () => {
    onChange &&
      onChange({
        ...data,
        items: [
          ...data.items,
          {
            body: '',
          },
        ],
      });
  };
  const handleSwap = (fromIndex, toIndex) => {
    const _data = data;
    _data.items = swapListItem(_data.items, fromIndex, toIndex);
    onChange && onChange(_data);
  };
  const handleDeleteItem = () => {
    const _data = data;
    _data.items = deleteListItem(_data.items, indexToBeDeleted);
    onChange && onChange(_data);
    setIndexToBeDeleted(null);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputField
            label="標題"
            value={data.title}
            onChange={e =>
              setFieldValue({
                title: e.target.value,
              })
            }
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12}>
          <Box width={400}>
            <FormAssetUpload
              image
              freeFormCrop
              withAssetDescription
              label="圖片"
              ratio={16 / 9}
              asset={data.image}
              onUploadedFile={asset => {
                setFieldValue({ image: asset });
              }}
              disabled={!editMode}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormRichText
            label="內容"
            height={350}
            value={data.body}
            onChange={content =>
              setFieldValue({
                body: content,
              })
            }
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12}>
          {data.items.map((item, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Divider sx={{ my: 2 }} />}

              <Box display="flex">
                <Box flex={1}>
                  <AppTypography
                    mb={1}
                    variant="subtitle1"
                    children={`#${index + 1}`}
                  />

                  <FormRichText
                    height={350}
                    value={item.body}
                    onChange={content =>
                      setSubFieldValue(index, 'items', {
                        body: content,
                      })
                    }
                    disabled={!editMode}
                  />
                </Box>
                {editMode && (
                  <Box ml={2} display="flex" flexDirection="column">
                    <IconButton
                      aria-label="move up"
                      onClick={() => handleSwap(index, index - 1)}
                      disabled={index === 0}
                    >
                      <KeyboardArrowUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="move down"
                      onClick={() => handleSwap(index, index + 1)}
                      disabled={index >= data.items.length - 1}
                    >
                      <KeyboardArrowDownIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setIndexToBeDeleted(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </React.Fragment>
          ))}

          {editMode && (
            <AppButton
              mt={data.items.length > 0 ? 2 : 0}
              children={
                data.style === 'StyleStep'
                  ? '新增 Step Item'
                  : '新增 Point Item'
              }
              onClick={handleClickAddItem}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormRichText
            label="備註"
            height={350}
            value={data.remarks}
            onChange={content =>
              setFieldValue({
                remarks: content,
              })
            }
            disabled={!editMode}
          />
        </Grid>
      </Grid>

      <ConfirmDialog
        open={indexToBeDeleted != null}
        title={
          data.style === 'StyleStep'
            ? '確認移除Step Item?'
            : '確認移除Point Item?'
        }
        onClose={() => setIndexToBeDeleted(null)}
        onConfirm={handleDeleteItem}
      />
    </>
  );
};

export default withModuleWrapper('Point')(LessonPointModule);
