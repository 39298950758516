import { Box, Button } from '@mui/material';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import AppLoadingDot from '@components/_common/AppLoadingDot';

const ConfirmButton = ({
  color,
  title,
  dialogTitle,
  action$,
  onActionComplete,
  successMessage,
  sx,
  ...props
}) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const [requesting, setRequesting] = useState(false);
  const handleConfirm = () => {
    showModal(ConfirmDialog, {
      title: dialogTitle,
      confirmButtonColor: color,
      onConfirm: () => {
        setRequesting(true);
        action$()
          .then(res => {
            setRequesting(false);
            onActionComplete && onActionComplete(res);
            enqueueSnackbar(successMessage);
          })
          .catch(err => {
            setRequesting(false);
            appService.handleError(err);
          });
      },
    });
  };
  return (
    <>
      {!requesting ? (
        <Button
          color={color}
          sx={{ ...sx, px: 2, borderRadius: 0, height: 1 }}
          {...props}
          onClick={handleConfirm}
        >
          {title}
        </Button>
      ) : (
        <Box
          px={2}
          height={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AppLoadingDot />
        </Box>
      )}
    </>
  );
};

export default ConfirmButton;
