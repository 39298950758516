import { useCallback, useContext } from 'react';
import { LocalizationContext } from '@contexts/localization';

const useI18n = () => {
  const localizedStrings = useContext(LocalizationContext);

  const language = localizedStrings.getLanguage();

  const getLocalization = (category, key) => {
    if (
      localizedStrings &&
      localizedStrings[category] &&
      localizedStrings[category][key]
    ) {
      return localizedStrings[category][key];
    }
    return key;
  };

  const getLocalizedUrl = useCallback(
    pathname => {
      return `/${language}${pathname}`;
    },
    [language]
  );

  // eslint-disable-next-line no-underscore-dangle
  const __i = (key, ...values) => {
    if (key) {
      const string = key
        .split('.')
        .reduce((a, b) => (a ? a[b] : null), localizedStrings);
      if (values && values.length > 0) {
        return localizedStrings.formatString(string, ...values);
      }
      return string || key;
    }
    return null;
  };

  return {
    language,
    localizedStrings,
    getLocalization,
    getLocalizedUrl,
    __i,
  };
};

export default useI18n;
