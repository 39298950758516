import { Box } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import { isValidColorCode } from '@utilities/common';
import FormInputField from '@components/forms/FormInputField';

const FormInputColorField = ({ label, value, ...props }) => {
  return (
    <>
      <Box mb={1} display="flex" alignItems="center">
        <AppTypography children={label} />
        <Box
          ml={0.5}
          height={12}
          width={12}
          style={{
            backgroundColor: isValidColorCode(value) ? value : '',
            borderRadius: 6,
          }}
        />
      </Box>
      <FormInputField
        hint="提示：如白色，輪入`#FFFFFF`"
        value={value}
        {...props}
      />
    </>
  );
};

export default FormInputColorField;
