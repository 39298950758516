import TabLayout from '@components/layouts/TabLayout';
import ArticleDetailsSummary from '@pages/article/details/_subs/ArticleDetailsSummary';
import ArticleDetailsModules from '@pages/article/details/_subs/ArticleDetailsModules';
import { Button } from '@mui/material';
import AppLink from '@components/_common/AppLink';
import ConfirmButton from '@components/_common/ConfirmButton';
import useAppService from '@hooks/use-app-service';
import useAppNavigate from '@hooks/use-app-navigate';

const ArticleDetails = props => {
  const { tab, article, setArticle } = props;
  const appService = useAppService();
  const navigate = useAppNavigate();
  const tabs = [
    {
      title: '概要',
      key: 'summary',
      url: `/article/${article.id}/summary`,
      component: () => {
        return <ArticleDetailsSummary {...props} />;
      },
    },
    {
      title: '模組',
      key: 'modules',
      url: `/article/${article.id}/modules`,
      component: () => {
        return <ArticleDetailsModules {...props} />;
      },
    },
  ];
  return (
    <TabLayout
      initialValue={tabs.map(x => x.key).indexOf(tab)}
      rightButtons={
        <>
          <AppLink to={`/article/${article.id}/edit`}>
            <Button sx={{ px: 2, height: 1, borderRadius: 0 }}>編輯文章</Button>
          </AppLink>
          {/*{article.status === 'Draft' && (*/}
          {/*  <div>*/}
          {/*    <ConfirmButton*/}
          {/*      color="warning"*/}
          {/*      title="待審批"*/}
          {/*      dialogTitle="待審批文章？"*/}
          {/*      successMessage="文章待審批"*/}
          {/*      action$={() =>*/}
          {/*        appService.article.updateArticleStatus(*/}
          {/*          article.id,*/}
          {/*          'pending-review'*/}
          {/*        )*/}
          {/*      }*/}
          {/*      onActionComplete={setArticle}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{article.status === 'PendingReview' && (*/}
          {/*  <div>*/}
          {/*    <ConfirmButton*/}
          {/*      color="success"*/}
          {/*      title="審批"*/}
          {/*      dialogTitle="已審批文章？"*/}
          {/*      successMessage="文章已審批"*/}
          {/*      action$={() =>*/}
          {/*        appService.article.updateArticleStatus(article.id, 'reviewed')*/}
          {/*      }*/}
          {/*      onActionComplete={setArticle}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {article.status === 'Draft' && (
            <div>
              <ConfirmButton
                color="success"
                title="發佈"
                dialogTitle="決定要發佈文章？"
                successMessage="文章已發佈"
                action$={() =>
                  appService.article.updateArticleStatus(article.id, 'publish')
                }
                onActionComplete={setArticle}
              />
            </div>
          )}
          {article.status === 'Published' && (
            <div>
              <ConfirmButton
                color="error"
                title="隱藏"
                dialogTitle="決定要隱藏文章？"
                successMessage="文章已隱藏"
                action$={() =>
                  appService.article.updateArticleStatus(article.id, 'hide')
                }
                onActionComplete={setArticle}
              />
            </div>
          )}
          {article.status === 'Hidden' && (
            <div>
              <ConfirmButton
                color="success"
                title="顯示"
                dialogTitle="決定要顯示文章？"
                successMessage="文章已顯示"
                action$={() =>
                  appService.article.updateArticleStatus(article.id, 'publish')
                }
                onActionComplete={setArticle}
              />
            </div>
          )}
          {
            <div>
              <ConfirmButton
                color="error"
                title="刪除"
                dialogTitle="決定要刪除文章？"
                successMessage="文章已刪除"
                action$={() =>
                  appService.article.updateArticleStatus(article.id, 'delete')
                }
                onActionComplete={() => {
                  navigate(`/article/list`);
                }}
              />
            </div>
          }
        </>
      }
      tabs={tabs}
    />
  );
};

export default ArticleDetails;
