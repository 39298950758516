import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setOpenMenuDrawer } from '@redux/slices/app';

const useOpenMenuDrawer = () => {
  const dispatch = useDispatch();
  const value = useSelector(state => state.openMenuDrawer);
  const setValue = useCallback(
    newValue => {
      dispatch(setOpenMenuDrawer(newValue));
    },
    [dispatch]
  );
  return {
    value,
    setValue,
  };
};

export default useOpenMenuDrawer;
