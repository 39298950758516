import { Box, Button, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useI18n from '@hooks/use-i18n';
import useAppNavigate from '@hooks/use-app-navigate';

const TabLayout = ({
  initialValue = 0,
  rightButtons,
  buttonRightTo,
  buttonRightTitle,
  tabs,
}) => {
  const navigate = useAppNavigate();
  const { getLocalizedUrl } = useI18n();
  const [value, setValue] = useState(initialValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (tabs[newValue].url) {
      navigate(tabs[newValue].url, { replace: true });
    }
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box display="flex" justifyContent="space-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabs.map(tab => (
              <Tab key={tab.key} label={tab.title} />
            ))}
          </Tabs>
          {rightButtons && (
            <Box
              flexShrink={0}
              display="flex"
              alignItems="center"
              sx={{
                a: { display: 'block' },
                '> *': {
                  height: '100%',
                  borderLeft: 1,
                  borderColor: 'divider',
                },
              }}
            >
              {rightButtons}
            </Box>
          )}
          {buttonRightTo && buttonRightTitle && (
            <Box flexShrink={0} sx={{ borderLeft: 1, borderColor: 'divider' }}>
              <Link to={getLocalizedUrl(buttonRightTo)}>
                <Button
                  variant="text"
                  sx={{ px: 2, height: 1, borderRadius: 0 }}
                >
                  {buttonRightTitle}
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={tab.key} value={value} index={index}>
          {tab.component()}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default TabLayout;
