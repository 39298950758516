import { IconButton, Tooltip } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import { Link } from 'react-router-dom';
import useI18n from '@hooks/use-i18n';

const TooltipIconButton = ({
  title,
  to,
  Icon,
  size = 'small',
  openNewTab,
  ...props
}) => {
  const { language } = useI18n();
  const renderTooltip = () => (
    <Tooltip
      title={<AppTypography sx={{ fontSize: 12 }}>{title}</AppTypography>}
      PopperProps={{
        sx: {
          pointerEvents: 'none',
          '& .MuiTooltip-tooltip': {
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <IconButton
        component={to ? 'div' : 'button'}
        aria-label={title}
        size={size}
        children={<Icon />}
        {...props}
      />
    </Tooltip>
  );

  if (to) {
    if (to.startsWith('http') || openNewTab) {
      return (
        <a
          href={to.startsWith('http') ? to : `/${language}${to}`}
          target="_blank"
          rel="nofollow noreoper noreferrer"
        >
          {renderTooltip()}
        </a>
      );
    } else {
      return <Link to={`/${language}${to}`}>{renderTooltip()}</Link>;
    }
  }
  return renderTooltip();
};

export default TooltipIconButton;
