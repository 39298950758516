import * as Yup from 'yup';

const schema = Yup.object().shape({
  role: Yup.string().required('validation.userRoleRequired'),
  name: Yup.string().required('validation.userNameRequired'),
  email: Yup.string()
    .required('validation.emailRequired')
    .email('validation.emailInvalid'),
  remarks: Yup.string(),
});

export default schema;
