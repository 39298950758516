// prettier-ignore
const tc = {
  common: {
    create: '創建',
    update: '更新',
    edit: '編輯',
  },
  login: {
    title: '歡迎回來',
    body: '登入到照顧者講數 Carers Money CMS',
    forgotPassword: '忘記密碼？',
    resetPasswordSuccess: '重設密碼成功，請使用新密碼登入您的帳號',
    login: '登入',
  },
  forgotPassword: {
    title: '忘記密碼',
    body: '輸入您的電郵地址，我們會發送一個連結給您重設密碼',
    submitButtonTitle: '發送重設密碼電郵',
    emailHasBeenSent: '重設密碼電郵已發送到您的電郵地址',
  },
  resetPassword: {
    title: '重設密碼',
    body: '輸入您的新密碼',
    submitButtonTitle: '重設密碼',
  },
  form: {
    email: '電郵地址',
    password: '密碼',
    confirmPassword: '確認密碼',
    currentPassword: '現時密碼',
    newPassword: '新密碼',
    newPasswordConfirm: '確認新密碼',
    userName: '名稱',
    userRole: '角色',
    remarks: '備註',
    link: '連結',
    chargeType: '種類',
    chargeRegion: '地區',
    chargePlace: '地點',
    chargeCost: '單一收費 / 最低收費 (HK$)',
    chargeCostMax: '最高收費 (HK$)',
    category: '類別',
    slug: '網址',
    author: '作者',
    image: '圖片',
    title: '標題',
    description: '描述',
    hashtag: '標籤',
    hashtags: '標籤',
    publishDate: '發佈日期及時間',
    expiryDate: '下架日期及時間',
    seoTitle: 'SEO 標題',
    seoDescription: 'SEO 濃縮簡介/描述',
    seoKeyword: 'SEO 關鍵字',
    seoKeywords: 'SEO 關鍵字',
    keyVisual: '頂部橫額',
    keyVisualImage: '圖片',
    keyVisualImageMobile: '圖片 (手機)',
    keyVisualImageLink: '連結',
    keyVisualTitle: '標題',
    keyVisualBody: '描述',
    footerTitle: '標題',
    footerButtonTitle: '按鈕標題',
    footerButtonLink: '按鈕連結',
    hideFromArticleList: '於文章列表中隱藏',
  },
  user: {
    create: '新增用戶',
    update: '更新用戶',
  },
  charge: {
    create: '新增收費',
    update: '更新收費',
  },
  article: {
    create: '新增文章',
    update: '更新文章',
  },
  questionnaire: {
    create: '新增問卷',
    update: '更新問卷',
  },
  profile: {
    updatePassword: '更改密碼',
  },
  validation: {
    emailRequired: '請輸入電郵地址',
    emailInvalid: '電郵地址無效',
    passwordRequired: '請輸入密碼',
    passwordConfirmRequired: '請再次輸入密碼',
    passwordConfirmNotMatch: '密碼不相符',
    currentPasswordRequired: '請輸入現時密碼',
    newPasswordRequired: '請輸入新密碼',
    newPasswordConfirmRequired: '請再次輸入新密碼',
    newPasswordConfirmNotMatch: '密碼不相符',
    userNameRequired: '請輸入用戶名稱',
    userRoleRequired: '請選擇用戶角色',
    linkInvalid: '請輪入有效的連結',
    chargeTypeRequired: '請選擇收費種類',
    chargeRegionRequired: '請輪入收費地區',
    chargePlaceRequired: '請輪入收費地點',
  },
  error: {
    login: '登入失敗，請檢查電郵地址和密碼是否正確',
  },
  errorApi: {
    UnauthorizedException: '登入逾時，請重新登入',
    CmsUserAuthenticationFailedException: '驗證錯誤，請重新登入',
  },
};

export default tc;
