import AppTypography from '@components/_common/AppTypography';
import useI18n from '@hooks/use-i18n';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

const Root = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  transition: 'all 0.3s ' + theme.transitions.easing.easeInOut,
  '&:hover': {
    color: '#BC72DA',
  },
}));

const AppLink = ({ to, variant, i, children, sx, ...props }) => {
  const { language } = useI18n();
  return (
    <Root to={`/${language}${to}`} {...props}>
      {i && <AppTypography component="span" variant={variant} i={i} />}
      {children}
    </Root>
  );
};
export default AppLink;
