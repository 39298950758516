import React, { useEffect, useState } from 'react';
import FormTextareaField from '../forms/FormTextareaField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import AppButton from '@components/_common/AppButton';

const TextareaDialog = props => {
  const {
    open,
    title,
    description,
    inputLabel,
    buttonTitle,
    cancelButtonTitle,
    initialValue,
    onClickCancel,
    onClickConfirm,
    ...otherProps
  } = props;
  const [value, setValue] = useState('');
  useEffect(() => {
    if (open) {
      if (initialValue) {
        setValue(initialValue);
      } else {
        setValue('');
      }
    }
  }, [open, initialValue]);
  return (
    <Dialog open={open} onClose={onClickCancel} {...otherProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ minWidth: 400 }}>
        {description && <DialogContentText children={description} />}
        <FormTextareaField
          label={inputLabel}
          value={value}
          onChange={e => setValue(e.target.value)}
          rows={5}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="inherit">
          {cancelButtonTitle || 'Cancel'}
        </Button>
        <AppButton
          children={buttonTitle}
          onClick={() => onClickConfirm && onClickConfirm(value)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default TextareaDialog;
