import TabLayout from '@components/layouts/TabLayout';
import BreastCancerSettings from '@pages/breast-cancer/_subs/BreastCancerSettings';
import { useEffect, useState } from 'react';
import useAppService from '@hooks/use-app-service';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';

const BreastCancer = props => {
  const { tab } = props;
  const appService = useAppService();
  const [calculator, setCalculator] = useState(null);

  useEffect(() => {
    appService.calculator.getBreastCancerCalculator().then(setCalculator);
  }, [appService.calculator]);

  const tabs = [
    {
      title: '設定',
      key: 'settings',
      url: `/breast-cancer/settings`,
      component: () => {
        return <BreastCancerSettings calculator={calculator} {...props} />;
      },
    },
  ];
  return !calculator ? (
    <AppLoadingSpinner mt={5} />
  ) : (
    <TabLayout initialValue={tabs.map(x => x.key).indexOf(tab)} tabs={tabs} />
  );
};

export default BreastCancer;
