import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('請輸入問卷名稱'),
  description: Yup.string().required('請輸入問卷簡介'),
  type: Yup.string().required('請選擇問卷種類'),
  result: Yup.string().when('type', {
    is: value => value === 'Frequency',
    then: schema => schema.required('請輸入問卷結果'),
  }),
  questions: Yup.array().when('type', {
    is: value => value === 'ScoreQuestionSpecificAnswers',
    then: schema =>
      schema
        .of(
          Yup.object().shape({
            id: Yup.string(),
            question: Yup.string().required('請輸入問題'),
            answers: Yup.array()
              .of(
                Yup.object().shape({
                  id: Yup.string(),
                  answer: Yup.string().required('請輸入答案'),
                  score: Yup.string().required('請輸入分數'),
                })
              )
              .min(1, '請輸入最少一個答案'),
          })
        )
        .min(1, '請輸入最少一個答案'),
    otherwise: schema =>
      schema
        .of(
          Yup.object().shape({
            id: Yup.string(),
            question: Yup.string().required('請輸入問題'),
          })
        )
        .min(1, '請輸入最少一條問題'),
  }),
  answers: Yup.array().when('type', {
    is: value => value !== 'ScoreQuestionSpecificAnswers',
    then: schema =>
      schema
        .of(
          Yup.object().shape({
            id: Yup.string(),
            answer: Yup.string().required('請輸入答案'),
            score: Yup.number().when('type', {
              is: value => value === 'Score',
              then: schema => schema.required('請輸入分數'),
              otherwise: schema => schema.nullable(),
            }),
          })
        )
        .min(1, '請輸入最少一個答案'),
  }),
  scoreResults: Yup.array().when('type', {
    is: value => value !== 'Frequency',
    then: schema =>
      schema
        .of(
          Yup.object().shape({
            id: Yup.string(),
            result: Yup.string().required('請輸入結果'),
            scoreMin: Yup.string().required('請輸入顯示結果之最少分數'),
            scoreMax: Yup.string().required('請輸入顯示結果之最大分數'),
          })
        )
        .min(1, '請輸入最少一個結果'),
  }),
});

export default schema;
