const seoKeyword = request => ({
  getSeoKeywordList: params =>
    request({
      method: 'get',
      endpoint: `/seo-keyword/list`,
      params: params,
    }),
});

export default seoKeyword;
