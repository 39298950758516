import withPage from '@hocs/with-page';
import { Route, Routes } from 'react-router-dom';
import ArticleListPage from './ArticleList';

const ArticleList = () => {
  const indexSubpages = ['treatment', 'care', 'discuss', 'hashtag'];
  return (
    <Routes>
      <Route index element={<ArticleListPage tab={indexSubpages[0]} />} />
      {indexSubpages.map(x => (
        <Route path={x} key={x} element={<ArticleListPage tab={x} />} />
      ))}
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '文章列表',
    pathname: '/article-list',
  },
};

export default withPage(page)(ArticleList);
