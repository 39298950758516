import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import { Radio, FormControlLabel, RadioGroup } from '@mui/material';

const FormRadioGroup = ({ options, value, formProps, hasError, ...props }) => {
  return (
    <RadioGroup value={value} {...props}>
      {options &&
        options.map(x => (
          <FormControlLabel
            key={x.value}
            label={x.label}
            value={x.value}
            control={<Radio color="primary" size="small" />}
          />
        ))}
    </RadioGroup>
  );
};

export default withFormControlWrapper(FormRadioGroup);
