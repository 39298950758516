import { animateScroll as scroll } from 'react-scroll';

const omitNullOrUndefined = obj => {
  const _obj = { ...obj };
  Object.keys(_obj).forEach(
    key => (_obj[key] === null || _obj[key] === undefined) && delete _obj[key]
  );
  return _obj;
};

const toSearchString = obj => {
  const _obj = { ...obj };
  Object.keys(_obj)
    .sort()
    .forEach(
      key =>
        (_obj[key] === null || _obj[key] === '' || _obj[key] === undefined) &&
        delete _obj[key]
    );
  return Object.keys(_obj)
    .map(key => `${key}=${_obj[key]}`)
    .join('&');
};

const formatNumber = number => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '0';
};

const getBracketParams = text => {
  const matches = text.match(/{(.*?)}/g);
  const result = [];
  if (matches) {
    for (let i = 0; i < matches.length; ++i) {
      const match = matches[i];
      result.push(match.substring(1, match.length - 1)); // brackets removing
    }
  }
  return result;
};

const getObjectPathValue = (obj, path) => {
  if (!obj) {
    return null;
  }
  const index = path.indexOf('.');
  if (index === -1) {
    return obj[path];
  }
  const key = path.slice(0, index);
  return getObjectPathValue(obj[key], path.slice(index + 1));
};

const isValidColorCode = code => {
  if (code.length !== 7) {
    return false;
  }
  const codeWithHashtag = code.replace(/#/g, '');
  return (
    typeof codeWithHashtag === 'string' &&
    codeWithHashtag.length === 6 &&
    !isNaN(Number('0x' + codeWithHashtag))
  );
};

const swapListItem = (list, fromIndex, toIndex) => {
  let _list = [...list];
  [_list[fromIndex], _list[toIndex]] = [_list[toIndex], _list[fromIndex]];
  return _list;
};

const moveListItem = (list, fromIndex, toIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
};

const replaceListItem = (list, index, item) => {
  const _list = [...list];
  _list[index] = item;
  return _list;
};

const deleteListItem = (list, index) => {
  const _list = [...list];
  _list.splice(index, 1);
  return _list;
};

const toggleListItem = (list, item) => {
  if (list.includes(item)) {
    return list.filter(x => x !== item);
  } else {
    return [...list, item];
  }
};

const binaryDataToOctet = binaryData => {
  const buf = new ArrayBuffer(binaryData.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < binaryData.length; i++) {
    view[i] = binaryData.charCodeAt(i) & 0xff; //convert to octet
  }
  return buf;
};

const numberWithCommas = number =>
  number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0';

const scrollToTop = delay => {
  scroll.scrollToTop({
    delay: delay || 0,
    duration: 0,
    smooth: false,
    isDynamic: true,
  });
};

const commonFormFields = page => ({
  seoTitle: page?.seoTitle || '',
  seoDescription: page?.seoDescription || '',
  seoKeywords: page?.seoKeywords || '',
  ogImage: page?.ogImage,
});

export {
  omitNullOrUndefined,
  toSearchString,
  formatNumber,
  getBracketParams,
  getObjectPathValue,
  isValidColorCode,
  swapListItem,
  moveListItem,
  replaceListItem,
  deleteListItem,
  toggleListItem,
  binaryDataToOctet,
  numberWithCommas,
  scrollToTop,
  commonFormFields,
};
