import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteListItem, swapListItem } from '@utilities/common';
import { Box, Grid, IconButton } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppTypography from '@components/_common/AppTypography';
import Portlet from '@components/layouts/Portlet';
import AppButton from '@components/_common/AppButton';
import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';

const LessonVideoModule = props => {
  const { data, setFieldValue, setSubFieldValue, editMode, onChange } = props;
  const handleClickAddChapter = () => {
    onChange &&
      onChange({
        ...data,
        chapters: [
          ...data.chapters,
          {
            title: '',
            items: '',
          },
        ],
      });
  };
  const handleSwap = (fromIndex, toIndex) => {
    const _data = data;
    _data.chapters = swapListItem(_data.chapters, fromIndex, toIndex);
    onChange && onChange(_data);
  };
  const handleDeleteChapter = index => {
    const _data = data;
    _data.chapters = deleteListItem(_data.chapters, index);
    onChange && onChange(_data);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputField
            label="YouTube影片ID"
            value={data.youtubeVideoId}
            onChange={e =>
              setFieldValue({
                youtubeVideoId: e.target.value,
              })
            }
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12}>
          <AppTypography mb={2} variant="h4" children="時段列表" />

          {data.chapters.length > 0 && (
            <Grid container spacing={2}>
              {data.chapters.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Portlet
                    titleComponent={
                      <Box flex={1}>
                        <Box display="flex">
                          <Box width={120} flexShrink={0}>
                            <FormInputField
                              placeholder="時段 (mm:ss)"
                              // textMask={createNumberMask({
                              //   includeThousandsSeparator: false,
                              //   prefix: '',
                              //   allowDecimal: true,
                              //   decimalSymbol: ':',
                              //   requireDecimal: true,
                              // })}
                              value={item.time}
                              onChange={e =>
                                setSubFieldValue(index, 'chapters', {
                                  time: e.target.value,
                                })
                              }
                              disabled={!editMode}
                            />
                          </Box>
                          <Box ml={1} flex={1}>
                            <FormInputField
                              placeholder="時段標題"
                              value={item.title}
                              onChange={e =>
                                setSubFieldValue(index, 'chapters', {
                                  title: e.target.value,
                                })
                              }
                              disabled={!editMode}
                            />
                          </Box>
                        </Box>
                      </Box>
                    }
                    headerRight={
                      editMode && (
                        <Box>
                          <IconButton
                            aria-label="move up"
                            onClick={() => handleSwap(index, index - 1)}
                            disabled={index === 0}
                          >
                            <KeyboardArrowUpIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="move down"
                            onClick={() => handleSwap(index, index + 1)}
                            disabled={index >= data.chapters.length - 1}
                          >
                            <KeyboardArrowDownIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteChapter(index)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      )
                    }
                    paddingContent
                    doubleShadow
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {editMode && (
            <AppButton
              mt={data.chapters.length > 0 ? 2 : 0}
              children="新增影片時段"
              onClick={handleClickAddChapter}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withModuleWrapper('影片')(LessonVideoModule);
