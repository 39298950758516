import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { moduleList } from '@components/module-page/config/module-list';
import { Box, Fab, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SelectModuleDialog from '@components/module-page/dialogs/SelectModuleDialog';
import AppButton from '@components/_common/AppButton';
import Portlet from '@components/layouts/Portlet';
import useAppService from '@hooks/use-app-service';

const ModulesContainer = styled(Box)(({ theme }) => ({
  '& > *:not(:first-of-type)': {
    marginTop: theme.spacing(2),
  },
}));

const ModuleRoot = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow:
    '0 2px 4px -1px rgba(0,0,0,.01), 0 4px 5px 0 rgba(0,0,0,.02), 0 1px 10px 0 rgba(0,0,0,.08)',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
}));

const ModulePage = ({
  mt,
  title,
  editButtonTitle,
  saveButtonTitle,
  successMessage,
  modulePage,
  availableTypes,
  editing,
  onChange,
  ...props
}) => {
  const { saveModulesPromise, onSaved } = props;
  const { enqueueSnackbar } = useSnackbar();
  const appService = useAppService();
  const [editMode, setEditMode] = useState(editing || false);
  const [modules, setModules] = useState(null);
  const [savingModules, setSavingModules] = useState(false);
  const [showTypeDialog, setShowTypeDialog] = React.useState(false);
  const addToIndex = useRef(0);

  useEffect(() => {
    setModules(
      modulePage?.modules
        ?.map(modulePageModule => {
          const module = moduleList
            .filter(x => availableTypes.includes(x.type))
            .find(x => x.type === modulePageModule.type);
          if (!!module) {
            return {
              ...modulePageModule[module.field],
              closeToPreviousModule: modulePageModule.closeToPreviousModule,
            };
          }
          return null;
        })
        .filter(x => !!x) || []
    );
  }, [availableTypes, modulePage]);

  const deleteModule = index => {
    const _modules = [...modules];
    _modules.splice(index, 1);
    setModules(_modules);
  };

  const handleClickEdit = () => {
    setEditMode(true);
  };
  const handleClickSaveModules = () => {
    setSavingModules(true);
    saveModulesPromise(modules).then(
      () => {
        setSavingModules(false);
        enqueueSnackbar(successMessage);
        setEditMode(false);
        onSaved && onSaved();
      },
      err => {
        setSavingModules(false);
        appService.handleError(err);
      }
    );
  };
  const handleClickAdd = index => {
    addToIndex.current = index;
    setShowTypeDialog(true);
  };
  const handleSelectModule = (type, _initialModuleBody) => {
    if (type && _initialModuleBody) {
      const initialModuleBody = JSON.parse(JSON.stringify(_initialModuleBody));
      const _modules = [...modules];
      _modules.splice(addToIndex.current, 0, {
        ...initialModuleBody,
        type,
      });
      setModules(_modules);
    }
    setShowTypeDialog(false);
  };
  const handleModuleSwap = (fromIndex, toIndex) => {
    let _modules = [...modules];
    [_modules[fromIndex], _modules[toIndex]] = [
      _modules[toIndex],
      _modules[fromIndex],
    ];
    setModules(_modules);
  };
  const handleModuleDataOnChange = (index, data) => {
    const _modules = [...modules];
    _modules[index] = data;
    setModules(_modules);

    if (onChange) {
      onChange(_modules);
    }
  };

  const renderActionButtons = () => {
    if (editing) {
      return <></>;
    }
    return !editMode ? (
      <AppButton children={editButtonTitle} onClick={handleClickEdit} />
    ) : (
      <AppButton
        children={saveButtonTitle}
        showLoading={savingModules}
        onClick={handleClickSaveModules}
      />
    );
  };
  const getModule = ({ module, index, sameTypeIndex, isFirst, isLast }) => {
    const ModuleClass =
      moduleList.find(x => x.type === module.type)?.moduleClass || null;
    return (
      ModuleClass && (
        <ModuleClass
          index={index}
          sameTypeIndex={sameTypeIndex}
          isFirst={isFirst}
          isLast={isLast}
          editMode={editMode}
          data={module}
          onMoveUp={() => handleModuleSwap(index, index - 1)}
          onMoveDown={() => handleModuleSwap(index, index + 1)}
          onChange={data => handleModuleDataOnChange(index, data)}
          onDelete={() => deleteModule(index)}
        />
      )
    );
  };
  const renderModules = () => {
    let sameTypeIndex = 0;
    let previousType = null;
    return modules.map((m, index) => {
      if (previousType === m.type) {
        sameTypeIndex += 1;
      } else {
        sameTypeIndex = 0;
      }
      previousType = m.type;
      return (
        <React.Fragment key={`${index}-${m.type}`}>
          {editMode && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Fab
                color="primary"
                size="small"
                aria-label="add"
                onClick={() => handleClickAdd(index)}
              >
                <AddIcon />
              </Fab>
            </Box>
          )}
          {getModule({
            module: m,
            index,
            sameTypeIndex,
            isFirst: index === 0,
            isLast: index === modules.length - 1,
          }) || <ModuleRoot children={m.type} />}
        </React.Fragment>
      );
    });
  };
  return (
    <>
      <Portlet
        mt={mt}
        title={title}
        headerRight={renderActionButtons()}
        headerSticky
        paddingContent
      >
        {(modules && modules.length > 0) || editMode ? (
          <ModulesContainer>
            {renderModules()}
            {editMode && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Fab
                  color="primary"
                  size="small"
                  aria-label="add"
                  onClick={() => handleClickAdd(modules.length)}
                >
                  <AddIcon />
                </Fab>
              </Box>
            )}
          </ModulesContainer>
        ) : null}
      </Portlet>

      {/* Start: Dialog */}
      <SelectModuleDialog
        open={showTypeDialog}
        availableTypes={availableTypes}
        onClose={handleSelectModule}
      />
      {/* End: Dialog */}
    </>
  );
};

export default ModulePage;
