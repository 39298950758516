import React, { useEffect, useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';
import useAppService from '@hooks/use-app-service';
import AppFormControlSelect from '@components/_common/AppFormControlSelect';

const LessonQuestionnaireModule = props => {
  const appService = useAppService();
  const { data, setFieldValue } = props;
  const [questionnaires, setQuestionnaires] = useState(null);

  useEffect(() => {
    if (!questionnaires) {
      appService.questionnaire
        .getAllQuestionnaireList()
        .then(setQuestionnaires);
    }
  }, [questionnaires, appService.questionnaire]);

  useEffect(() => {
    setQuestionnaires([]);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppFormControlSelect
          width={240}
          label="收費種類"
          value={data.questionnaireId || ''}
          onChange={v => setFieldValue({ questionnaireId: v })}
        >
          {questionnaires?.map(x => (
            <MenuItem key={x.id} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </AppFormControlSelect>
      </Grid>
    </Grid>
  );
};

export default withModuleWrapper('問卷')(LessonQuestionnaireModule);
