import useAppService from '@hooks/use-app-service';
import { Formik } from 'formik';
import schemaResetPassword from '@validations/reset-password.schema';
import { Box, styled } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';
import useAppNavigate from '@hooks/use-app-navigate';
import md5 from 'md5';

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: theme.spacing(5, 2.5),
  width: 400,
  borderRadius: theme.spacing(2),
  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 2px 12px 0px;',
}));

const ResetPasswordForm = ({ token, onSubmitSuccess }) => {
  const appService = useAppService();
  const navigate = useAppNavigate();
  return (
    <Formik
      initialValues={{
        newPassword: '',
        newPasswordConfirm: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        appService.authentication
          .resetPassword(token, md5(values.newPassword))
          .then(res => {
            onSubmitSuccess(res);
            navigate('/login?resetPassword=true');
          })
          .catch(err => {
            setSubmitting(false);
            appService.handleError(err);
          });
      }}
      validateOnMount={true}
      validationSchema={schemaResetPassword}
    >
      {formProps => {
        const { isSubmitting, handleSubmit, isValid } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <FormInputField
              id="newPassword"
              type="password"
              label="form.newPassword"
              formProps={formProps}
            />

            <FormInputField
              mt={2}
              id="newPasswordConfirm"
              type="password"
              label="form.newPasswordConfirm"
              formProps={formProps}
            />

            <AppButton
              mt={5}
              large
              type="submit"
              sx={{ mt: 5, width: 1 }}
              i="resetPassword.submitButtonTitle"
              onClick={handleSubmit}
              disabled={isValid && isSubmitting}
              showLoading={isValid && isSubmitting}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const ResetPassword = () => {
  const appService = useAppService();
  const location = useLocation();
  const token = queryString.parse(location.search).token;
  const [isTokenValid, setIsTokenValid] = useState(null);

  useEffect(() => {
    appService.authentication.checkResetPasswordToken(token).then(
      res => {
        setIsTokenValid(res);
      },
      () => {
        setIsTokenValid(false);
      }
    );
  }, [appService, token]);

  const handleSubmitSuccess = () => {};

  return (
    <>
      <Box component="main" py={15}>
        <AppTypography variant="h2" i="resetPassword.title" center />
        <AppTypography
          mt={1.5}
          variant="h5"
          color="text.secondary"
          i="resetPassword.body"
          center
        />
        <Root>
          {!isTokenValid ? (
            <AppLoadingSpinner />
          ) : (
            <ResetPasswordForm
              token={token}
              onSubmitSuccess={handleSubmitSuccess}
            />
          )}
        </Root>
      </Box>
    </>
  );
};

export default ResetPassword;
