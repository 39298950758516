import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { login } from '@redux/slices/app';

const useLogin = () => {
  const dispatch = useDispatch();
  return useCallback(
    (sessionToken, user) => {
      dispatch(login({ sessionToken, user }));
    },
    [dispatch],
  );
};

export default useLogin;
