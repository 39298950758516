const asset = request => ({
  uploadAsset: formData =>
    request({
      method: 'post',
      endpoint: `/asset`,
      data: formData,
      contentType: 'multipart/form-data',
    }),

  updateAssetDescription: (id, description) =>
    request({
      method: 'post',
      endpoint: `/asset/${id}/description`,
      data: { description },
    }),
});

export default asset;
