import BaseLayout from '@components/layouts/BaseLayout';
import articleCategories from '@configurations/article-categories';
import withFetchPage from '@hocs/with-fetch-page';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Formik } from 'formik';
import AppButton from '@components/_common/AppButton';
import { Box, Divider, Grid, IconButton, useTheme } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import FormTextareaField from '@components/forms/FormTextareaField';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import AppTypography from '@components/_common/AppTypography';
import FormSeoKeywords from '@components/forms/FormSeoKeywords';
import schemaArticleList from '@validations/article-list.schema';
import FabAdd from '@components/_common/FabAdd';
import FormSelectField from '@components/forms/FormSelectField';
import FormInputColorField from '@components/forms/FormInputColorField';
import { getObjectPathValue, swapListItem } from '@utilities/common';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';

const Highlights = ({ formProps, editMode }) => {
  const theme = useTheme();
  const {
    values,
    errors,
    submitCount,
    setValues,
    setFieldValue,
    handleChange,
    handleBlur,
  } = formProps;
  const key = 'highlights';
  const handleAddHighlight = () => {
    const defaultHighlight = {
      backgroundColor: '',
      image: null,
      badgeLabel: '',
      title: '',
      action: 'None',
      actionLink: '',
    };
    setValues({
      ...values,
      [key]: [...values[key], defaultHighlight],
    });
  };
  const handleMoveUp = index => () => {
    const newNavigations = swapListItem(values[key], index, index - 1);
    setValues({
      ...values,
      [key]: newNavigations,
    });
  };
  const handleMoveDown = index => () => {
    const newNavigations = swapListItem(values[key], index, index + 1);
    setValues({
      ...values,
      [key]: newNavigations,
    });
  };
  const handleDelete = index => () => {
    let newNavigations = [...values[key]];
    newNavigations.splice(index, 1);
    setValues({
      ...values,
      [key]: newNavigations,
    });
  };
  return (
    <>
      {values[key].map((highlight, index) => (
        <Box
          key={index}
          mb={2}
          px={2}
          py={2}
          display="flex"
          alignItems="flex-start"
          sx={{
            borderRadius: '6px',
            border: `1px solid ${theme.palette.divider}}`,
          }}
        >
          <Box flex={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormAssetUpload
                  image
                  withAssetDescription
                  label="圖片 (368 x 276)"
                  ratio={184 / 138}
                  asset={highlight.image}
                  onUploadedFile={asset =>
                    setFieldValue(`${key}.${index}.image`, asset)
                  }
                  errorMessage={
                    submitCount > 0 &&
                    errors[key] &&
                    errors[key][index] &&
                    errors[key][index].image
                  }
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormInputColorField
                      id={`${key}.${index}.backgroundColor`}
                      label="背景顏色"
                      hint=""
                      placeholder="如白色，輪入`#FFFFFF`"
                      value={getObjectPathValue(
                        values,
                        `${key}.${index}.backgroundColor`
                      )}
                      errorMessage={
                        submitCount > 0 &&
                        getObjectPathValue(
                          errors,
                          `${key}.${index}.backgroundColor`
                        )
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputField
                      id={`${key}.${index}.badgeLabel`}
                      label="徽章"
                      formProps={formProps}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextareaField
                      id={`${key}.${index}.title`}
                      label="標題"
                      rows={3}
                      formProps={formProps}
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormSelectField
                      id={`${key}.${index}.action`}
                      label="點搫處理"
                      options={[
                        {
                          label: '沒有',
                          value: 'None',
                        },
                        {
                          label: '開啟連結',
                          value: 'Link',
                        },
                        {
                          label: '乳癌計算機',
                          value: 'BreastCancerCalculator',
                        },
                        {
                          label: '乳癌治療計算機',
                          value: 'BreastCancerSurgeryCalculator',
                        },
                      ]}
                      formProps={formProps}
                      disabled={!editMode}
                    />
                  </Grid>
                  {values[key][index].action === 'Link' && (
                    <Grid item xs={12}>
                      <FormInputField
                        id={`${key}.${index}.actionLink`}
                        label="連結"
                        formProps={formProps}
                        disabled={!editMode}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {editMode && (
            <Box
              width={60}
              flexShrink={0}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <IconButton
                aria-label="move up"
                onClick={handleMoveUp(index)}
                disabled={index === 0}
              >
                <KeyboardArrowUpIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="move down"
                onClick={handleMoveDown(index)}
                disabled={index === values[key].length - 1}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
              <IconButton aria-label="delete" onClick={handleDelete(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}

      {editMode && <FabAdd text="新增焦點" onClick={handleAddHighlight} />}
    </>
  );
};

const ArticleListTab = ({ page, setPage, pageName, category }) => {
  const title = articleCategories.find(x => x.value === category)?.label;
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);
  const initialValues = useMemo(
    () => ({
      seoTitle: page?.seoTitle || '',
      seoDescription: page?.seoDescription || '',
      seoKeywords: page?.seoKeywords || '',
      keyVisualImage: page.keyVisualImage, // prettier-ignore
      keyVisualImageLink: page.keyVisualImageLink || '', // prettier-ignore
      keyVisualTitle: page.keyVisualTitle || '', // prettier-ignore
      keyVisualBody: page.keyVisualBody || '', // prettier-ignore
      highlights: page.highlights || [], // prettier-ignore
      footerImage: page.footerImage, // prettier-ignore
      footerTitle: page.footerTitle || '', // prettier-ignore
      footerButtonTitle: page.footerButtonTitle || '', // prettier-ignore
      footerButtonLink: page.footerButtonLink || '', // prettier-ignore
    }),
    [page]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        appService.page
          .updatePage({
            pagePath: pageName,
            body: values,
          })
          .then(res => {
            enqueueSnackbar(`頁面已更新`);
            setSubmitting(false);
            setPage(res);
            setEditMode(false);
          }, appService.handleError);
      }}
      validationSchema={schemaArticleList}
      enableReinitialize
    >
      {formProps => {
        const {
          values,
          errors,
          submitCount,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        } = formProps;
        return (
          <BaseLayout
            title={title}
            headerRight={
              <>
                {editMode ? (
                  <AppButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                  >
                    更新
                  </AppButton>
                ) : (
                  <AppButton onClick={() => setEditMode(true)}>編輯</AppButton>
                )}
              </>
            }
            noMb
          >
            <form onSubmit={handleSubmit}>
              <AppTypography mb={2} variant="h6">
                頂部橫額
              </AppTypography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <FormAssetUpload
                    image
                    withAssetDescription
                    label="圖片 (1600 x 760)"
                    ratio={800 / 380}
                    asset={values.keyVisualImage}
                    onUploadedFile={asset =>
                      setFieldValue('keyVisualImage', asset)
                    }
                    errorMessage={submitCount > 0 && errors.keyVisualImage}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormInputField
                    id="keyVisualTitle"
                    label="form.keyVisualTitle"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                  <FormTextareaField
                    mt={2}
                    id="keyVisualBody"
                    label="form.keyVisualBody"
                    rows={5}
                    formProps={formProps}
                    disabled={!editMode}
                  />
                  <FormInputField
                    mt={2}
                    id="keyVisualImageLink"
                    label="form.keyVisualImageLink"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h6">
                焦點
              </AppTypography>

              <Highlights formProps={formProps} editMode={editMode} />

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h6">
                底部橫額
              </AppTypography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <FormAssetUpload
                    image
                    withAssetDescription
                    label="圖片 (1600 x 660)"
                    ratio={800 / 330}
                    asset={values.footerImage}
                    onUploadedFile={asset =>
                      setFieldValue('footerImage', asset)
                    }
                    errorMessage={submitCount > 0 && errors.footerImage}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormTextareaField
                    mt={2}
                    id="footerTitle"
                    label="form.footerTitle"
                    rows={5}
                    formProps={formProps}
                    disabled={!editMode}
                  />
                  <FormInputField
                    mt={2}
                    id="footerButtonTitle"
                    label="form.footerButtonTitle"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                  <FormInputField
                    mt={2}
                    id="footerButtonLink"
                    label="form.footerButtonLink"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h4" children="SEO" />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormInputField
                    id="seoTitle"
                    label="form.seoTitle"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextareaField
                    id="seoDescription"
                    label="form.seoDescription"
                    formProps={formProps}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSeoKeywords
                    label={'SEO 關鍵字'}
                    seoKeywords={values.seoKeywords
                      .split(',')
                      .filter(x => !!x)
                      .map(x => x.trim())}
                    onChange={seoKeywords =>
                      setFieldValue('seoKeywords', seoKeywords.join(','))
                    }
                    disabled={!editMode}
                  />
                </Grid>
              </Grid>
            </form>
          </BaseLayout>
        );
      }}
    </Formik>
  );
};

export default withFetchPage()(ArticleListTab);
