const page = request => ({
  getPage: pagePath =>
    request({
      method: 'get',
      endpoint: `/page/${pagePath}`,
    }),

  updatePage: ({ pagePath, body }) =>
    request({
      method: 'put',
      endpoint: `/page/${pagePath}`,
      data: body,
    }),
});

export default page;
