import React from 'react';
import withModuleWrapper from '../hoc/with-module-wrapper';
import FormRichText from '../../forms/FormRichText';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import { Grid } from '@mui/material';
import FormInputColorField from '@components/forms/FormInputColorField';
import FormSelectField from '@components/forms/FormSelectField';
import FormInputField from '@components/forms/FormInputField';

const LessonBannerModule = props => {
  const { data, setFieldValue, editMode } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormSelectField
          id="position"
          label="位罝"
          options={[
            {
              label: '置中',
              value: 'Center',
            },
            {
              label: '靠左',
              value: 'Left',
            },
            {
              label: '靠右',
              value: 'Right',
            },
            {
              label: '左圖右字',
              value: 'RightCenter',
            },
          ]}
          value={data.position}
          formProps={{
            values: data,
            setFieldValue: (id, value) => {
              console.log('setFieldValue', id, value);
              setFieldValue({ [id]: value });
            },
          }}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormAssetUpload
          image
          freeFormCrop
          withAssetDescription
          label="背景圖片"
          ratio={800 / 330}
          asset={data.backgroundImage}
          onUploadedFile={asset => {
            setFieldValue({ backgroundImage: asset });
          }}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputColorField
          id="backgroundColor"
          label="背景顏色"
          value={data.backgroundColor}
          onChange={e => setFieldValue({ backgroundColor: e.target.value })}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormRichText
          label="標題"
          height={240}
          value={data.title}
          onChange={content => setFieldValue({ title: content })}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputField
          label="按鈕標題"
          value={data.buttonTitle}
          onChange={e =>
            setFieldValue({
              buttonTitle: e.target.value,
            })
          }
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputField
          label="按鈕連結"
          value={data.buttonLink}
          onChange={e =>
            setFieldValue({
              buttonLink: e.target.value,
            })
          }
          disabled={!editMode}
        />
      </Grid>
    </Grid>
  );
};

export default withModuleWrapper('横額 Banner')(LessonBannerModule);
