import BaseLayout from '@components/layouts/BaseLayout';
import { Box, Divider, Grid, styled } from '@mui/material';
import useFormUtilities from '@hooks/use-form-utilities';
import { getStatusChip } from '@constants/status';
import AppTypography from '@components/_common/AppTypography';

const Option = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0.75),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: 38,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const Summary = ({ member }) => {
  const { renderLabelChildren, renderLabelValue } = useFormUtilities();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderLabelChildren({
          label: '狀態',
          children: getStatusChip('User', member.status, { mt: -0.5 }),
        })}
      </Grid>
      <Grid item xs={12}>
        {renderLabelValue({ label: '花名', value: member.nickname })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {renderLabelValue({ label: '電話號碼', value: member.mobileNumber })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {renderLabelValue({ label: '電郵地址', value: member.email })}
      </Grid>
    </Grid>
  );
};

const SelectedOptions = ({ member }) => {
  const sections = [
    {
      key: 'target',
      title: '照顧對象',
    },
    {
      key: 'situation',
      title: '照顧情況',
    },
    {
      key: 'intoArea',
      title: '想了解的範疇',
    },
    {
      key: 'intoIllness',
      title: '想了解的病症',
    },
  ];
  return (
    <>
      <AppTypography variant="subtitle1">會員問卷選項</AppTypography>

      <Box mt={2}>
        <Grid container spacing={2}>
          {sections.map(x => (
            <Grid key={x.key} item xs={12}>
              <AppTypography>{x.title}</AppTypography>
              <Box
                mt={0.5}
                mx={-0.75}
                mb={-0.75}
                display="flex"
                flexWrap="wrap"
              >
                {member[`${x.key}Options`]?.selectOptionList
                  ?.filter(y => y.status === 'Active')
                  ?.map(y => (
                    <Option key={y.id}>
                      <AppTypography variant="subtitle1">
                        {y.name}
                      </AppTypography>
                    </Option>
                  ))}
                {member[`${x.key}Others`]
                  ?.split(',')
                  .filter(y => !!y)
                  ?.map(y => (
                    <Option key={y}>
                      <AppTypography variant="subtitle1">{y}</AppTypography>
                    </Option>
                  ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const MemberDetailsSummary = props => {
  return (
    <BaseLayout>
      <Summary {...props} />
      <Divider sx={{ my: 2 }} />
      <SelectedOptions {...props} />
    </BaseLayout>
  );
};

export default MemberDetailsSummary;
