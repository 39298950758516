import LessonTextModule from '../modules/LessonTextModule';
import LessonImageTextModule from '../modules/LessonImageTextModule';
import LessonImageTextBackgroundModule from '../modules/LessonImageTextBackgroundModule';
import LessonQuoteModule from '../modules/LessonQuoteModule';
import LessonShowcaseModule from '../modules/LessonShowcaseModule';
import LessonPointModule from '../modules/LessonPointModule';
import LessonTabModule from '../modules/LessonTabModule';
import LessonTableModule from '../modules/LessonTableModule';
import LessonAccordionModule from '../modules/LessonAccordionModule';
import LessonQuestionnaireModule from '../modules/LessonQuestionnaireModule';
import LessonChecklistModule from '../modules/LessonChecklistModule';
import LessonZoomableImageModule from '../modules/LessonZoomableImageModule';
import LessonVideoModule from '../modules/LessonVideoModule';
import LessonBannerModule from '@components/module-page/modules/LessonBannerModule';

export const moduleList = [
  {
    name: '標題及內容',
    type: 'text',
    field: 'text',
    moduleClass: LessonTextModule,
    initialModuleBody: {
      image: null,
      title: '',
      subtitle: '',
      body: '',
    },
  },
  {
    name: '左圖右字',
    type: 'image-text',
    field: 'imageText',
    moduleClass: LessonImageTextModule,
    initialModuleBody: {
      image: null,
      title: '',
      body: '',
    },
  },
  {
    name: '左字右圖 (有背景顏色)',
    type: 'image-text-background',
    field: 'imageTextBackground',
    moduleClass: LessonImageTextBackgroundModule,
    initialModuleBody: {
      backgroundColor: '',
      image: null,
      title: '',
      body: '',
    },
  },
  {
    name: '引用',
    type: 'quote',
    field: 'quote',
    moduleClass: LessonQuoteModule,
    initialModuleBody: {
      quote: '',
      author: '',
    },
  },
  {
    name: 'Showcase A',
    type: 'showcase-style-a',
    field: 'showcaseStyleA',
    moduleClass: LessonShowcaseModule,
    initialModuleBody: {
      style: 'StyleA',
      items: [
        {
          image: {
            id: null,
            url: null,
          },
          title: '',
        },
      ],
    },
  },
  {
    name: 'Showcase B',
    type: 'showcase-style-b',
    field: 'showcaseStyleB',
    moduleClass: LessonShowcaseModule,
    initialModuleBody: {
      style: 'StyleB',
      title: '',
      items: [
        {
          image: {
            id: null,
            url: null,
          },
          title: '',
        },
      ],
    },
  },
  {
    name: 'Showcase C',
    type: 'showcase-style-c',
    field: 'showcaseStyleC',
    moduleClass: LessonShowcaseModule,
    initialModuleBody: {
      style: 'StyleC',
      title: '',
      items: [
        {
          image: {
            id: null,
            url: null,
          },
          title: '',
        },
      ],
    },
  },
  {
    name: 'Point',
    type: 'point',
    field: 'point',
    moduleClass: LessonPointModule,
    initialModuleBody: {
      style: 'StylePoint',
      title: '',
      body: '',
      image: null,
      remarks: '',
      items: [
        {
          body: '',
        },
      ],
    },
  },
  {
    name: '分頁',
    type: 'tab',
    field: 'tab',
    moduleClass: LessonTabModule,
    initialModuleBody: {
      title: '',
      body: '',
      items: [
        {
          title: '',
          body: '',
        },
      ],
    },
  },
  {
    name: 'Step',
    type: 'step',
    field: 'step',
    moduleClass: LessonPointModule,
    initialModuleBody: {
      style: 'StyleStep',
      title: '',
      body: '',
      image: null,
      remarks: '',
      items: [
        {
          body: '',
        },
      ],
    },
  },
  {
    name: '表格',
    type: 'table',
    field: 'table',
    moduleClass: LessonTableModule,
    initialModuleBody: {
      table: '',
    },
  },
  {
    name: '折叠內容',
    type: 'accordion',
    field: 'accordion',
    moduleClass: LessonAccordionModule,
    initialModuleBody: {
      forceExpand: false,
      title: '',
      items: [
        {
          title: '',
          body: '',
        },
      ],
    },
  },
  {
    name: '問卷',
    type: 'questionnaire',
    field: 'questionnaire',
    moduleClass: LessonQuestionnaireModule,
    initialModuleBody: {
      questionnaireId: null,
    },
  },
  {
    name: '清單',
    type: 'checklist',
    field: 'checklist',
    moduleClass: LessonChecklistModule,
    initialModuleBody: {
      title: '',
      body: '',
      groups: [
        {
          title: '',
          items: '',
        },
      ],
    },
  },
  {
    name: '圖片 (放大縮小)',
    type: 'zoomable-image',
    field: 'zoomableImage',
    moduleClass: LessonZoomableImageModule,
    initialModuleBody: {
      image: null,
      imageLarge: null,
    },
  },
  {
    name: '影片',
    type: 'video',
    field: 'video',
    moduleClass: LessonVideoModule,
    initialModuleBody: {
      youtubeVideoId: '',
      chapters: [
        {
          time: '',
          title: '',
        },
      ],
    },
  },
  {
    name: '橫額',
    type: 'banner',
    field: 'banner',
    moduleClass: LessonBannerModule,
    initialModuleBody: {
      position: 'Center',
      backgroundImage: null,
      backgroundColor: '',
      title: '',
      buttonTitle: '',
      buttonLink: '',
    },
  },
];
