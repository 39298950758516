import BaseLayout from '@components/layouts/BaseLayout';
import { Formik } from 'formik';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import useAppNavigate from '@hooks/use-app-navigate';
import schemaUser from '@validations/user.schema';
import { Box, Grid } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import FormTextareaField from '@components/forms/FormTextareaField';
import AppButton from '@components/_common/AppButton';
import FormSelectField from '@components/forms/FormSelectField';
import { useEffect, useState } from 'react';
import withPage from '@hocs/with-page';

const UserForm = ({ user, setUser }) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useAppNavigate();
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    appService.cmsUser
      .getUserRoleList()
      .then(setRoles)
      .catch(appService.handleError);
  }, [appService]);

  return (
    <BaseLayout>
      <Formik
        initialValues={{
          role: user?.role?.slug || '',
          permissions: [],
          name: user?.name || '',
          email: user?.email || '',
          remarks: user?.remarks || '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          const submit$ = user
            ? appService.cmsUser.updateUser(user.id, values)
            : appService.cmsUser.createUser(values);
          submit$
            .then(res => {
              let successMessage = `用戶\`${values.name}\`已新增`;
              if (user) {
                successMessage = `用戶\`${values.name}\`已更新`;
                setUser(res);
              }
              enqueueSnackbar(successMessage);
              navigate(`/cms-user/${res.id}`);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
        validationSchema={schemaUser}
      >
        {formProps => {
          const { handleSubmit, isSubmitting } = formProps;
          return (
            roles && (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormSelectField
                      id="role"
                      width={240}
                      label="form.userRole"
                      options={roles.map(x => ({
                        label: x.name,
                        value: x.slug,
                      }))}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormInputField
                      id="name"
                      label="form.userName"
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormInputField
                      id="email"
                      label="form.email"
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextareaField
                      id="remarks"
                      label="form.remarks"
                      rows={5}
                      formProps={formProps}
                    />
                  </Grid>
                </Grid>

                <Box mt={2} display="flex" justifyContent="flex-end">
                  <AppButton
                    type="submit"
                    i={user ? 'user.update' : 'user.create'}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                  />
                </Box>
              </form>
            )
          );
        }}
      </Formik>
    </BaseLayout>
  );
};

const UserFormVariants = {
  Create: withPage({
    level: 1,
    route: {
      title: '新增用戶',
      pathname: '/cms-user/create',
    },
  })(UserForm),
  Edit: withPage({
    level: 2,
    route: {
      title: '編輯用戶',
      pathname: `/cms-user/:id/edit`,
    },
  })(UserForm),
};

export default UserFormVariants;
