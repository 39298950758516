import { createTheme } from '@mui/material';

const getTheme = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1048,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      common: {
        purple: '#CF7DF0',
        orange: '#FAC346',
        skin: '#EBE6E3',
        grey: '#E0E0E0',
        greyLight: '#F5F5F5',
      },
      primary: {
        main: '#CF7DF0',
        light: '#e3b3f5',
        dark: '#b745e4',
      },
      error: {
        light: '#e57373',
        main: '#f67280',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
      warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      info: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: '#fff',
      },
      success: {
        light: '#81c784',
        main: '#34bfa3',
        dark: '#388e3c',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        // primary: '#484440',
        secondary: '#8091a5',
      },
      background: {
        default: '#ffffff',
      },
    },
    shape: {
      borderRadius: 6,
    },
    typography: {
      fontSize: 16,
      fontFamily: "'Noto Sans TC', 'Source Sans 3', sans-serif",
      h1: {
        fontSize: 32,
        fontWeight: 700,
      },
      h2: {
        fontSize: 28,
        fontWeight: 700,
      },
      h3: {
        fontSize: 22,
        fontWeight: 700,
      },
      h4: {
        fontSize: 20,
        fontWeight: 700,
      },
      h5: {
        fontSize: 18,
        fontWeight: 600,
      },
      h6: {
        fontSize: 16,
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.2,
      },
      body1: {
        fontSize: 14,
        fontWeight: 400,
      },
      button: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
  });

  theme.components = {
    ...theme.components,

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          '.MuiSelect-select': {
            transition: 'background-color .2s',
            '&[aria-expanded="true"]': {
              backgroundColor: '#F8F8F8',
            },
          },
          '&:hover': {
            '.MuiSelect-select': {
              backgroundColor: '#F8F8F8',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          },
          '&:active': {
            backgroundColor: '#F8F8F8',
          },
        },
        notchedOutline: {
          transition: 'border-color .2s',
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          minWidth: '0 !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 14,
          height: 20,
          minHeight: 20,
          paddingTop: 11,
          paddingBottom: 11,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-focusVisible': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: 'white',
          fontWeight: 600,
        },
        label: {
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 1,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'white',
          },
        },
      },
    },
  };

  return theme;
};

const theme = getTheme();

export default theme;
