import AppDatatable from '@components/_common/AppDatatable';
import useAppService from '@hooks/use-app-service';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Chip, MenuItem } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import { useEffect, useState } from 'react';
import { useDebounce } from '@hooks/use-debounce';
import AppButton from '@components/_common/AppButton';
import BaseLayout from '@components/layouts/BaseLayout';
import { getStatusChip, getStatusList } from '@constants/status';
import AppFormControlSelect from '@components/_common/AppFormControlSelect';
import { format, parseISO } from 'date-fns';
import articleCategories from '@configurations/article-categories';

const ArticleList = () => {
  const appService = useAppService();
  const [category, setCategory] = useState('All');
  const [status, setStatus] = useState('All');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [filter, setFilter] = useState({
    category,
    status,
    search,
  });
  const pageSize = 20;

  useEffect(() => {
    if (
      filter.category !== category ||
      filter.status !== status ||
      filter.search !== debouncedSearch
    ) {
      setFilter({
        category,
        status,
        search: debouncedSearch,
      });
    }
  }, [
    category,
    status,
    debouncedSearch,
    filter.category,
    filter.status,
    filter.search,
  ]);

  const renderHeaderLeft = () => (
    <>
      <AppFormControlSelect
        width={120}
        label="類別"
        value={category}
        onChange={setCategory}
      >
        <MenuItem value="All">全部</MenuItem>
        {articleCategories.map(x => (
          <MenuItem key={x.value} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </AppFormControlSelect>
      <AppFormControlSelect
        width={120}
        label="狀態"
        value={status}
        onChange={setStatus}
      >
        <MenuItem value="All">全部</MenuItem>
        {getStatusList('Article').map(x => (
          <MenuItem key={x.status} value={x.status}>
            {x.label}
          </MenuItem>
        ))}
      </AppFormControlSelect>
      <FormInputField
        width={320}
        placeholder={'搜尋文章'}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
    </>
  );

  const renderHeaderRight = () => (
    <AppButton to="/article/create">新增文章</AppButton>
  );

  return (
    <BaseLayout
      headerLeft={renderHeaderLeft()}
      headerRight={renderHeaderRight()}
    >
      <AppDatatable
        negativeMx
        syncQueryParams
        defaultSortFieldId="publishDate"
        defaultSortAsc={false}
        columns={[
          {
            name: '類別',
            selector: row => row.category,
            width: '100px',
            sortable: true,
            sortField: 'category',
            cell: row => {
              return articleCategories.find(x => x.value === row.category)
                ?.label;
            },
          },
          {
            name: '標題',
            selector: row => row.title,
            sortable: true,
            sortField: 'title',
          },
          {
            name: '觀看次數',
            selector: row => row.viewCount,
            sortable: true,
            sortField: 'viewCount',
            width: '120px',
          },
          {
            name: '發佈日期',
            selector: row => row.publishDate,
            width: '180px',
            sortable: true,
            sortField: 'publishDate',
            cell: row => {
              return row.publishDate
                ? format(parseISO(row.publishDate), 'yyyy-MM-dd HH:mm:ss')
                : '沒有';
            },
          },
          {
            name: '下架日期',
            selector: row => row.expiryDate,
            width: '180px',
            sortable: true,
            sortField: 'expiryDate',
            cell: row => {
              return row.expiryDate
                ? format(parseISO(row.expiryDate), 'yyyy-MM-dd HH:mm:ss')
                : '沒有';
            },
          },
          {
            name: '於列表中隱藏?',
            selector: row => row.hideFromArticleList,
            width: '160px',
            sortable: true,
            sortField: 'hideFromArticleList',
            cell: row => {
              return row.hideFromArticleList ? '隱藏' : '不隱藏';
            },
          },
          {
            name: '狀態',
            width: '140px',
            selector: row => row.status,
            sortable: true,
            sortField: 'status',
            cell: row => {
              if (row.status === 'Published') {
                if (
                  row.expiryDate != null &&
                  parseISO(row.expiryDate).getTime() < new Date().getTime()
                ) {
                  return <Chip size="small" color="warning" label="已下架" />;
                }
                if (
                  parseISO(row.publishDate).getTime() > new Date().getTime()
                ) {
                  return <Chip size="small" color="warning" label="待發佈" />;
                }
                return <Chip size="small" color="success" label="已發佈" />;
              }
              return getStatusChip('Article', row.status);
            },
          },
          {
            id: 'action',
            name: '',
            width: '70px',
            right: true,
            cell: row => (
              <TooltipIconButton
                title="前往"
                color="primary"
                Icon={NavigateNextIcon}
                to={`/article/${row.id}`}
              />
            ),
          },
        ]}
        pageSize={pageSize}
        onLayout$={appService.article.getArticleList}
        otherParams={filter}
      />
    </BaseLayout>
  );
};

export default ArticleList;
