import React, { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteListItem, swapListItem } from '@utilities/common';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import FormRichText from '@components/forms/FormRichText';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import withModuleWrapper from '@components/module-page/hoc/with-module-wrapper';
import AppButton from '@components/_common/AppButton';

const LessonShowcaseModule = props => {
  const {
    data,
    setTitle,
    setFieldValue,
    setSubFieldValue,
    editMode,
    onChange,
  } = props;
  const [indexToBeDeleted, setIndexToBeDeleted] = useState(null);

  useEffect(() => {
    if (data.style === 'StyleA') {
      setTitle('Showcase A');
    } else if (data.style === 'StyleB') {
      setTitle('Showcase B');
    } else if (data.style === 'StyleC') {
      setTitle('Showcase C');
    }
  }, [data.style, setTitle]);

  const handleClickAddItem = () => {
    onChange &&
      onChange({
        ...data,
        items: [
          ...data.items,
          {
            image: {
              id: null,
              url: null,
            },
            title: '',
          },
        ],
      });
  };
  const handleSwap = (fromIndex, toIndex) => {
    const _data = data;
    _data.items = swapListItem(_data.items, fromIndex, toIndex);
    onChange && onChange(_data);
  };
  const handleDeleteItem = () => {
    const _data = data;
    _data.items = deleteListItem(_data.items, indexToBeDeleted);
    onChange && onChange(_data);
    setIndexToBeDeleted(null);
  };
  return (
    <>
      <Grid container spacing={2}>
        {data.style !== 'StyleA' && (
          <Grid item xs={12}>
            <FormInputField
              label="標題"
              value={data.title}
              onChange={e =>
                setFieldValue({
                  title: e.target.value,
                })
              }
              disabled={!editMode}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {data.items.map((item, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Divider sx={{ my: 2 }} />}

              <Box display="flex">
                <Box flex={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={data.style === 'StyleC' ? 6 : 4}>
                      <FormAssetUpload
                        image
                        freeFormCrop
                        withAssetDescription
                        label="圖片"
                        ratio={data.style === 'StyleC' ? 16 / 9 : 1}
                        asset={item.image}
                        onUploadedFile={asset => {
                          setSubFieldValue(index, 'items', { image: asset });
                        }}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={data.style === 'StyleC' ? 6 : 8}>
                      <Grid container spacing={2}>
                        {data.style !== 'StyleC' && (
                          <Grid item xs={12}>
                            <FormRichText
                              label="標題"
                              height={200}
                              value={item.title}
                              onChange={content =>
                                setSubFieldValue(index, 'items', {
                                  title: content,
                                })
                              }
                              disabled={!editMode}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <FormRichText
                            label="內容"
                            height={350}
                            value={item.body}
                            onChange={content =>
                              setSubFieldValue(index, 'items', {
                                body: content,
                              })
                            }
                            disabled={!editMode}
                          />
                        </Grid>
                        {data.style === 'StyleB' && (
                          <>
                            <Grid item xs={12}>
                              <FormInputField
                                label="網址名稱"
                                value={item.buttonTitle}
                                onChange={e =>
                                  setSubFieldValue(index, 'items', {
                                    buttonTitle: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputField
                                label="網址"
                                value={item.buttonLink}
                                onChange={e =>
                                  setSubFieldValue(index, 'items', {
                                    buttonLink: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {editMode && (
                  <Box ml={2} display="flex" flexDirection="column">
                    <IconButton
                      aria-label="move up"
                      onClick={() => handleSwap(index, index - 1)}
                      disabled={index === 0}
                    >
                      <KeyboardArrowUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="move down"
                      onClick={() => handleSwap(index, index + 1)}
                      disabled={index >= data.items.length - 1}
                    >
                      <KeyboardArrowDownIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setIndexToBeDeleted(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </React.Fragment>
          ))}

          {editMode && (
            <AppButton
              mt={data.items.length > 0 ? 2 : 0}
              children="新增 Showcase Item"
              onClick={handleClickAddItem}
            />
          )}
        </Grid>
      </Grid>

      <ConfirmDialog
        open={indexToBeDeleted != null}
        title={'確認移除Showcase Item?'}
        onClose={() => setIndexToBeDeleted(null)}
        onConfirm={handleDeleteItem}
      />
    </>
  );
};

export default withModuleWrapper('Showcase')(LessonShowcaseModule);
