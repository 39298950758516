import axios from 'axios';
import { omitNullOrUndefined } from '@utilities/common';
import ApiError from '@services/ApiError';
import authentication from '@services/app-service/services/authentication';
import cmsUser from '@services/app-service/services/cms-user';
import charge from '@services/app-service/services/charge';
import article from '@services/app-service/services/article';
import asset from '@services/app-service/services/asset';
import hashtag from '@services/app-service/services/hashtag';
import seoKeyword from '@services/app-service/services/seo-keyword';
import page from '@services/app-service/services/page';
import questionnaire from '@services/app-service/services/questionnaire';
import calculator from '@services/app-service/services/calculator';
import selectOption from '@services/app-service/services/select-option';
import user from '@services/app-service/services/user';

export class AppService {
  constructor(url) {
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 60000,
      withCredentials: true,
    });
  }

  setSessionToken = sessionToken => {
    if (sessionToken) {
      this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`; // prettier-ignore
      this.axiosInstance.defaults.headers.common['X-Authorization'] = `Bearer ${sessionToken}`; // prettier-ignore
    } else {
      delete this.axiosInstance.defaults.headers.common['Authorization'];
      delete this.axiosInstance.defaults.headers.common['X-Authorization'];
    }
  };

  setLanguage = language => {
    if (language) {
      this.axiosInstance.defaults.headers.common['Accept-Language'] = language; // prettier-ignore
    } else {
      delete this.axiosInstance.defaults.headers.common['Accept-Language'];
    }
  };

  setSetErrorMessageDispatch = dispatch => {
    this.setErrorMessage = dispatch;
  };

  setLogoutDispatch = dispatch => {
    this.logout = dispatch;
  };

  request = ({ method, endpoint, params, data, contentType }) => {
    const paramsOrData = omitNullOrUndefined({ params, data });
    if (!Object.keys(paramsOrData).length) {
      console.log(`[App Service] <<<< - ${endpoint}`);
    } else {
      console.log(`[App Service] <<<< - ${endpoint}`, paramsOrData);
    }

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request({
          method,
          url: endpoint,
          params: params,
          data: data,
          headers: {
            'Content-Type': contentType || 'application/json',
          },
        })
        .then(response => {
          console.log(`[App Service] >>>> - ${endpoint}`, response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          const httpStatus = error.response?.status;
          const errorClass = error.response?.data?.status.errorClass;
          let message = error.response?.data?.status.message; // prettier-ignore
          if (errorClass) {
            message = `errorApi.${errorClass}`;
          }
          if (!message) {
            message = `${httpStatus} - ${error.response?.data?.error}`;
          }
          const errorResponse = {
            endpoint,
            httpStatus,
            errorClass,
            message,
            axiosError: error,
          };

          console.error(`[App Service] >>>> - ${endpoint}`, errorResponse);

          if (httpStatus === 401 && endpoint !== '/user/login') {
            this.setErrorMessage(message);
            this.logout();
          }
          reject(new ApiError(errorResponse));
        });
    });
  };

  handleError = e => {
    const axiosResponse = e.response;
    this.setErrorMessage(axiosResponse.message);
  };

  authentication = authentication(this.request);
  cmsUser = cmsUser(this.request);
  charge = charge(this.request);
  article = article(this.request);
  asset = asset(this.request);
  hashtag = hashtag(this.request);
  seoKeyword = seoKeyword(this.request);
  page = page(this.request);
  questionnaire = questionnaire(this.request);
  calculator = calculator(this.request);
  selectOption = selectOption(this.request);
  user = user(this.request);
}

const appService = new AppService(process.env.REACT_APP_API_URL);

export default appService;
