const hashtag = request => ({
  getHashtagList: (type, params) =>
    request({
      method: 'get',
      endpoint: `/hashtag/${type}/list`,
      params: params,
    }),
});

export default hashtag;
