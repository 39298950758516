import React, { createContext } from 'react';

const AppServiceContext = createContext(null);

const AppServiceProvider = ({ appService, children }) => {
  return (
    <AppServiceContext.Provider value={appService}>
      {children}
    </AppServiceContext.Provider>
  );
};

export { AppServiceContext, AppServiceProvider };
