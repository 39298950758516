import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from '@mui/material';
import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormSelectField = ({
  id,
  options,
  formProps,
  width = 1,
  value,
  disabled,
}) => {
  const { isSubmitting, setFieldValue } = formProps || {};
  const theme = useTheme();
  return (
    <FormControl sx={{ width }}>
      <Select
        id={id}
        displayEmpty
        value={value}
        onChange={e => setFieldValue(id, e.target.value)}
        input={<OutlinedInput />}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              mt: 0.75,
            },
            '.MuiList-root': {
              py: 0,
              borderRadius: '6px',
            },
            '.MuiMenuItem-root': {
              py: 1.25,
              '&:hover': {
                backgroundColor: `rgba(207, 125, 240, 0.2) !important`,
              },
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: theme.palette.common.white,
              },
            },
          },
        }}
        disabled={disabled || isSubmitting}
        sx={{ height: 42, py: 0 }}
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            children={option.label}
          />
        ))}
      </Select>
    </FormControl>
  );
};

export default withFormControlWrapper(FormSelectField);
