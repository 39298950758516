const user = request => ({
  getUserList: params =>
    request({
      method: 'get',
      endpoint: `/user/list`,
      params: params,
    }),

  getUser: id =>
    request({
      method: 'get',
      endpoint: `/user/${id}`,
    }),
});

export default user;
