import '@styles/global.scss';
import '@styles/reset.scss';
import '@styles/reset-button.scss';

import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { LocalizationProvider } from '@contexts/localization';
import { AppServiceProvider } from '@contexts/app-service';
import { Provider as ReduxProvider } from 'react-redux';
import ModalProvider from 'mui-modal-provider';
import theme from '@theme/index';
import localizedStrings from '@i18n/index';
import store from '@redux/store';
import appService from '@services/app-service';
import languages from '@configurations/languages';
import { useEffect } from 'react';
import { scrollToTop } from '@utilities/common';
import useI18n from '@hooks/use-i18n';
import useAppService from '@hooks/use-app-service';
import useUser from '@hooks/redux/use-user';
import useSessionToken from '@hooks/redux/use-session-token';
import Login from '@pages/authentication/Login';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';
import routes from '@configurations/routes';
import MainLayout from '@components/layouts/MainLayout';
import Profile from '@pages/profile/Profile';
import useErrorMessage from '@hooks/redux/use-error-message';
import OkDialog from '@components/dialogs/OkDialog';
import useLogout from '@hooks/redux/use-logout';
import { LocalizationProvider as DatePickersLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ForgotPassword from '@pages/authentication/ForgotPassword';
import ResetPassword from '@pages/authentication/ResetPassword';

const wrap = Component => props => {
  return (
    <AppServiceProvider appService={appService}>
      <ReduxProvider store={store}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DatePickersLocalizationProvider dateAdapter={AdapterDateFns}>
              <BrowserRouter
                basename={process.env.REACT_APP_REACT_ROUTER_BASENAME}
              >
                <ModalProvider>
                  <Routes>
                    <Route path=":lang/*" element={<Component {...props} />} />
                    <Route index element={<Component {...props} />} />
                  </Routes>
                </ModalProvider>
              </BrowserRouter>
            </DatePickersLocalizationProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </ReduxProvider>
    </AppServiceProvider>
  );
};

const localized = Component => props => {
  let { lang } = useParams();
  const languageCodes = languages.map(x => x.code);
  return (
    <LocalizationProvider
      language={languageCodes.includes(lang) ? lang : languageCodes[0]}
      localizedStrings={localizedStrings}
    >
      <Component {...props} />
    </LocalizationProvider>
  );
};

const App = () => {
  const { language } = useI18n();
  const { value: user, setValue: setUser } = useUser();
  const { value: sessionToken, setValue: setSessionToken } = useSessionToken();
  const logout = useLogout();
  const { value: errorMessage, setValue: setErrorMessage } = useErrorMessage();
  const location = useLocation();
  const appService = useAppService();

  // Set app service required parameters
  appService.setLanguage(language);
  appService.setSessionToken(sessionToken);
  appService.setSetErrorMessageDispatch(setErrorMessage);
  appService.setLogoutDispatch(logout);

  // Get logged-in user if session token is not null while user is null
  useEffect(() => {
    if (sessionToken && !user) {
      appService.authentication
        .getLoggedInUser()
        .then(user => {
          setUser(user);
        })
        .catch(() => {
          setSessionToken(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to top on location change
  useEffect(() => {
    scrollToTop();
  }, [location]);

  // Auto add language prefix in the front of the url if no language prefix detected
  const pathnameParts = location.pathname.split('/').filter(x => !!x);
  const languageCodes = languages.map(x => x.code);
  if (pathnameParts.length && languageCodes.indexOf(pathnameParts[0]) === -1) {
    const joinedParts = pathnameParts.join('/');
    let navigateToPathname = `/${language}/${joinedParts}${location.search}`;
    return <Navigate to={navigateToPathname} />;
  }

  if (sessionToken && !user) {
    return <AppLoadingSpinner mt={5} />;
  }

  const authenticated = sessionToken && user;
  return (
    <>
      {!authenticated ? (
        <Routes>
          <Route path="login" Component={Login} />
          <Route path="forgot-password" Component={ForgotPassword} />
          <Route path="reset-password" Component={ResetPassword} />
          <Route path="*" element={<Navigate to={`/${language}/login`} />} />
        </Routes>
      ) : (
        <MainLayout>
          <Routes>
            {routes.map(route => {
              if (route.routes) {
                return route.routes.map(subRoute => {
                  return (
                    <Route
                      key={subRoute.title}
                      path={subRoute.path}
                      Component={subRoute.Component}
                    />
                  );
                });
              } else {
                return (
                  <Route
                    key={route.title}
                    path={route.path}
                    Component={route.Component}
                  />
                );
              }
            })}
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<Navigate to={`/${language}/home`} />} />
          </Routes>
        </MainLayout>
      )}

      <OkDialog
        open={!!errorMessage}
        title="錯誤"
        description={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default wrap(localized(App));
