import TabLayout from '@components/layouts/TabLayout';
import ChargeDetailsSummary from '@pages/charge/details/_subs/ChargeDetailsSummary';
import ChargeDetailsChangeLogs from '@pages/charge/details/_subs/ChargeDetailsChangeLogs';
import { Button } from '@mui/material';
import AppLink from '@components/_common/AppLink';
import ConfirmButton from '@components/_common/ConfirmButton';
import useAppService from '@hooks/use-app-service';
import useAppNavigate from '@hooks/use-app-navigate';

const ChargeDetails = props => {
  const appService = useAppService();
  const navigate = useAppNavigate();
  const { tab, charge, setCharge } = props;
  const tabs = [
    {
      title: '概要',
      key: 'summary',
      url: `/charge/${charge.id}/summary`,
      component: () => {
        return <ChargeDetailsSummary {...props} />;
      },
    },
    {
      title: '更新日誌',
      key: 'change-logs',
      url: `/charge/${charge.id}/change-logs`,
      component: () => {
        return <ChargeDetailsChangeLogs {...props} />;
      },
    },
  ];
  return (
    <TabLayout
      initialValue={tabs.map(x => x.key).indexOf(tab)}
      rightButtons={
        <>
          <AppLink to={`/charge/${charge.id}/edit`}>
            <Button sx={{ px: 2, height: 1, borderRadius: 0 }}>編輯收費</Button>
          </AppLink>
          {charge.status === 'Active' && (
            <div>
              <ConfirmButton
                color="warning"
                title="隱藏"
                dialogTitle="確定要隱藏收費？"
                successMessage="收費已隱藏"
                action$={() =>
                  appService.charge.updateChargeStatus(charge.id, 'archive')
                }
                onActionComplete={setCharge}
              />
            </div>
          )}
          {charge.status === 'Inactive' && (
            <div>
              <ConfirmButton
                color="success"
                title="上架"
                dialogTitle="確定要上架收費？"
                successMessage="收費已上架"
                action$={() =>
                  appService.charge.updateChargeStatus(charge.id, 'publish')
                }
                onActionComplete={setCharge}
              />
            </div>
          )}
          {charge.status === 'Inactive' && (
            <div>
              <ConfirmButton
                color="error"
                title="刪除"
                dialogTitle="確定要刪除收費？"
                successMessage="收費已刪除"
                action$={() =>
                  appService.charge.updateChargeStatus(charge.id, 'delete')
                }
                onActionComplete={() => {
                  navigate(`/charge/list`);
                }}
              />
            </div>
          )}
        </>
      }
      tabs={tabs}
    />
  );
};

export default ChargeDetails;
