import { useState } from 'react';
import AppTypography from '@components/_common/AppTypography';
import { Box, Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Portlet = ({
  headerPaddingX,
  headerPaddingY,
  headerHeight,
  headerSticky,
  titleComponent,
  title,
  titleVariant,
  description,
  headerRight,
  paddingContent,
  contentPaddingX,
  contentPaddingBottom,
  doubleShadow,
  expandable,
  collapsed,
  children,
  className,
  style,
  ...props
}) => {
  const [expanded, setExpanded] = useState(!collapsed);
  const handleClickHeader = () => {
    if (expandable) {
      setExpanded(!expanded);
    }
  };
  const content = children && (
    <Box
      pt={headerSticky ? 1 : 0}
      px={contentPaddingX != null ? contentPaddingX : paddingContent ? 2 : 0}
      pb={contentPaddingBottom != null ? contentPaddingBottom : 2}
      flex={1}
    >
      {children}
    </Box>
  );
  const renderDescription = () => {
    if (description) {
      if (typeof description === 'string') {
        return (
          <AppTypography
            mt={-2}
            mb={4}
            px={2}
            color={'darkGrey'}
            children={description}
          />
        );
      } else {
        return description;
      }
    }
    return null;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      style={{ ...style, overflow: headerSticky ? 'unset' : null }}
      {...props}
    >
      {(titleComponent || title || headerRight) && (
        <Box
          px={headerPaddingX != null ? headerPaddingX : 2}
          py={headerPaddingY != null ? headerPaddingY : 2}
          minHeight={headerHeight || 70}
          onClick={handleClickHeader}
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          style={
            headerSticky ? { position: 'sticky', top: 0, zIndex: 100 } : {}
          }
        >
          {titleComponent ? (
            titleComponent
          ) : (
            <Box
              children={
                title && (
                  <AppTypography
                    variant={titleVariant || 'h5'}
                    children={title}
                  />
                )
              }
            />
          )}
          {expandable ? (
            <>
              {expanded ? (
                <ExpandLessIcon color="primary" />
              ) : (
                <ExpandMoreIcon color="primary" />
              )}
            </>
          ) : (
            <Box children={headerRight} flexShrink={0} />
          )}
        </Box>
      )}
      {renderDescription()}
      {expandable ? <Collapse in={expanded}>{content}</Collapse> : content}
      {/*{headerRight && (*/}
      {/*  <Box*/}
      {/*    px={2}*/}
      {/*    py={headerPaddingY || 2}*/}
      {/*    height={headerHeight || 70}*/}
      {/*    display="flex"*/}
      {/*    justifyContent="flex-end"*/}
      {/*  >*/}
      {/*    <Box children={headerRight} flexShrink={0} />*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  );
};

export default Portlet;
