import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { toSearchString } from '@utilities/common';

const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const setValue = useCallback(
    queryParams => {
      const search = toSearchString(queryParams);
      navigate(`${location.pathname}?${search}`, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, location.search, navigate]
  );
  return {
    value: queryString.parse(location.search),
    setValue,
  };
};

export default useQueryParams;
