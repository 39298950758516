import AppTypography from '@components/_common/AppTypography';
import BaseLayout from '@components/layouts/BaseLayout';
import AppButton from '@components/_common/AppButton';
import { Box, Divider, useTheme } from '@mui/material';
import FabAdd from '@components/_common/FabAdd';
import { useModal } from 'mui-modal-provider';
import SelectArticleDialog from '@components/dialogs/SelectArticleDialog';
import { Formik } from 'formik';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { deleteListItem, moveListItem } from '@utilities/common';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

const ArticleRow = ({ mt, editMode, article, onDelete }) => {
  const theme = useTheme();
  return (
    <Box
      mt={mt || 0}
      pl={2}
      pr={1}
      height={50}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderRadius: '6px',
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <AppTypography variant="subtitle1">{article.title}</AppTypography>
      <Box display="flex">
        {editMode && (
          <TooltipIconButton
            title="移除"
            color="primary"
            Icon={DeleteIcon}
            onClick={onDelete}
          />
        )}
        <TooltipIconButton
          title="前往"
          color="primary"
          Icon={NavigateNextIcon}
          to={`/article/${article.id}`}
          openNewTab
        />
      </Box>
    </Box>
  );
};

const BreastCancerSettings = ({ calculator }) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const [editMode, setEditMode] = useState(false);
  return (
    <Formik
      initialValues={{
        outpatientArticles: calculator.outpatientArticleCollection?.articleList || [], // prettier-ignore
        preliminaryCheckArticles: calculator.preliminaryCheckArticleCollection?.articleList || [], // prettier-ignore
        skipPreliminaryCheckArticles: calculator.skipPreliminaryCheckArticleCollection?.articleList || [], // prettier-ignore
        furtherCheckArticles: calculator.furtherCheckArticleCollection?.articleList || [], // prettier-ignore
        skipFurtherCheckArticles: calculator.skipFurtherCheckArticleCollection?.articleList || [], // prettier-ignore
        otherCheckArticles: calculator.otherCheckArticleCollection?.articleList || [], // prettier-ignore
        relatedArticles: calculator.relatedArticleCollection?.articleList || [], // prettier-ignore
      }}
      onSubmit={(values, { setSubmitting }) => {
        appService.calculator
          .updateBreastCancerCalculator({
            outpatientArticleIds: values.outpatientArticles.map(x => x.id), // prettier-ignore
            preliminaryCheckArticleIds: values.preliminaryCheckArticles.map(x => x.id), // prettier-ignore
            skipPreliminaryCheckArticleIds: values.skipPreliminaryCheckArticles.map(x => x.id), // prettier-ignore
            furtherCheckArticleIds: values.furtherCheckArticles.map(x => x.id), // prettier-ignore
            skipFurtherCheckArticleIds: values.skipFurtherCheckArticles.map(x => x.id), // prettier-ignore
            otherCheckArticleIds: values.otherCheckArticles.map(x => x.id), // prettier-ignore
            relatedArticleIds: values.relatedArticles.map(x => x.id), // prettier-ignore
          })
          .then(() => {
            setSubmitting(false);
            setEditMode(false);
            enqueueSnackbar(`設定已更新`);
          })
          .catch(err => {
            setSubmitting(false);
            appService.handleError(err);
          });
      }}
      enableReinitialize
    >
      {formProps => {
        const { values, setFieldValue, handleSubmit, isSubmitting } = formProps;

        const handleSelectArticle = key => () => {
          showModal(SelectArticleDialog, {
            onSelect: article => {
              const keyArticles = [...values[key], article];
              setFieldValue(key, keyArticles);
            },
          });
        };

        const handleDelete = (key, index) => e => {
          e.stopPropagation();
          const keyArticles = [...values[key]];
          setFieldValue(key, deleteListItem(keyArticles, index));
        };

        const handleReorder = key => result => {
          if (!result.destination) {
            return;
          }
          const updatedKeyArticles = moveListItem(
            values[key],
            result.source.index,
            result.destination.index
          );
          setFieldValue(key, updatedKeyArticles);
        };

        const articlesSections = [
          { key: 'outpatientArticles', label: '門診文章' }, // prettier-ignore
          { key: 'preliminaryCheckArticles', label: '初步檢查文章' }, // prettier-ignore
          { key: 'skipPreliminaryCheckArticles', label: '初步檢查不考慮私家檢查文章', }, // prettier-ignore
          { key: 'furtherCheckArticles', label: '進一步檢查文章' }, // prettier-ignore
          { key: 'skipFurtherCheckArticles', label: '進一步檢查不考慮私家檢查文章' }, // prettier-ignore
          { key: 'otherCheckArticles', label: '其他檢查文章' }, // prettier-ignore
          { key: 'relatedArticles', label: '相關文章' }, // prettier-ignore
        ];
        return (
          <BaseLayout
            title="設定"
            headerRight={
              <>
                {editMode ? (
                  <AppButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                  >
                    更新
                  </AppButton>
                ) : (
                  <AppButton onClick={() => setEditMode(true)}>編輯</AppButton>
                )}
              </>
            }
            noMb
          >
            <form onSubmit={handleSubmit}>
              {articlesSections.map(({ key, label }, index) => (
                <Box key={key}>
                  {index > 0 && <Divider sx={{ my: 2 }} />}

                  <AppTypography variant="h6">{label}</AppTypography>

                  {!editMode ? (
                    <Box mt={1.5}>
                      {values[key].length === 0 ? (
                        <AppTypography>沒有文章</AppTypography>
                      ) : (
                        values[key].map((x, index) => (
                          <Box key={x.id} py={0.5}>
                            <ArticleRow
                              editMode={editMode}
                              article={x}
                              onDelete={handleDelete(key, index)}
                            />
                          </Box>
                        ))
                      )}
                    </Box>
                  ) : (
                    values[key].length > 0 && (
                      <Box mt={1.5}>
                        <DragDropContext onDragEnd={handleReorder(key)}>
                          <Droppable droppableId="droppable">
                            {provided => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {values[key].map((x, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={index + ''}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          userSelect: 'none',
                                          paddingTop: 4,
                                          paddingBottom: 4,
                                        }}
                                        {...provided.dragHandleProps}
                                      >
                                        <ArticleRow
                                          key={x.id}
                                          editMode={editMode}
                                          article={x}
                                          onDelete={handleDelete(key, index)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Box>
                    )
                  )}

                  {editMode && (
                    <Box mt={1}>
                      <FabAdd
                        text="選擇文章"
                        onClick={handleSelectArticle(key)}
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </form>
          </BaseLayout>
        );
      }}
    </Formik>
  );
};

export default BreastCancerSettings;
