import { Box, useTheme } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import withFormControlWrapper from '@hocs/with-form-control-wrapper';

const FormRichText = ({
  mt,
  ml,
  mr,
  mb,
  pt,
  pl,
  pr,
  pb,
  width,
  height,
  label,
  hint,
  hasError,
  className,
  onChange,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        '& .tox-tinymce': {
          borderRadius: 1,
          borderColor: hasError ? theme.palette.error.main : undefined,
        },
        '& .tox-tinymce--disabled iframe': {
          background: '#F5F5F5',
        },
      }}
    >
      <Editor
        apiKey="cuqwvu3nxtc9kafyi0vwosptta5opxl3htzt66v9e2ry1gx8"
        init={{
          height: height || 500,
          plugins: [
            'advlist',
            'anchor',
            'autolink',
            'charmap',
            'code',
            'fullscreen',
            'help',
            'image',
            'insertdatetime',
            'link',
            'lists',
            'media',
            'preview',
            'searchreplace',
            'table',
            'visualblocks',
            'wordcount',
          ],
          toolbar:
            'undo redo | lists advlist | styles | ' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          invalid_styles: {
            table: 'height',
            tr: 'height',
            td: 'height',
            th: 'height',
          },
          table_header_type: 'section',
        }}
        onEditorChange={content => onChange && onChange(content)}
        {...props}
      />
    </Box>
  );
};

export default withFormControlWrapper(FormRichText);
