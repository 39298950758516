import { useNavigate } from 'react-router-dom';
import useI18n from '@hooks/use-i18n';
import { useCallback } from 'react';

const useAppNavigate = () => {
  const navigate = useNavigate();
  const { language } = useI18n();
  return useCallback(
    (pathname, options = undefined) => {
      navigate(`/${language}${pathname}`, options);
    },
    [language, navigate]
  );
};

export default useAppNavigate;
