import { useEffect } from 'react';
import useRoutes from '@hooks/redux/use-routes';
import { useParams } from 'react-router-dom';

const withPage =
  ({ level, route }) =>
  Component =>
  props => {
    const params = useParams();
    const { update: updateRoutes } = useRoutes();

    useEffect(() => {
      let pathname = route.pathname;
      pathname.split('/').forEach(x => {
        if (x.startsWith(':')) {
          pathname = pathname.replace(x, params[x.replace(':', '')]);
        }
      });
      updateRoutes(level, {
        ...route,
        pathname,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
  };

export default withPage;
