import { useState } from 'react';
import { Box } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import ReorderDialog from '@components/dialogs/ReorderDialog';
import TooltipIconButton from '@components/buttons/TooltipIconButton';

const Reorder = ({ mr, width, className, title, items, onUpdate }) => {
  const [openReorderDialog, setOpenReorderDialog] = useState(false);
  const handleClickUpdate = items => {
    setOpenReorderDialog(false);
    onUpdate && onUpdate(items);
  };
  return (
    <Box mr={mr} width={width} className={className}>
      <TooltipIconButton
        title="更改排序"
        color="primary"
        Icon={ReorderIcon}
        onClick={() => setOpenReorderDialog(true)}
      />

      <ReorderDialog
        open={openReorderDialog}
        title={title}
        items={items}
        onClickCancel={() => setOpenReorderDialog(false)}
        onClickUpdate={handleClickUpdate}
      />
    </Box>
  );
};

export default Reorder;
