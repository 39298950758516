import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import withFetchModelWithId from '@hocs/with-fetch-model-with-id';
import withPage from '@hocs/with-page';
import ArticleForm from '@pages/article/ArticleForm';
import ArticleDetailsPage from '@pages/article/details/ArticleDetails';

const ArticleDetails = ({ fetchedModel, ...props }) => {
  const [article, setArticle] = useState(fetchedModel);
  const _props = {
    ...props,
    article,
    setArticle,
  };
  // prettier-ignore
  return (
    <Routes>
      <Route index element={<ArticleDetailsPage tab="summary" {..._props} />} />
      <Route path="summary" element={<ArticleDetailsPage tab="summary" {..._props} />} />
      <Route path="modules" element={<ArticleDetailsPage tab="modules" {..._props} />} />
      <Route path="edit" element={<ArticleForm.Edit {..._props} />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

const page = {
  level: 1,
  route: {
    title: '',
    pathname: '/article/:id',
    fetching: true,
  },
};

export default withPage(page)(
  withFetchModelWithId({
    serviceName: 'article',
    promiseName: 'getArticle',
    fallbackUrl: '/article/list',
    title: '{title}',
    page: page,
  })(ArticleDetails)
);
