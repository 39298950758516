import { createSlice } from '@reduxjs/toolkit';
import { getStateFromCookies } from 'redux-cookies-middleware';
import { omitNullOrUndefined } from '@utilities/common';

const cookiePathMapping = {
  sessionToken: { name: 'session_token' },
};

const initialState = {
  sessionToken: null,
  user: null,
  routes: [],
  errorMessage: null,
  openMenuDrawer: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: (() => {
    const cookies = getStateFromCookies({}, cookiePathMapping);
    const cookiesWithoutNullOrUndefined = omitNullOrUndefined(cookies);
    return { ...initialState, ...cookiesWithoutNullOrUndefined };
  })(),
  reducers: {
    setSessionToken(state, action) {
      state.sessionToken = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    login(state, action) {
      const { sessionToken, user } = action.payload;
      state.sessionToken = sessionToken;
      state.user = user;
    },
    logout(state) {
      state.sessionToken = null;
      state.user = null;
    },
    setRoutes(state, action) {
      state.routes = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    setOpenMenuDrawer(state, action) {
      state.openMenuDrawer = action.payload;
    },
  },
});

const { actions, reducer } = appSlice;

export const {
  setSessionToken,
  setUser,
  login,
  logout,
  setRoutes,
  setErrorMessage,
  setOpenMenuDrawer,
} = actions;

export default reducer;
