import useAppService from '@hooks/use-app-service';
import { useState } from 'react';
import { Formik } from 'formik';
import md5 from 'md5';
import schemaLogin from '@validations/login.schema';
import { Box, styled } from '@mui/material';
import AppTypography from '@components/_common/AppTypography';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import FormInputField from '@components/forms/FormInputField';
import AppLink from '@components/_common/AppLink';
import AppButton from '@components/_common/AppButton';
import useLogin from '@hooks/redux/use-login';

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: theme.spacing(5, 2.5),
  width: 400,
  borderRadius: theme.spacing(2),
  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 2px 12px 0px;',
}));

const LoginForm = ({ onSubmitSuccess, ...props }) => {
  const appService = useAppService();
  const [resetPassword, setResetPassword] = useState(props.resetPassword);
  const [error, setError] = useState('');
  return (
    <Formik
      initialValues={{
        email: process.env.NODE_ENV !== 'development' ? '' : 'admin@xnotech.com', // prettier-ignore
        password: process.env.NODE_ENV !== 'development' ? '' : 'a1234567', // prettier-ignore
      }}
      onSubmit={(values, { setSubmitting }) => {
        appService.authentication
          .login(values.email, md5(values.password))
          .then(res => {
            onSubmitSuccess(res);
          })
          .catch(error => {
            setResetPassword(false);
            setSubmitting(false);
            setError('error.login');
          });
      }}
      validateOnMount={true}
      validationSchema={schemaLogin}
    >
      {formProps => {
        const { isSubmitting, handleSubmit, isValid } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <FormInputField
              id="email"
              label="form.email"
              formProps={formProps}
            />

            <FormInputField
              mt={2}
              id="password"
              type="password"
              label="form.password"
              formProps={formProps}
            />

            <Box mt={2} display="flex" justifyContent="flex-end">
              <AppLink to="/forgot-password" i="login.forgotPassword" />
            </Box>

            {error && error !== '' && (
              <AppTypography mt={3} color="error.main" i={error} />
            )}

            {resetPassword && resetPassword !== '' && (
              <AppTypography
                mt={3}
                color="success.main"
                i="login.resetPasswordSuccess"
              />
            )}

            <AppButton
              mt={5}
              large
              type="submit"
              sx={{ mt: 5, width: 1 }}
              i="login.login"
              onClick={handleSubmit}
              disabled={isValid && isSubmitting}
              showLoading={isValid && isSubmitting}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const Login = () => {
  const location = useLocation();
  const login = useLogin();
  const resetPassword = queryString.parse(location.search).resetPassword === 'true'; // prettier-ignore
  return (
    <>
      <Box component="main" py={15}>
        <AppTypography variant="h2" i="login.title" center />
        <AppTypography
          mt={1.5}
          variant="h5"
          color="text.secondary"
          i="login.body"
          center
        />
        <Root>
          <LoginForm
            resetPassword={resetPassword}
            onSubmitSuccess={loginResponse => {
              const { sessionToken, user } = loginResponse;
              login(sessionToken, user);
            }}
          />
        </Root>
      </Box>
    </>
  );
};

export default Login;
