import 'react-image-crop/src/ReactCrop.scss';

import { useEffect, useRef, useState, forwardRef } from 'react';
import ReactCrop from 'react-image-crop';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import AppTypography from '@components/_common/AppTypography';

const Transition = forwardRef(function Transition(props, ref) {
  const { children } = props;
  return <Slide direction="up" ref={ref} {...props} children={children} />;
});

const getResizedCanvas = (canvas, newWidth, newHeight) => {
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext('2d');
  ctx.imageSmoothingEnabled = false;
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
};

const getCroppedImageBase64 = (previewCanvas, crop, scaleX, scaleY, type) => {
  if (!crop || !previewCanvas) {
    return;
  }

  const canvas = getResizedCanvas(
    previewCanvas,
    Math.round(crop.width * scaleX),
    Math.round(crop.height * scaleY)
  );
  return canvas.toDataURL(type);
};

const CropImageDialog = props => {
  const {
    open,
    ratio,
    imageBase64,
    imageType,
    onClose,
    onClickCancel,
    onClickConfirm,
  } = props;
  const [crop, setCrop] = useState({ aspect: ratio });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef();
  const previewCanvasRef = useRef();

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    canvas.width = Math.round(crop.width * scaleX);
    canvas.height = Math.round(crop.height * scaleY);

    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(
      image,
      Math.round(crop.x * scaleX),
      Math.round(crop.y * scaleY),
      Math.round(crop.width * scaleX),
      Math.round(crop.height * scaleY),
      0,
      0,
      canvas.width,
      canvas.height
    );
  }, [completedCrop]);

  const getCropSize = () => {
    const image = imgRef.current;
    if (!image) {
      return { width: 0, height: 0 };
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    return {
      width: Math.round((crop.width ?? 0) * scaleX),
      height: Math.round((crop.height ?? 0) * scaleY),
    };
  };

  const handleClickConfirm = () => {
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const croppedImageBase64 = getCroppedImageBase64(
      previewCanvasRef.current,
      completedCrop,
      scaleX,
      scaleY,
      imageType
    );
    onClickConfirm &&
      onClickConfirm({
        imageBase64: croppedImageBase64,
        width: Math.round(completedCrop.width),
        height: Math.round(completedCrop.height),
      });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogTitle>裁剪圖像</DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Box flex={1}>
            <ReactCrop
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
            >
              <img ref={imgRef} src={imageBase64} alt="" />
            </ReactCrop>
          </Box>
          <Box px={2} width={180} flexShrink={0}>
            <AppTypography variant="h5" children="Width" />
            <AppTypography children={`${getCropSize().width}px`} />
            <AppTypography mt={2} variant="h5" children="Height" />
            <AppTypography children={`${getCropSize().height}px`} />
          </Box>
        </Box>
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="inherit">
          取消
        </Button>
        <Button onClick={handleClickConfirm}>確定</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropImageDialog;
