import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setRoutes } from '@redux/slices/app';

let routesRef = [];

const useRoutes = () => {
  const dispatch = useDispatch();
  const value = useSelector(state => state.routes);
  const setValue = useCallback(
    newValue => {
      dispatch(setRoutes(newValue));
      routesRef = newValue;
    },
    [dispatch]
  );
  const update = useCallback(
    (level, route) => {
      const newValue = [...routesRef];
      newValue[level] = route;

      // console.log('useRoutes', 'update', level, newValue);

      const indexes = Array.from(Array(newValue.length).keys());
      const containsEmpty = indexes.some(i => !newValue[i]);
      if (!containsEmpty) {
        // console.log('useRoutes', 'update', level, newValue, '--', 'PUSHED');
        setValue(newValue);
      }

      routesRef = newValue;
    },
    [setValue]
  );
  return {
    value,
    setValue,
    update,
  };
};

export default useRoutes;
