import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';
import QuizIcon from '@mui/icons-material/Quiz';
import CalculateIcon from '@mui/icons-material/Calculate';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WebIcon from '@mui/icons-material/Web';
import User from '@pages/user';
import BreastCancer from '@pages/breast-cancer';
import Charge from '@pages/charge';
import Home from '@pages/page/home';
// import Calculator from '@pages/page/calculator';
import Article from '@pages/article';
import Questionnaire from '@pages/questionnaire';
import ArticleList from '@pages/page/article-list';
import SelectOption from '@pages/select-option';
import PersonIcon from '@mui/icons-material/Person';
import Member from '@pages/member';
import BreastCancerSurgery from '@pages/breast-cancer-surgery';
import Common from '@pages/page/common';
import Footer from '@pages/page/footer';

const routes = [
  { title: '網站管理' },
  {
    icon: WebIcon,
    title: '共用內容',
    url: '/common',
    path: 'common/*',
    Component: Common,
  },
  {
    icon: HomeIcon,
    title: '主頁',
    url: '/home',
    path: 'home/*',
    Component: Home,
  },
  {
    icon: LibraryBooksIcon,
    title: '文章列表',
    url: '/article-list',
    path: 'article-list/*',
    Component: ArticleList,
  },
  {
    icon: CallToActionIcon,
    title: '網頁底部',
    url: '/footer',
    path: 'footer/*',
    Component: Footer,
  },
  // {
  //   icon: CalculateIcon,
  //   title: '計算機',
  //   url: '/calculator',
  //   path: 'calculator/*',
  //   Component: Calculator,
  // },
  { title: '內容管理' },
  {
    icon: CalculateIcon,
    title: '乳癌計算機',
    url: '/breast-cancer',
    path: 'breast-cancer/*',
    Component: BreastCancer,
  },
  {
    icon: CalculateIcon,
    title: '乳癌治療計算機',
    url: '/breast-cancer-surgery',
    path: 'breast-cancer-surgery/*',
    Component: BreastCancerSurgery,
  },
  {
    icon: ArticleIcon,
    title: '文章',
    url: '/article',
    path: 'article/*',
    Component: Article,
  },
  {
    icon: QuizIcon,
    title: '問卷',
    url: '/questionnaire',
    path: 'questionnaire/*',
    Component: Questionnaire,
  },
  {
    icon: AttachMoneyIcon,
    title: '收費',
    url: '/charge/list',
    path: 'charge/*',
    Component: Charge,
  },
  {
    icon: CheckBoxIcon,
    title: '會員問卷選項',
    url: '/select-option',
    path: 'select-option/*',
    Component: SelectOption,
  },
  { title: '數據管理' },
  {
    icon: PersonIcon,
    title: '會員',
    url: '/member/list',
    path: 'member/*',
    Component: Member,
  },
  { title: 'CMS 管理' },
  {
    icon: PeopleIcon,
    title: 'CMS 用戶',
    url: '/cms-user/list',
    path: 'cms-user/*',
    Component: User,
  },
];

export default routes;
