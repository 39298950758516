import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import withFetchModelWithId from '@hocs/with-fetch-model-with-id';
import withPage from '@hocs/with-page';
import ChargeForm from '@pages/charge/ChargeForm';
import ChargeDetailsPage from '@pages/charge/details/ChargeDetails';

const ChargeDetails = ({ fetchedModel, ...props }) => {
  const [charge, setCharge] = useState(fetchedModel);
  const _props = {
    ...props,
    charge,
    setCharge,
  };

  // prettier-ignore
  return (
    <Routes>
      <Route index element={<ChargeDetailsPage tab="summary" {..._props} />} />
      <Route path="summary" element={<ChargeDetailsPage tab="summary" {..._props} />} />
      <Route path="change-logs" element={<ChargeDetailsPage tab="change-logs" {..._props} />} />
      <Route path="edit" element={<ChargeForm.Edit {..._props} />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

const page = {
  level: 1,
  route: {
    title: '',
    pathname: '/charge/:id',
    fetching: true,
  },
};

export default withPage(page)(
  withFetchModelWithId({
    serviceName: 'charge',
    promiseName: 'getCharge',
    fallbackUrl: '/charge/list',
    title: charge => {
      if (!charge.category) {
        return '{type}';
      }
      return '{type} | {place}';
    },
    page: page,
  })(ChargeDetails)
);
