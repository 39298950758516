import BaseLayout from '@components/layouts/BaseLayout';
import { Formik } from 'formik';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import useAppNavigate from '@hooks/use-app-navigate';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import withPage from '@hocs/with-page';
import schemaQuestionnaire from '@validations/questionnaire.schema';
import AppTypography from '@components/_common/AppTypography';
import FormRichText from '@components/forms/FormRichText';
import FormRadioGroup from '@components/forms/FormRadioGroup';
import FabAdd from '@components/_common/FabAdd';
import { swapListItem } from '@utilities/common';
import Portlet from '@components/layouts/Portlet';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import FormAssetUpload from '@components/forms/FormAssetUpload';

const Questions = props => {
  const { showAnswers, formProps } = props;
  const { values, setValues, submitCount, errors } = formProps;

  const handleMoveUp = index => () => {
    const newQuestions = swapListItem(values.questions, index, index - 1);
    setValues({
      ...values,
      questions: newQuestions,
    });
  };
  const handleMoveDown = index => () => {
    const newQuestions = swapListItem(values.questions, index, index + 1);
    setValues({
      ...values,
      questions: newQuestions,
    });
  };
  const handleDelete = index => () => {
    let newQuestions = [...values.questions];
    newQuestions.splice(index, 1);
    setValues({
      ...values,
      questions: newQuestions,
    });
  };
  const handleClickAddAnswer = questionIndex => () => {
    const defaultAnswer = {
      answer: '',
      score: '',
    };
    let newQuestions = [...values.questions];
    newQuestions[questionIndex].answers = [
      ...newQuestions[questionIndex].answers,
      defaultAnswer,
    ];
    setValues({
      ...values,
      questions: newQuestions,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        {values.questions.map((question, index) => (
          <Grid key={index} item xs={12}>
            <Portlet
              headerPaddingX={0}
              headerPaddingY={0}
              contentPaddingX={0}
              contentPaddingBottom={0}
              headerHeight={42}
              titleComponent={
                <>
                  <Box width={1} display="flex">
                    <AppTypography
                      variant="h5"
                      mt={1}
                      width={32}
                      flexShrink={0}
                    >
                      {index + 1}.
                    </AppTypography>

                    <Box flex={1}>
                      <FormInputField
                        id={`questions.${index}.question`}
                        formProps={formProps}
                      />
                    </Box>
                  </Box>
                </>
              }
              headerRight={
                <Box>
                  <IconButton
                    aria-label="move up"
                    onClick={handleMoveUp(index)}
                    disabled={index === 0}
                  >
                    <KeyboardArrowUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="move down"
                    onClick={handleMoveDown(index)}
                    disabled={index === values.questions.length - 1}
                  >
                    <KeyboardArrowDownIcon fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={handleDelete(index)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
              paddingContent
              doubleShadow
            >
              {!showAnswers ? undefined : (
                <>
                  <Box ml={4}>
                    <AppTypography mt={2} mb={2} variant="h4" children="答案" />
                    {(values.questions[index].answers ?? []).length > 0 && (
                      <Answers
                        showScore
                        questionIndex={index}
                        formProps={formProps}
                      />
                    )}
                    {submitCount > 0 &&
                      errors.questions &&
                      errors.questions[index] &&
                      errors.questions[index].answers &&
                      typeof errors.questions[index].answers === 'string' && (
                        <AppTypography
                          color="error.main"
                          children={errors.questions[index].answers}
                        />
                      )}
                  </Box>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FabAdd
                      text="新增答案"
                      onClick={handleClickAddAnswer(index)}
                    />
                  </Box>
                </>
              )}
            </Portlet>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const Answers = props => {
  const { showScore, questionIndex, formProps } = props;
  const { values, setValues } = formProps;

  const answers =
    questionIndex != null
      ? values.questions[questionIndex].answers
      : values.answers;

  const updateAnswers = newAnswers => {
    setValues({
      ...values,
      answers: newAnswers,
    });
  };
  const updateQuestionAnswers = newAnswers => {
    let newQuestions = [...values.questions];
    newQuestions[questionIndex].answers = newAnswers;
    setValues({
      ...values,
      questions: newQuestions,
    });
  };

  const handleMoveUp = index => () => {
    const newAnswers = swapListItem(answers, index, index - 1);
    if (questionIndex != null) {
      updateQuestionAnswers(newAnswers);
    } else {
      updateAnswers(newAnswers);
    }
  };
  const handleMoveDown = index => () => {
    const newAnswers = swapListItem(answers, index, index + 1);
    if (questionIndex != null) {
      updateQuestionAnswers(newAnswers);
    } else {
      updateAnswers(newAnswers);
    }
  };
  const handleDelete = index => () => {
    let newAnswers = [...answers];
    newAnswers.splice(index, 1);
    if (questionIndex != null) {
      updateQuestionAnswers(newAnswers);
    } else {
      updateAnswers(newAnswers);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {answers.map((answer, index) => (
          <Grid key={index} item xs={12}>
            <Portlet
              headerPaddingX={0}
              headerPaddingY={0}
              headerHeight={42}
              title={answer.answer}
              titleComponent={
                <Box width={1} display="flex">
                  <AppTypography variant="h5" mt={1} width={32} flexShrink={0}>
                    {index + 1}.
                  </AppTypography>

                  <Box flex={1}>
                    <FormInputField
                      id={
                        questionIndex != null
                          ? `questions.${questionIndex}.answers.${index}.answer`
                          : `answers.${index}.answer`
                      }
                      formProps={formProps}
                    />
                  </Box>
                </Box>
              }
              headerRight={
                <Box>
                  <IconButton
                    aria-label="move up"
                    onClick={handleMoveUp(index)}
                    disabled={index === 0}
                  >
                    <KeyboardArrowUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="move down"
                    onClick={handleMoveDown(index)}
                    disabled={index === answers.length - 1}
                  >
                    <KeyboardArrowDownIcon fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={handleDelete(index)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
              paddingContent
              doubleShadow
            >
              {showScore ? (
                <Box mt={1} mx={-2} mb={-2} pl={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormInputField
                        id={
                          questionIndex != null
                            ? `questions.${questionIndex}.answers.${index}.score`
                            : `answers.${index}.score`
                        }
                        label="分數"
                        mask="999999999"
                        formProps={formProps}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Portlet>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const ScoreResults = ({ formProps }) => {
  const { values, setValues } = formProps;
  const handleMoveUp = index => () => {
    const newQuestions = swapListItem(values.scoreResults, index, index - 1);
    setValues({
      ...values,
      scoreResults: newQuestions,
    });
  };
  const handleMoveDown = index => () => {
    const newQuestions = swapListItem(values.scoreResults, index, index + 1);
    setValues({
      ...values,
      scoreResults: newQuestions,
    });
  };
  const handleDelete = index => () => {
    let newQuestions = [...values.scoreResults];
    newQuestions.splice(index, 1);
    setValues({
      ...values,
      scoreResults: newQuestions,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        {values.scoreResults.map((scoreResult, index) => (
          <Grid key={index} item xs={12}>
            <Portlet
              headerPaddingX={0}
              headerPaddingY={0}
              headerHeight={42}
              contentPaddingX={0}
              contentPaddingBottom={0}
              headerRight={
                <Box>
                  <IconButton
                    aria-label="move up"
                    onClick={handleMoveUp(index)}
                    disabled={index === 0}
                  >
                    <KeyboardArrowUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="move down"
                    onClick={handleMoveDown(index)}
                    disabled={index === values.scoreResults.length - 1}
                  >
                    <KeyboardArrowDownIcon fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={handleDelete(index)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
              paddingContent
              doubleShadow
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormRichText
                    id={`scoreResults.${index}.result`}
                    height={400}
                    onChange={content =>
                      formProps.setFieldValue(
                        `scoreResults.${index}.result`,
                        content
                      )
                    }
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputField
                    id={`scoreResults.${index}.scoreMin`}
                    label="顯示結果之最少分數"
                    mask="999999999"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputField
                    id={`scoreResults.${index}.scoreMax`}
                    label="顯示結果之最大分數"
                    mask="999999999"
                    formProps={formProps}
                  />
                </Grid>
              </Grid>
            </Portlet>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const QuestionnaireForm = ({ questionnaire, setQuestionnaire }) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useAppNavigate();
  const types = [
    {
      label: '答案選擇次數',
      value: 'Frequency',
    },
    {
      label: '計分制 (問題相同答案)',
      value: 'Score',
    },
    {
      label: '計分制 (問題有不同答案)',
      value: 'ScoreQuestionSpecificAnswers',
    },
  ];
  return (
    <BaseLayout>
      <Formik
        initialValues={{
          backgroundImage: questionnaire?.backgroundImage || null,
          name: questionnaire?.name || '',
          description: questionnaire?.description || '',
          questionPrefix: questionnaire?.questionPrefix || '',
          result: questionnaire?.result || '',
          type: questionnaire?.type || 'Frequency',

          questions: questionnaire?.questions || [],
          answers: questionnaire?.answers || [],
          scoreResults: questionnaire?.scoreResults || [],
        }}
        onSubmit={(values, { setSubmitting }) => {
          const requestBody = {
            ...values,
          };
          const submit$ = questionnaire
            ? appService.questionnaire.updateQuestionnaire(
                questionnaire.id,
                requestBody
              )
            : appService.questionnaire.createQuestionnaire(requestBody);
          submit$.then(
            res => {
              setSubmitting(false);

              if (questionnaire) {
                setQuestionnaire(res);
                enqueueSnackbar(`問卷已儲存`);
              } else {
                enqueueSnackbar(`問卷已新增`);
                navigate(`/questionnaire/${res.id}/edit`);
              }
            },
            err => {
              setSubmitting(false);
              return appService.handleError(err);
            }
          );
        }}
        validateOnMount={true}
        validationSchema={schemaQuestionnaire}
      >
        {props => {
          const {
            values,
            errors,
            submitCount,
            setFieldValue,
            setValues,
            handleSubmit,
            isSubmitting,
          } = props;

          const handleClickAddQuestion = () => {
            const defaultQuestion = {
              question: '',
              answers: [],
            };
            setValues({
              ...values,
              questions: [...values.questions, defaultQuestion],
            });
          };

          const handleClickAddAnswer = () => {
            const defaultAnswer = {
              answer: '',
              score: '',
            };
            setValues({
              ...values,
              answers: [...values.answers, defaultAnswer],
            });
          };

          const handleClickAddScoreResult = () => {
            const defaultScoreResult = {
              result: '',
              scoreMin: '',
              scoreMax: '',
            };
            setValues({
              ...values,
              scoreResults: [...values.scoreResults, defaultScoreResult],
            });
          };

          const showQuestionSpecificAnswers =
            values.type === 'ScoreQuestionSpecificAnswers';
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormAssetUpload
                    image
                    freeFormCrop
                    withAssetDescription
                    label="背景圖片"
                    ratio={1232 / 720}
                    asset={values.backgroundImage}
                    onUploadedFile={asset =>
                      setFieldValue('backgroundImage', asset)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormInputField id="name" label="名稱" formProps={props} />
                </Grid>

                <Grid item xs={12}>
                  <FormRichText
                    id="description"
                    label="簡介"
                    height={300}
                    value={values.description}
                    onChange={content => setFieldValue('description', content)}
                    formProps={props}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormInputField
                    id="questionPrefix"
                    label="問題前言"
                    formProps={props}
                  />
                </Grid>

                <Grid item xs={12}>
                  {questionnaire ? (
                    <AppTypography>
                      類型：
                      <AppTypography variant="h4" component="span">
                        {types.find(type => type.value === values.type).label}
                      </AppTypography>
                    </AppTypography>
                  ) : (
                    <FormRadioGroup
                      label="類型"
                      options={types}
                      value={values.type}
                      onChange={e => setFieldValue('type', e.target.value)}
                      disabled={!!questionnaire}
                    />
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <AppTypography mb={2} variant="h4" children="問題" />
              {values.questions.length > 0 && (
                <Questions
                  showAnswers={showQuestionSpecificAnswers}
                  formProps={props}
                />
              )}
              {submitCount > 0 &&
                errors.questions &&
                typeof errors.questions === 'string' && (
                  <AppTypography
                    color="error.main"
                    children={errors.questions}
                  />
                )}
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FabAdd text="新增問題" onClick={handleClickAddQuestion} />
              </Box>
              {!showQuestionSpecificAnswers && (
                <>
                  <Divider sx={{ my: 2 }} />

                  <AppTypography mb={2} variant="h4" children="答案" />

                  {values.answers.length > 0 && (
                    <Answers
                      showScore={values.type === 'Score'}
                      formProps={props}
                    />
                  )}

                  {submitCount > 0 &&
                    errors.answers &&
                    typeof errors.answers === 'string' && (
                      <AppTypography
                        color="error.main"
                        children={errors.answers}
                      />
                    )}

                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FabAdd text="新增答案" onClick={handleClickAddAnswer} />
                  </Box>
                </>
              )}

              <Divider sx={{ my: 2 }} />

              <AppTypography mb={2} variant="h4" children="結果" />

              {values.type === 'Frequency' ? (
                <FormRichText
                  id="result"
                  height={300}
                  value={values.result}
                  onChange={content => setFieldValue('result', content)}
                  formProps={props}
                />
              ) : (
                <>
                  {values.scoreResults.length > 0 && (
                    <ScoreResults formProps={props} />
                  )}

                  {submitCount > 0 &&
                    errors.scoreResults &&
                    typeof errors.scoreResults === 'string' && (
                      <AppTypography
                        color="error.main"
                        children={errors.scoreResults}
                      />
                    )}

                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FabAdd
                      text="新增分數結果"
                      onClick={handleClickAddScoreResult}
                    />
                  </Box>
                </>
              )}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <AppButton
                  type="submit"
                  i={
                    questionnaire
                      ? 'questionnaire.update'
                      : 'questionnaire.create'
                  }
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  showLoading={isSubmitting}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
    </BaseLayout>
  );
};

const QuestionnaireFormVariants = {
  Create: withPage({
    level: 1,
    route: {
      title: '新增問卷',
      pathname: '/questionnaire/create',
    },
  })(QuestionnaireForm),
  Edit: withPage({
    level: 2,
    route: {
      title: '編輯問卷',
      pathname: `/questionnaire/:id/edit`,
    },
  })(QuestionnaireForm),
};

export default QuestionnaireFormVariants;
