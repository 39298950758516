import TabLayout from '@components/layouts/TabLayout';
import MemberDetailsSummary from '@pages/member/details/_subs/MemberDetailsSummary';

const MemberDetails = props => {
  const { tab, member } = props;
  const tabs = [
    {
      title: '概要',
      key: 'summary',
      url: `/member/${member.id}/summary`,
      component: () => {
        return <MemberDetailsSummary {...props} />;
      },
    },
  ];
  return (
    <TabLayout initialValue={tabs.map(x => x.key).indexOf(tab)} tabs={tabs} />
  );
};

export default MemberDetails;
