const article = request => ({
  getArticleList: params =>
    request({
      method: 'get',
      endpoint: `/article/list`,
      params: params,
    }),

  getArticle: id =>
    request({
      method: 'get',
      endpoint: `/article/${id}`,
    }),

  createArticle: body =>
    request({
      method: 'post',
      endpoint: '/article',
      data: body,
    }),

  updateArticle: (id, body) =>
    request({
      method: 'put',
      endpoint: `/article/${id}`,
      data: body,
    }),

  updateArticleStatus: (
    id,
    action // pending-review | reviewed | publish | hide | delete
  ) =>
    request({
      method: 'post',
      endpoint: `/article/${id}/${action}`,
    }),

  updateArticleModulePageModules: (id, body) =>
    request({
      method: 'post',
      endpoint: `/article/${id}/module-page-modules`,
      data: body,
    }),
});

export default article;
