import BaseLayout from '@components/layouts/BaseLayout';
import { Box, Grid } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import { Formik } from 'formik';
import schemaChangePassword from '@validations/change-password.schema';
import { useSnackbar } from 'notistack';
import useAppService from '@hooks/use-app-service';
import md5 from 'md5';

const initialValues = {
  password: '',
  newPassword: '',
  newPasswordConfirm: '',
};

const ProfileChangePassword = () => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <BaseLayout>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setValues, setSubmitting }) => {
          appService.authentication
            .changePassword({
              password: md5(values.password),
              newPassword: md5(values.newPassword),
            })
            .then(res => {
              const successMessage = `密碼已更新`;
              enqueueSnackbar(successMessage);
              setSubmitting(false);
              setValues(initialValues, false).then();
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
        validationSchema={schemaChangePassword}
      >
        {formProps => {
          const { handleSubmit, isSubmitting } = formProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInputField
                    id="password"
                    label="form.password"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={0} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputField
                    id="newPassword"
                    label="form.newPassword"
                    formProps={formProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputField
                    id="newPasswordConfirm"
                    label="form.newPasswordConfirm"
                    formProps={formProps}
                  />
                </Grid>
              </Grid>

              <Box mt={2} display="flex" justifyContent="flex-end">
                <AppButton
                  type="submit"
                  i="profile.updatePassword"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  showLoading={isSubmitting}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
    </BaseLayout>
  );
};

export default ProfileChangePassword;
