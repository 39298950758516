import { styled } from '@mui/material';
import { IMaskInput } from 'react-imask';
import withFormControlWrapper from '@hocs/with-form-control-wrapper';
import ReactInputMask from 'react-input-mask';

const styles = ({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(0, 2),
  width: '100%',
  height: 42,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '6px',
  outline: 0,
  margin: 0,
  transition: 'background-color .2s, border-color .2s',
  WebkitAppearance: 'none',
  '&::placeholder': {
    color: theme.palette.common.grey,
  },
  '&:hover': {
    backgroundColor: '#F8F8F8',
    border: '1px solid ' + theme.palette.primary.main,
  },
  '&:focus': {
    backgroundColor: '#F8F8F8',
    padding: theme.spacing(1.125, 1.875),
    border: '2px solid ' + theme.palette.primary.main,
  },
  '&:disabled': {
    backgroundColor: theme.palette.common.greyLight,
    border: '1px solid ' + theme.palette.common.greyLight,
    cursor: 'not-allowed',
  },
});

const Input = styled('input')(styles);

const MaskedInput = styled(IMaskInput)(styles);

const ReactInputMaskInput = styled(ReactInputMask)(styles);

const FormInputField = ({
  id,
  formProps,
  hasError,
  currency,
  mask,
  value,
  disabled,
  ...props
}) => {
  const { isSubmitting, handleChange, handleBlur } = formProps || {};
  const inputProps = {
    ...props,
    onBlur: handleBlur,
    disabled: disabled || isSubmitting,
    value: `${value != null ? `${value}` : ''}`,
    sx: {
      ...props.sx,
      ...(hasError && {
        borderColor: 'error.main',
        borderWidth: 2,
      }),
    },
  };
  const setFieldValue = value => formProps.setFieldValue(id, value);
  return (
    <>
      {currency ? (
        <>
          <MaskedInput
            id={id}
            mask="HK$ num"
            unmask={true}
            blocks={{
              num: {
                mask: Number,
                thousandsSeparator: ',',
              },
            }}
            radix="."
            onAccept={setFieldValue}
            {...inputProps}
          />
        </>
      ) : mask ? (
        <ReactInputMaskInput
          id={id}
          mask={mask}
          maskPlaceholder={null}
          style={{ width: '100%' }}
          onChange={handleChange}
          {...inputProps}
        />
      ) : (
        <Input id={id} type="text" onChange={handleChange} {...inputProps} />
      )}
    </>
  );
};

export default withFormControlWrapper(FormInputField);
