import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import AppTypography from '@components/_common/AppTypography';

const OkDialog = props => {
  const { open, title, onClose } = props;
  const [description, setDescription] = useState(null);
  useEffect(() => {
    if (props.description) {
      setDescription(props.description);
    }
  }, [props.description]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <AppTypography i={description} />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>知道</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OkDialog;
