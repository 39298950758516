import { configureStore } from '@reduxjs/toolkit';
import reduxCookiesMiddleware from 'redux-cookies-middleware/lib/reduxCookiesMiddleware';
import appSlice from '@redux/slices/app';

const cookiePathMapping = {
  sessionToken: { name: 'session_token' },
};

const store = configureStore({
  reducer: appSlice,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware(),
    reduxCookiesMiddleware(cookiePathMapping),
  ],
});

export default store;
