import React, { useEffect, useState } from 'react';
import useAppService from '@hooks/use-app-service';
import { useNavigate } from 'react-router-dom';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';

const useFetchPage = pageName => {
  const navigate = useNavigate();
  const appService = useAppService();
  const [page, setPage] = useState(null);
  useEffect(() => {
    appService.page.getPage(pageName).then(_page => {
      setPage(_page);
      if (!_page) {
        navigate(-1);
      }
    }, appService.handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appService, pageName]);
  return { page, setPage };
};

const withFetchPage =
  ({ pageName } = {}) =>
  Component => {
    return props => {
      const { overridePageName } = props;
      const { page, setPage } = useFetchPage(overridePageName || pageName);
      return page ? (
        <Component
          pageName={overridePageName || pageName}
          page={page}
          setPage={setPage}
          {...props}
        />
      ) : (
        <AppLoadingSpinner />
      );
    };
  };

export default withFetchPage;
