import TabLayout from '@components/layouts/TabLayout';
import BreastCancerSurgerySettings from '@pages/breast-cancer-surgery/_subs/BreastCancerSurgerySettings';
import { useEffect, useState } from 'react';
import useAppService from '@hooks/use-app-service';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';

const BreastCancerSurgery = props => {
  const { tab } = props;
  const appService = useAppService();
  const [calculator, setCalculator] = useState(null);

  useEffect(() => {
    appService.calculator
      .getBreastCancerSurgeryCalculator()
      .then(setCalculator);
  }, [appService.calculator]);

  const tabs = [
    {
      title: '設定',
      key: 'settings',
      url: `/breast-cancer-surgery/settings`,
      component: () => {
        return (
          <BreastCancerSurgerySettings calculator={calculator} {...props} />
        );
      },
    },
  ];
  return !calculator ? (
    <AppLoadingSpinner mt={5} />
  ) : (
    <TabLayout initialValue={tabs.map(x => x.key).indexOf(tab)} tabs={tabs} />
  );
};

export default BreastCancerSurgery;
