import { Navigate, Route, Routes } from 'react-router-dom';
import ChargeList from '@pages/charge/ChargeList';
import ChargeDetails from '@pages/charge/details';
import withPage from '@hocs/with-page';
import ChargeForm from '@pages/charge/ChargeForm';

const Charge = () => {
  return (
    <Routes>
      <Route path="list" Component={ChargeList} />
      <Route path="create" Component={ChargeForm.Create} />
      <Route path=":id/*" Component={ChargeDetails} />
      <Route path="*" element={<Navigate to="list" />} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '收費',
    pathname: '/charge/list',
  },
};

export default withPage(page)(Charge);
