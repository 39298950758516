import * as Yup from 'yup';

const schema = Yup.object().shape({
  slug: Yup.string().nullable().required('請輸入文章網址'),
  author: Yup.string().nullable().required('請輸入文章作者'),
  image: Yup.object().nullable().required('請上載圖片'),
  title: Yup.string().nullable().required('請輸入文章標題'),
  publishDate: Yup.date().required('請選擇發佈日期'),
  expiryDate: Yup.date().when('noExpiryDate', {
    is: value => !value,
    then: schema =>
      schema
        .nullable()
        .test('isAfterStartDate', '下架日期不得早過發佈日期', function (v) {
          const ref = Yup.ref('publishDate');
          const publishDate = this.resolve(ref) || '';
          const expiryDate = v || '';
          return expiryDate.getTime() > publishDate.getTime();
        }),
  }),
});

export default schema;
