import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { moduleList } from '@components/module-page/config/module-list';
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';

const SelectModuleDialog = ({ availableTypes, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };
  const handleListItemClick = (type, initialModuleBody) => {
    onClose(type, initialModuleBody);
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>選擇模組</DialogTitle>
      <List>
        {moduleList
          .filter(x => availableTypes.includes(x.type))
          .map(module => (
            <ListItem
              key={module.type}
              autoFocus
              button
              onClick={() =>
                handleListItemClick(module.type, module.initialModuleBody)
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={module.name} />
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
};

export default SelectModuleDialog;
