import TabLayout from '@components/layouts/TabLayout';
import UserDetailsSummary from '@pages/user/details/_subs/UserDetailsSummary';
import UserDetailsRolesAndPermissions from '@pages/user/details/_subs/UserDetailsRolesAndPermissions';

const UserDetails = props => {
  const { user } = props;
  const tabs = [
    {
      title: '概要',
      key: 'summary',
      component: () => {
        return <UserDetailsSummary {...props} />;
      },
    },
    {
      title: '身分與權限',
      key: 'roles-and-permissions',
      component: () => {
        return <UserDetailsRolesAndPermissions {...props} />;
      },
    },
  ];
  return (
    <TabLayout
      buttonRightTitle="編輯用戶"
      buttonRightTo={`/cms-user/${user.id}/edit`}
      tabs={tabs}
    />
  );
};

export default UserDetails;
