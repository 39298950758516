import AppDatatable from '@components/_common/AppDatatable';
import useAppService from '@hooks/use-app-service';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MenuItem } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import { useEffect, useState } from 'react';
import { useDebounce } from '@hooks/use-debounce';
import BaseLayout from '@components/layouts/BaseLayout';
import { getStatusChip, getStatusList } from '@constants/status';
import AppFormControlSelect from '@components/_common/AppFormControlSelect';
import useQueryParams from '@hooks/use-query-params';
import { format, parseISO } from 'date-fns';
import AppButton from '@components/_common/AppButton';
import { saveAs } from 'file-saver';
import useSessionToken from '@hooks/redux/use-session-token';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';

const MemberList = () => {
  const {
    value: { status: queryStatus, search: querySearch },
  } = useQueryParams();
  const appService = useAppService();
  const { value: sessionToken } = useSessionToken();
  const [status, setStatus] = useState(queryStatus || 'All');
  const [search, setSearch] = useState(querySearch || '');
  const [exporting, setExporting] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const [filter, setFilter] = useState({
    status,
    search,
  });
  const pageSize = 20;

  useEffect(() => {
    if (filter.status !== status || filter.search !== debouncedSearch) {
      setFilter({
        status,
        search: debouncedSearch,
      });
    }
  }, [status, debouncedSearch, filter.status, filter.search]);

  const renderHeaderLeft = () => (
    <>
      <AppFormControlSelect
        width={120}
        label="狀態"
        value={status}
        onChange={setStatus}
      >
        <MenuItem value="All">全部</MenuItem>
        {getStatusList('User').map(x => (
          <MenuItem key={x.status} value={x.status}>
            {x.label}
          </MenuItem>
        ))}
      </AppFormControlSelect>
      <FormInputField
        width={320}
        placeholder={'搜尋用戶'}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
    </>
  );

  const renderHeaderRight = () => {
    const exportUserList = () => {
      setExporting(true);
      const request = new XMLHttpRequest();
      request.open('GET', `${process.env.REACT_APP_API_URL}/user/export`, true);
      request.setRequestHeader('Authorization', `Bearer ${sessionToken}`);
      request.responseType = 'blob';
      request.onload = function () {
        saveAs(request.response, 'user-export.xlsx');
        setExporting(false);
      };
      request.send();
    };
    return exporting ? (
      <AppLoadingSpinner size={42} />
    ) : (
      <AppButton onClick={exportUserList}>匯出</AppButton>
    );
  };

  return (
    <BaseLayout
      headerLeft={renderHeaderLeft()}
      headerRight={renderHeaderRight()}
    >
      <AppDatatable
        negativeMx
        syncQueryParams
        defaultSortFieldId="createDate"
        defaultSortAsc={false}
        columns={[
          {
            name: '電話號碼',
            selector: row => row.mobileNumber,
            sortable: false,
            sortField: 'mobileNumber',
          },
          {
            name: '花名',
            selector: row => row.nickname,
            sortable: true,
            sortField: 'nickname',
          },
          {
            name: '電郵地址',
            selector: row => row.email,
            sortable: false,
            sortField: 'email',
          },
          {
            name: '狀態',
            selector: row => row.status,
            sortable: true,
            cell: row => {
              return getStatusChip('User', row.status);
            },
          },
          {
            name: '建立日期',
            selector: row => row.createDate,
            width: '180px',
            sortable: true,
            sortField: 'createDate',
            cell: row => {
              return format(parseISO(row.createDate), 'yyyy-MM-dd HH:mm:ss');
            },
          },
          {
            id: 'action',
            name: '',
            width: '80px',
            right: true,
            cell: row => (
              <TooltipIconButton
                title="前往"
                color="primary"
                Icon={NavigateNextIcon}
                to={`/member/${row.id}`}
              />
            ),
          },
        ]}
        pageSize={pageSize}
        onLayout$={appService.user.getUserList}
        otherParams={filter}
      />
    </BaseLayout>
  );
};

export default MemberList;
