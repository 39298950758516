import AppDatatable from '@components/_common/AppDatatable';
import useAppService from '@hooks/use-app-service';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MenuItem } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import { useEffect, useState } from 'react';
import { useDebounce } from '@hooks/use-debounce';
import AppButton from '@components/_common/AppButton';
import BaseLayout from '@components/layouts/BaseLayout';
import { getStatusList } from '@constants/status';
import AppFormControlSelect from '@components/_common/AppFormControlSelect';
import { format, parseISO } from 'date-fns';

const QuestionnaireList = () => {
  const appService = useAppService();
  const [status, setStatus] = useState('All');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [filter, setFilter] = useState({
    status,
    search,
  });
  const pageSize = 20;

  useEffect(() => {
    if (filter.status !== status || filter.search !== debouncedSearch) {
      setFilter({
        status,
        search: debouncedSearch,
      });
    }
  }, [status, debouncedSearch, filter.status, filter.search]);

  const renderHeaderLeft = () => (
    <>
      <AppFormControlSelect
        width={120}
        label="狀態"
        value={status}
        onChange={setStatus}
      >
        <MenuItem value="All">全部</MenuItem>
        {getStatusList('Questionnaire').map(x => (
          <MenuItem key={x.status} value={x.status}>
            {x.label}
          </MenuItem>
        ))}
      </AppFormControlSelect>
      <FormInputField
        width={320}
        placeholder={'搜尋文章'}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
    </>
  );

  const renderHeaderRight = () => (
    <AppButton to="/questionnaire/create">新增問卷</AppButton>
  );

  return (
    <BaseLayout
      headerLeft={renderHeaderLeft()}
      headerRight={renderHeaderRight()}
    >
      <AppDatatable
        negativeMx
        syncQueryParams
        defaultSortField="createDate"
        defaultSortAsc={false}
        columns={[
          {
            name: '名稱',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
          },
          {
            name: '創建日期',
            selector: row => row.createDate,
            width: '200px',
            sortable: true,
            sortField: 'createDate',
            cell: row => {
              return format(parseISO(row.createDate), 'yyyy-MM-dd HH:mm:ss');
            },
          },
          {
            name: '更新日期',
            selector: row => row.updateDate,
            width: '200px',
            sortable: true,
            sortField: 'updateDate',
            cell: row => {
              return format(parseISO(row.updateDate), 'yyyy-MM-dd HH:mm:ss');
            },
          },
          {
            id: 'action',
            name: '',
            width: '80px',
            right: true,
            cell: row => (
              <TooltipIconButton
                title="前往"
                color="primary"
                Icon={NavigateNextIcon}
                to={`/questionnaire/${row.id}`}
              />
            ),
          },
        ]}
        pageSize={pageSize}
        onLayout$={appService.questionnaire.getQuestionnaireList}
        otherParams={filter}
      />
    </BaseLayout>
  );
};

export default QuestionnaireList;
