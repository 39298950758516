import TabLayout from '@components/layouts/TabLayout';
import SelectOptionCategoryList from '@pages/select-option/_subs/SelectOptionCategoryList';

const SelectOption = props => {
  const { tab } = props;
  const tabs = [
    {
      title: '照顧對象',
      key: 'target',
      url: `/select-option/target`,
      component: () => {
        return (
          <SelectOptionCategoryList
            title="照顧對象"
            category="Target"
            {...props}
          />
        );
      },
    },
    {
      title: '照顧情況',
      key: 'situation',
      url: `/select-option/situation`,
      component: () => {
        return (
          <SelectOptionCategoryList
            title="照顧情況"
            category="Situation"
            {...props}
          />
        );
      },
    },
    {
      title: '想了解的範疇',
      key: 'into-area',
      url: `/select-option/into-area`,
      component: () => {
        return (
          <SelectOptionCategoryList
            title="想了解的範疇"
            category="IntoArea"
            {...props}
          />
        );
      },
    },
    {
      title: '想了解的病症',
      key: 'into-illness',
      url: `/select-option/into-illness`,
      component: () => {
        return (
          <SelectOptionCategoryList
            title="想了解的病症"
            category="IntoIllness"
            {...props}
          />
        );
      },
    },
  ];
  return (
    <TabLayout initialValue={tabs.map(x => x.key).indexOf(tab)} tabs={tabs} />
  );
};

export default SelectOption;
