const cmsUser = request => ({
  getUserRoleList: () =>
    request({
      method: 'get',
      endpoint: `/cms-user/roles`,
    }),

  getUserList: params =>
    request({
      method: 'get',
      endpoint: `/cms-user/list`,
      params: params,
    }),

  getUser: id =>
    request({
      method: 'get',
      endpoint: `/cms-user/${id}`,
    }),

  createUser: body =>
    request({
      method: 'post',
      endpoint: '/cms-user',
      data: body,
    }),

  updateUser: (id, body) =>
    request({
      method: 'put',
      endpoint: `/cms-user/${id}`,
      data: body,
    }),

  sendResetPasswordEmail: id =>
    request({
      method: 'post',
      endpoint: `/cms-user/${id}/send-reset-password-email`,
    }),

  enableUser: id =>
    request({
      method: 'post',
      endpoint: `/cms-user/${id}/enable`,
    }),

  disableUser: id =>
    request({
      method: 'post',
      endpoint: `/cms-user/${id}/disable`,
    }),
});

export default cmsUser;
