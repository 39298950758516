import { Navigate, Route, Routes } from 'react-router-dom';
import QuestionnaireList from '@pages/questionnaire/QuestionnaireList';
import QuestionnaireDetails from '@pages/questionnaire/details';
import withPage from '@hocs/with-page';
import QuestionnaireForm from '@pages/questionnaire/QuestionnaireForm';

const Questionnaire = () => {
  return (
    <Routes>
      <Route path="list" Component={QuestionnaireList} />
      <Route path="create" Component={QuestionnaireForm.Create} />
      <Route path=":id/*" Component={QuestionnaireDetails} />
      <Route path="*" element={<Navigate to="list" />} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '問卷',
    pathname: '/questionnaire/list',
  },
};

export default withPage(page)(Questionnaire);
