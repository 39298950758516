import BaseLayout from '@components/layouts/BaseLayout';
import { Grid } from '@mui/material';
import useFormUtilities from '@hooks/use-form-utilities';

const UserDetailsRolesAndPermissions = ({ user }) => {
  const { renderLabelValue } = useFormUtilities();
  return (
    <BaseLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {renderLabelValue({ label: '身分', value: user.role.name })}
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default UserDetailsRolesAndPermissions;
