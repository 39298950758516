import { Route, Routes } from 'react-router-dom';
import BreastCancerSurgeryPage from './BreastCancerSurgery';
import withPage from '@hocs/with-page';

const BreastCancerSurgery = () => {
  const indexSubpages = ['settings'];
  return (
    <Routes>
      <Route index element={<BreastCancerSurgeryPage tab="settings" />} />
      {indexSubpages.map(x => (
        <Route path={x} key={x} element={<BreastCancerSurgeryPage tab={x} />} />
      ))}
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '乳癌治療計算機',
    pathname: '/breast-cancer-surgery',
  },
};

export default withPage(page)(BreastCancerSurgery);
