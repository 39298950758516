import * as Yup from 'yup';

const schema = Yup.object().shape({
  type: Yup.string().when('global', {
    is: false,
    then: schema => schema.required('validation.chargeTypeRequired'),
  }),
  region: Yup.string().when('global', {
    is: false,
    then: schema => schema.required('validation.chargeRegionRequired'),
  }),
  place: Yup.string().when('global', {
    is: false,
    then: schema => schema.required('validation.chargePlaceRequired'),
  }),
  cost: Yup.number(),
  costMax: Yup.number(),
  remarks: Yup.string(),
  link: Yup.string().matches(
    /(^(http|https):\/\/|^\/)/,
    'validation.linkInvalid'
  ),
});

export default schema;
