import { Box, styled } from '@mui/material';
import useI18n from '@hooks/use-i18n';
import AppTypography from '@components/_common/AppTypography';
import AppLoadingDot from '@components/_common/AppLoadingDot';
import AppLink from '@components/_common/AppLink';

const Root = styled(Box)(({ theme }) => ({
  paddingLeft: 16,
  paddingRight: 16,
  height: 42,
  outline: 0,
  borderRadius: 6,
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s ' + theme.transitions.easing.easeInOut,
  '& .MuiTypography-root': {
    transition: 'all 0.3s ' + theme.transitions.easing.easeInOut,
  },
  '&[disabled]': {
    opacity: 0.5,
    cursor: 'default',
  },

  '@media (hover: hover)': {
    '&:not([disabled]):hover': {
      backgroundColor: '#BC72DA',
    },

    '&:not([disabled])::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      opacity: 0,
      transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
      zIndex: 1,
    },
    '&:not([disabled]):hover::after': {
      opacity: 1,
    },
  },
  '&:not([disabled]):active': {
    transform: 'scale(0.95)',
  },
}));

const AppButton = ({
  to,
  type = 'button',
  large,
  i,
  sx,
  showLoading,
  children,
  ...props
}) => {
  const { __i } = useI18n();
  const renderButton = () => (
    <Root
      component={!to ? 'button' : 'div'}
      type={!to ? type : undefined}
      sx={{
        ...sx,
        height: large ? 48 : undefined,
      }}
      {...props}
    >
      <AppTypography
        component="span"
        variant="subtitle1"
        color="text.primary"
        sx={{ fontSize: large ? 16 : 14 }}
      >
        {i ? __i(i) : children}
      </AppTypography>
    </Root>
  );

  if (showLoading) {
    return (
      <Root
        sx={{
          ...sx,
          height: large ? 48 : undefined,
          cursor: 'not-allowed',
        }}
      >
        <AppLoadingDot white />
      </Root>
    );
  }
  return to ? <AppLink to={to} children={renderButton()} /> : renderButton();
};

export default AppButton;
