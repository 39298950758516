import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import UserDetailsPage from './UserDetails';
import withFetchModelWithId from '@hocs/with-fetch-model-with-id';
import withPage from '@hocs/with-page';
import UserForm from '@pages/user/UserForm';

const UserDetails = ({ fetchedModel, ...props }) => {
  const [user, setUser] = useState(fetchedModel);
  const _props = {
    ...props,
    user,
    setUser,
  };

  return (
    <Routes>
      <Route index element={<UserDetailsPage {..._props} />} />
      <Route path="edit" element={<UserForm.Edit {..._props} />} />
    </Routes>
  );
};

const page = {
  level: 1,
  route: {
    title: '',
    pathname: '/cms-user/:id',
    fetching: true,
  },
};

export default withPage(page)(
  withFetchModelWithId({
    serviceName: 'cmsUser',
    promiseName: 'getUser',
    fallbackUrl: '/cms-user/list',
    title: '{name}',
    page: page,
  })(UserDetails)
);
