import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setSessionToken } from '@redux/slices/app';

const useSessionToken = () => {
  const dispatch = useDispatch();
  const value = useSelector(state => state.sessionToken);
  const setValue = useCallback(
    newValue => {
      dispatch(setSessionToken(newValue));
    },
    [dispatch]
  );
  return {
    value,
    setValue,
  };
};

export default useSessionToken;
