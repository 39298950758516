import { Route, Routes } from 'react-router-dom';
import SelectOptionPage from './SelectOption';
import withPage from '@hocs/with-page';

const BreastCancer = () => {
  const indexSubpages = ['target', 'situation', 'into-area', 'into-illness'];
  return (
    <Routes>
      <Route index element={<SelectOptionPage tab="target" />} />
      {indexSubpages.map(x => (
        <Route path={x} key={x} element={<SelectOptionPage tab={x} />} />
      ))}
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '會員問卷選項',
    pathname: '/select-option',
  },
};

export default withPage(page)(BreastCancer);
