import { Route, Routes } from 'react-router-dom';
import CommonPage from './Common';
import withPage from '@hocs/with-page';

const Home = () => {
  return (
    <Routes>
      <Route index Component={CommonPage} />
    </Routes>
  );
};

const page = {
  level: 0,
  route: {
    title: '共用內容',
    pathname: '/common',
  },
};

export default withPage(page)(Home);
