import * as Yup from 'yup';

const schema = Yup.object().shape({
  password: Yup.string().required('validation.currentPasswordRequired'),
  newPassword: Yup.string().required('validation.newPasswordRequired'),
  newPasswordConfirm: Yup.string()
    .required('validation.newPasswordConfirmRequired')
    .oneOf(
      [Yup.ref('newPassword'), null],
      'validation.newPasswordConfirmNotMatch'
    ),
});

export default schema;
