import DataTable from 'react-data-table-component';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import useQueryParams from '@hooks/use-query-params';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';
import { Box, styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { omitNullOrUndefined } from '@utilities/common';
import AppTypography from '@components/_common/AppTypography';
import useWindowSize from '@hooks/use-window-size';

const Root = styled(Box)(({ theme }) => ({
  '.rdt_TableHeadRow, .rdt_TableRow': {
    position: 'relative',
    color: theme.palette.text.primary,
  },
  '.rdt_TableCol_Sortable': {
    fontSize: 14,
  },
  '.rdt_TableCell': {
    fontSize: 14,

    '&[data-column-id="action"]': {
      position: 'sticky',
      top: 0,
      right: 0,
      minHeight: 48,
      backgroundColor: 'white',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 2px 12px 0px;',
        clipPath: 'polygon(-20px 0, -20px 100%, 100% 100%, 100% 0)',
        transition: 'all 0.25s',
      },
    },
  },
}));

const AppDatatable = forwardRef(
  (
    {
      offsetHeaderTop,
      negativeMx,
      staticData,
      headerLeft,
      columns,
      pageSize: defaultPageSize,
      defaultSortFieldId,
      defaultSortAsc,
      onClickRow,
      onSelectedRows,
      onLayout$,
      otherParams,
      syncQueryParams,
      noPagination,
      onLayout,
      ...props
    },
    ref
  ) => {
    const {
      value: {
        page: queryPage,
        pageSize: queryPageSize,
        sortField: querySortField,
        sortAsc: querySortAsc,
      },
      setValue: setQueryParams,
    } = useQueryParams();
    const windowWidth = useWindowSize()[0];
    const [data, setData] = useState(null);
    const [totalCount, setTotalCount] = useState(null);
    const [serverError, setServerError] = useState(false);
    const [page, setPage] = useState(syncQueryParams ? +queryPage || 0 : 0);
    const [pageSize, setPageSize] = useState(
      syncQueryParams
        ? +queryPageSize || defaultPageSize || 10
        : defaultPageSize || 10
    );
    const [sort, setSort] = useState(
      syncQueryParams && querySortField
        ? {
            selector: querySortField,
            direction: querySortAsc === 'false' ? 'desc' : 'asc',
          }
        : defaultSortFieldId
        ? {
            selector: defaultSortFieldId,
            direction: defaultSortAsc === false ? 'desc' : 'asc',
          }
        : null
    );
    const [showActionShadow, setShowActionShadow] = useState(false);
    const params = useRef(null);
    const id = useRef(`dt-${Math.random().toString(36).substring(7)}`);

    // Action shadow
    useEffect(() => {
      let table = null;
      let eventListener = null;
      if (data) {
        table = document.querySelector(`#${id.current} > :first-child`);
        if (table) {
          const tableWrapper = table.querySelector('.rdt_Table');
          if (tableWrapper) {
            eventListener = () => {
              const totalWidth = table.scrollLeft + table.offsetWidth;
              setShowActionShadow(
                Math.ceil(totalWidth) < Math.ceil(tableWrapper.offsetWidth)
              );
            };
            table.addEventListener('scroll', eventListener);

            eventListener();
          }
        }
      }
      return () => {
        if (table) {
          table.removeEventListener('scroll', eventListener);
        }
      }; // eslint-disable-next-line
    }, [windowWidth, data]);

    // Set query params
    useEffect(() => {
      if (syncQueryParams) {
        const newQueryParams = {
          page,
          pageSize,
          sortField: sort?.selector,
          sortAsc: sort?.direction === 'asc',
          ...otherParams,
        };
        if (noPagination) {
          delete newQueryParams['page'];
          delete newQueryParams['pageSize'];
        }
        setQueryParams(newQueryParams);
      }
    }, [
      syncQueryParams,
      noPagination,
      page,
      pageSize,
      sort?.direction,
      sort?.selector,
      otherParams,
      setQueryParams,
    ]);

    useImperativeHandle(ref, () => ({
      reload() {
        fetchList(params.current);
      },
    }));

    const fetchList = useCallback(
      params => {
        setData(null);
        onLayout$ &&
          !serverError &&
          onLayout$(params)
            .then(res => {
              setData(res.items);
              setTotalCount(res.totalCount);
              if (onLayout) {
                onLayout([...res.items]);
              }
            })
            .catch(() => {
              setServerError(true);
            });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [serverError]
    );

    useEffect(() => {
      if (staticData) {
        setData(staticData);
        setTotalCount(staticData.length);
      }
    }, [staticData]);

    const otherParamsJson = JSON.stringify(otherParams || {});
    useEffect(() => {
      if (!staticData) {
        params.current = {
          sortField: sort && sort.selector,
          sortOrder: sort && sort.direction,
          page,
          pageSize,
          ...JSON.parse(otherParamsJson),
        };
        fetchList(omitNullOrUndefined(params.current));
      }
    }, [page, sort, staticData, pageSize, fetchList, otherParamsJson]);

    const handleOnChangePage = page => {
      setPage(page - 1);
    };

    const handleOnChangePageSize = (newPerPage, page) => {
      setPageSize(newPerPage);
    };

    const handleSort = (column, direction) => {
      const sortField = column.sortField;
      setSort({
        selector: sortField,
        direction,
      });
    };

    return (
      <Root
        id={id.current}
        mt={offsetHeaderTop ? -2 : 0}
        mx={negativeMx ? -2 : 0}
        sx={{
          '.rdt_TableCell[data-column-id="action"]::before': {
            opacity: showActionShadow ? 1 : 0,
          },
        }}
      >
        {headerLeft && (
          <Box
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box children={headerLeft} />
          </Box>
        )}
        <DataTable
          noHeader
          noDataComponent={
            <AppTypography px={3} py={5}>
              沒有資料
            </AppTypography>
          }
          progressPending={!data}
          progressComponent={<AppLoadingSpinner p={2} />}
          defaultSortFieldId={defaultSortFieldId}
          defaultSortAsc={defaultSortAsc}
          columns={columns}
          data={data || undefined}
          noContextMenu
          highlightOnHover={!!onClickRow}
          pointerOnHover={!!onClickRow}
          sortServer={!staticData}
          pagination={!noPagination}
          paginationServer
          paginationDefaultPage={+page + 1}
          paginationTotalRows={totalCount || undefined}
          paginationPerPage={pageSize}
          paginationRowsPerPageOptions={[20, 50, 100]}
          paginationComponentOptions={{
            rowsPerPageText: '每頁行數',
            rangeSeparatorText: '，共有',
            // selectAllRowsItem: true,
            // selectAllRowsItemText: 'All',
          }}
          sortIcon={<ArrowDropDownIcon />}
          onChangePage={handleOnChangePage}
          onChangeRowsPerPage={handleOnChangePageSize}
          onRowClicked={onClickRow}
          // selectableRows
          // selectableRowsComponent={Checkbox}
          // onSelectedRowsChange={state => onSelectedRows && onSelectedRows(state.selectedRows)}
          onSort={handleSort}
          customStyles={{
            rows: {
              style: {
                minHeight: '48px',
              },
            },
            // headCells: {
            //   style: {
            //     paddingLeft: '8px',
            //     paddingRight: '8px',
            //   },
            // },
            cells: {
              style: {
                paddingTop: '4px',
                paddingBottom: '4px',
              },
            },
          }}
          {...props}
        />
      </Root>
    );
  }
);

export default AppDatatable;
