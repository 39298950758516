import React, { useMemo, useState } from 'react';
import BaseLayout from '@components/layouts/BaseLayout';
import withFetchPage from '@hocs/with-fetch-page';
import { Formik } from 'formik';
import useAppService from '@hooks/use-app-service';
import { useSnackbar } from 'notistack';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import FormInputField from '@components/forms/FormInputField';
import AppButton from '@components/_common/AppButton';
import AppTypography from '@components/_common/AppTypography';
import FabAdd from '@components/_common/FabAdd';
import { getObjectPathValue, swapListItem } from '@utilities/common';
import Portlet from '@components/layouts/Portlet';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import FormSelectField from '@components/forms/FormSelectField';
import FormAssetUpload from '@components/forms/FormAssetUpload';
import schemaFooter from '@validations/footer.schema';

const BannerRows = props => {
  const { formProps, editMode } = props;
  const { values, setValues, submitCount, errors } = formProps;

  const handleMoveUp = index => () => {
    const newBannerRows = swapListItem(values.bannerRows, index, index - 1);
    setValues({
      ...values,
      bannerRows: newBannerRows,
    });
  };
  const handleMoveDown = index => () => {
    const newBannerRows = swapListItem(values.bannerRows, index, index + 1);
    setValues({
      ...values,
      bannerRows: newBannerRows,
    });
  };
  const handleDelete = index => () => {
    let newBannerRows = [...values.bannerRows];
    newBannerRows.splice(index, 1);
    setValues({
      ...values,
      bannerRows: newBannerRows,
    });
  };
  const handleClickAddBannerRowItem = index => () => {
    const defaultBannerRowItem = {
      image: null,
      imageWidth: '',
      imageHeight: '',
      title: '',
      titleOffsetY: '0',
      action: 'None',
      actionLink: '',
    };
    let newBannerRows = [...values.bannerRows];
    newBannerRows[index].items = [
      ...newBannerRows[index].items,
      defaultBannerRowItem,
    ];
    setValues({
      ...values,
      bannerRows: newBannerRows,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        {values.bannerRows.map((question, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <Portlet
                headerPaddingX={0}
                headerPaddingY={0}
                contentPaddingX={0}
                contentPaddingBottom={0}
                headerHeight={42}
                titleComponent={
                  <>
                    <Box width={1} display="flex" alignItems="center">
                      <AppTypography variant="h5" width={64} flexShrink={0}>
                        第{index + 1}行
                      </AppTypography>

                      <Box flex={1}>
                        <Divider />
                      </Box>
                    </Box>
                  </>
                }
                headerRight={
                  <Box
                    sx={!editMode ? { opacity: 0, pointerEvents: 'none' } : {}}
                  >
                    <IconButton
                      aria-label="move up"
                      onClick={handleMoveUp(index)}
                      disabled={index === 0}
                    >
                      <KeyboardArrowUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="move down"
                      onClick={handleMoveDown(index)}
                      disabled={index === values.bannerRows.length - 1}
                    >
                      <KeyboardArrowDownIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={handleDelete(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                }
                paddingContent
                doubleShadow
              >
                <Box mt={2} ml={8}>
                  {(values.bannerRows[index].items ?? []).length > 0 && (
                    <BannerRowItems
                      index={index}
                      formProps={formProps}
                      editMode={editMode}
                    />
                  )}
                  {submitCount > 0 &&
                    errors.questions &&
                    errors.questions[index] &&
                    errors.questions[index].items &&
                    typeof errors.questions[index].items === 'string' && (
                      <AppTypography
                        color="error.main"
                        children={errors.questions[index].items}
                      />
                    )}
                </Box>
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FabAdd
                    text="新增項目"
                    onClick={handleClickAddBannerRowItem(index)}
                  />
                </Box>
              </Portlet>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
};

const BannerRowItems = props => {
  const { index: bannerRowIndex, formProps, editMode } = props;
  const { values, touched, errors, setValues, setFieldValue } = formProps;

  const items = values.bannerRows[bannerRowIndex].items;

  const updateBannerRowItems = newItems => {
    let newBannerRows = [...values.bannerRows];
    newBannerRows[bannerRowIndex].items = newItems;
    setValues({
      ...values,
      bannerRows: newBannerRows,
    });
  };

  const handleMoveUp = index => () => {
    const newItems = swapListItem(items, index, index - 1);
    updateBannerRowItems(newItems);
  };
  const handleMoveDown = index => () => {
    const newItems = swapListItem(items, index, index + 1);
    updateBannerRowItems(newItems);
  };
  const handleDelete = index => () => {
    let newItems = [...items];
    newItems.splice(index, 1);
    updateBannerRowItems(newItems);
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <Box display="flex">
                <Box flex={1} display="flex">
                  <Box width={32} flexShrink={0}>
                    <AppTypography variant="h5" children={`${index + 1}`} />
                  </Box>
                  <Box pt={0.25} flex={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <FormAssetUpload
                          image
                          freeFormCrop
                          withAssetDescription
                          label="圖片"
                          ratio={1}
                          asset={items[index].image}
                          onUploadedFile={asset =>
                            setFieldValue(
                              `bannerRows.${bannerRowIndex}.items.${index}.image`,
                              asset
                            )
                          }
                          errorMessage={
                            getObjectPathValue(
                              touched,
                              `bannerRows.${bannerRowIndex}.items.${index}.image`
                            ) &&
                            getObjectPathValue(
                              errors,
                              `bannerRows.${bannerRowIndex}.items.${index}.image`
                            )
                          }
                          disabled={!editMode}
                        />

                        <Box mt={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormInputField
                                id={`bannerRows.${bannerRowIndex}.items.${index}.imageWidth`}
                                label="圖片闊度 (px)"
                                mask="9999"
                                formProps={formProps}
                                disabled={!editMode}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputField
                                id={`bannerRows.${bannerRowIndex}.items.${index}.imageHeight`}
                                label="圖片高度 (px)"
                                mask="9999"
                                formProps={formProps}
                                disabled={!editMode}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={8}>
                                <FormInputField
                                  id={`bannerRows.${bannerRowIndex}.items.${index}.title`}
                                  label="標題"
                                  formProps={formProps}
                                  disabled={!editMode}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormInputField
                                  id={`bannerRows.${bannerRowIndex}.items.${index}.titleOffsetY`}
                                  label="標題上下微調 (px)"
                                  mask="9999"
                                  formProps={formProps}
                                  disabled={!editMode}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <FormSelectField
                              id={`bannerRows.${bannerRowIndex}.items.${index}.action`}
                              label="點搫處理"
                              options={[
                                {
                                  label: '沒有',
                                  value: 'None',
                                },
                                {
                                  label: '開啟連結',
                                  value: 'Link',
                                },
                                {
                                  label: '乳癌計算機',
                                  value: 'BreastCancerCalculator',
                                },
                                {
                                  label: '乳癌治療計算機',
                                  value: 'BreastCancerSurgeryCalculator',
                                },
                              ]}
                              formProps={formProps}
                              disabled={!editMode}
                            />
                          </Grid>
                          {items[index].action === 'Link' && (
                            <Grid item xs={12}>
                              <FormInputField
                                id={`bannerRows.${bannerRowIndex}.items.${index}.actionLink`}
                                label="連結"
                                formProps={formProps}
                                disabled={!editMode}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  flexShrink={0}
                  sx={!editMode ? { opacity: 0, pointerEvents: 'none' } : {}}
                >
                  <IconButton
                    aria-label="move up"
                    onClick={handleMoveUp(index)}
                    disabled={index === 0}
                  >
                    <KeyboardArrowUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="move down"
                    onClick={handleMoveDown(index)}
                    disabled={index === items.length - 1}
                  >
                    <KeyboardArrowDownIcon fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={handleDelete(index)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 1 }} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

const Footer = ({ page, setPage, pageName }) => {
  const appService = useAppService();
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);
  const initialValues = useMemo(
    () => ({
      bannerRows: page?.bannerRows || [],
    }),
    [page]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        appService.page
          .updatePage({
            pagePath: pageName,
            body: values,
          })
          .then(res => {
            enqueueSnackbar(`網頁底部已更新`);
            setSubmitting(false);
            setPage(res);
            setEditMode(false);
          }, appService.handleError);
      }}
      validationSchema={schemaFooter}
      enableReinitialize
      validateOnMount
    >
      {formProps => {
        const { values, setValues, handleSubmit, isSubmitting } = formProps;

        const handleClickAddBannerRow = () => {
          const defaultBannerRow = {
            question: '',
            items: [
              {
                image: null,
                imageWidth: '',
                imageHeight: '',
                title: '',
                titleOffsetY: '0',
                action: 'None',
                actionLink: '',
              },
            ],
          };
          setValues({
            ...values,
            bannerRows: [...values.bannerRows, defaultBannerRow],
          });
        };

        return (
          <BaseLayout
            title="橫額"
            headerRight={
              <>
                {editMode ? (
                  <AppButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                  >
                    更新
                  </AppButton>
                ) : (
                  <AppButton onClick={() => setEditMode(true)}>編輯</AppButton>
                )}
              </>
            }
            noMb
          >
            <form onSubmit={handleSubmit}>
              <Box pt={2}>
                {values.bannerRows.length > 0 && (
                  <BannerRows formProps={formProps} editMode={editMode} />
                )}
              </Box>
              {editMode && (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FabAdd
                    text="新增橫額行數"
                    onClick={handleClickAddBannerRow}
                  />
                </Box>
              )}
            </form>
          </BaseLayout>
        );
      }}
    </Formik>
  );
};

export default withFetchPage({
  pageName: 'footer',
})(Footer);
