import { useSnackbar } from 'notistack';
import useAppService from '@hooks/use-app-service';
import { useCallback, useRef, useState } from 'react';
import RatioLayout from '@components/layouts/RatioLayout';
import AppTypography from '@components/_common/AppTypography';
import { Box, Fab, Tooltip } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import AppLoadingSpinner from '@components/_common/AppLoadingSpinner';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import CropImageDialog from '@components/dialogs/CropImageDialog';
import EditIcon from '@mui/icons-material/Edit';
import TextareaDialog from '@components/dialogs/TextareaDialog';

const Dropzone = ({
  className,
  onSelectedFile,
  image,
  imageLarge,
  video,
  small,
  disableCompression,
  placeholder,
  ...props
}) => {
  const [compressing, setCompressing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleOnFilesDragged = acceptedFiles => {
    if (onSelectedFile) {
      const file = acceptedFiles[0];
      const isImage =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/webp';
      console.log('file', file.size, file);
      if (isImage && !disableCompression) {
        setCompressing(true);
        imageCompression(file, {
          maxSizeMB: 3,
        }).then(compressedFile => {
          console.log('compressedFile', compressedFile.size, compressedFile);
          onSelectedFile(compressedFile);
          setCompressing(false);
        });
      } else {
        onSelectedFile(file);
      }
    }
  };
  const getMaxSize = () => {
    if (image && video) {
      return 15 * 1000 * 1000;
    } else if (image) {
      return (imageLarge ? 3 : 0.5) * 1000 * 1000;
    } else if (video) {
      return 15 * 1000 * 1000;
    }
    return 0;
  };
  const getFileAcceptFormat = () => {
    if (image && video) {
      return {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'image/webp': ['.webp'],
        'video/mp4': ['.mp4'],
      };
    } else if (image) {
      return {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'image/webp': ['.webp'],
      };
    } else if (video) {
      return {
        'video/mp4': ['.mp4'],
      };
    }
    return {};
  };
  const getHintMessage = () => {
    if (image && video) {
      return 'Drag & Drop image / video file, or click to select file';
    } else if (image) {
      return 'Drag & Drop image file, or click to select file';
    } else if (video) {
      return 'Drag & Drop video file, or click to select file';
    }
    return '';
  };
  const getHintMessage2 = () => {
    if (image && video) {
      return 'Only JPG or PNG or MP4 formats are accepted.';
    } else if (image) {
      if (imageLarge) {
        return 'Image cannot exceed 3MB and only .jpg or .png or .webp formats are accepted.';
      }
      return 'Image cannot exceed 500KB and only .jpg or .png or .webp formats are accepted.';
    } else if (video) {
      return 'Video cannot exceed 15MB and only MP4 format is accepted.';
    }
    return '';
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: getMaxSize(),
    accept: getFileAcceptFormat(),
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: acceptedFiles => {
      const file = acceptedFiles[0];
      if (
        ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(
          file.type
        ) &&
        file.size >= (imageLarge ? 3 : 0.5) * 1000 * 1000
      ) {
        setErrorMessage('Please upload image file of size less than 500KB');
        return;
      }
      handleOnFilesDragged(acceptedFiles);
      setErrorMessage(null);
    },
    onDropRejected: errors => {
      const file = errors[0].file;
      if (file.size > (imageLarge ? 3 : 0.5) * 1000 * 1000) {
        setErrorMessage('Please upload file of size less than 500KB');
      } else if (
        !['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(
          file.type
        )
      ) {
        setErrorMessage('Please upload file with specified accepted formats');
      }
    },
  });
  return (
    <Box {...props} {...getRootProps({ className: className })}>
      <input {...getInputProps()} />

      <Box
        p={3}
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        onClick={open}
        sx={{ cursor: 'pointer' }}
      >
        {compressing ? (
          <Box
            width={1}
            height={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AppLoadingSpinner />
          </Box>
        ) : (
          <>
            {placeholder ? (
              placeholder
            ) : (
              <>
                <PhotoLibraryIcon />
                {!small && (
                  <>
                    <AppTypography
                      mt={1}
                      align="center"
                      children={getHintMessage()}
                    />
                    {errorMessage && (
                      <AppTypography
                        mt={1}
                        color="errorMain"
                        align="center"
                        children={errorMessage}
                      />
                    )}
                    <AppTypography
                      mt={1}
                      align="center"
                      children={getHintMessage2()}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

const FormAssetUpload = ({
  mt,
  ml,
  mr,
  mb,
  pt,
  pl,
  pr,
  pb,
  width,
  label,
  ratio,
  small,
  freeFormCrop,
  directUpload,
  disableCompression,
  errorMessage,
  className,
  disabled,
  asset,
  onUploadedFile,
  image,
  imageLarge,
  video,
  placeholder,
  withAssetDescription,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const appService = useAppService();
  const [directUploadImageBase64, setDirectUploadImageBase64] = useState(null);
  const [selectedImageBase64, setSelectedImageBase64] = useState(null);
  const [uploading, setUploading] = useState(false);
  // const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const selectedFile = useRef(null);
  const imageRef = useRef(null);

  const uploadAsset = useCallback(
    formData => {
      setUploading(true);
      appService.asset.uploadAsset(formData).then(res => {
        setDirectUploadImageBase64(null);
        onUploadedFile && onUploadedFile(res);
        setUploading(false);
      }, appService.handleError);
    },
    [appService, onUploadedFile]
  );
  const uploadImage = (imageBase64, width, height) => {
    const dataURLComponents = imageBase64.split(',');
    const byteString =
      dataURLComponents[0].indexOf('base64') >= 0
        ? atob(dataURLComponents[1])
        : decodeURI(dataURLComponents[1]);
    const mimeString = dataURLComponents[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: mimeString });

    const formData = new FormData();
    formData.append('file', blob, selectedFile.current.name);
    formData.append('width', width);
    formData.append('height', height);
    uploadAsset(formData);
  };
  const uploadVideo = videoFile => {
    const formData = new FormData();
    formData.append('file', videoFile);
    uploadAsset(formData);
  };
  const updateAssetDescription = useCallback(
    description => {
      appService.asset
        .updateAssetDescription(asset && asset.id, description)
        .then(res => {
          enqueueSnackbar('圖片 Alt 描述已修改');
          asset.description = description;
        }, appService.handleError);
    },
    [appService, asset, enqueueSnackbar]
  );

  const handleSelectedFile = file => {
    if (
      ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(file.type)
    ) {
      handleSelectedImageFile(file);
    } else if (['video/mp4'].includes(file.type)) {
      handleSelectedVideoFile(file);
    }
  };
  const handleSelectedImageFile = file => {
    selectedFile.current = file;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (!directUpload) {
        setSelectedImageBase64(reader.result);
      } else {
        setDirectUploadImageBase64(reader.result);
      }
    });
    reader.readAsDataURL(file);
  };
  const handleOnImageLoaded = () => {
    const width = imageRef.current.naturalWidth;
    const height = imageRef.current.naturalHeight;
    if (directUploadImageBase64) {
      uploadImage(directUploadImageBase64, width, height);
    }
  };
  const handleHideCropImageDialog = () => {
    setSelectedImageBase64(null);
  };
  const handleCroppedImage = ({ imageBase64, width, height }) => {
    handleHideCropImageDialog();
    uploadImage(imageBase64, width, height);
  };
  const handleSelectedVideoFile = file => {
    uploadVideo(file);
  };
  const handleUpdateAssetDescription = value => {
    setOpenDescriptionDialog(false);
    updateAssetDescription(value);
  };
  return (
    <Box position="relative" {...{ mt, ml, mr, mb, pt, pl, pr, pb, width }}>
      {label && <AppTypography mb={1} children={label} />}

      <RatioLayout
        ratio={ratio || 4 / 3}
        sx={{
          backgroundColor: !asset?.url ? '#F8F8F8' : 'transparent',
          border:
            freeFormCrop || !asset?.url
              ? `1px solid #E8E8E8`
              : `1px solid transparent`,
          borderRadius: 1,
        }}
        {...props}
      >
        {!disabled && !uploading && (
          <Dropzone
            width={1}
            height={1}
            onSelectedFile={handleSelectedFile}
            image={image}
            imageLarge={imageLarge}
            video={video}
            small={small}
            disableCompression={disableCompression}
            placeholder={asset && asset.url ? <></> : placeholder}
          />
        )}
        {directUploadImageBase64 && (
          <Box
            component="img"
            ref={imageRef}
            src={directUploadImageBase64}
            alt=""
            onLoad={handleOnImageLoaded}
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: 1,
              height: 1,
              pointerEvents: 'none',
              objectFit: 'contain',
              opacity: 0,
            }}
          />
        )}
        {asset &&
          ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(
            asset.contentType
          ) && (
            <Box
              component="img"
              src={asset.url}
              alt=""
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: 1,
                height: 1,
                pointerEvents: 'none',
                objectFit: 'contain',
              }}
            />
          )}
        {/*{asset && ['video/mp4'].includes(asset.contentType) && (*/}
        {/*  <>*/}
        {/*    <video*/}
        {/*      src={asset.url}*/}
        {/*      className={clsx(*/}
        {/*        classes.absoluteEdges,*/}
        {/*        classes.pointerEventsNone,*/}
        {/*        classes.objectFitCover*/}
        {/*      )}*/}
        {/*    />*/}
        {/*    <button*/}
        {/*      className={classes.videoPlay}*/}
        {/*      onClick={() => setOpenVideoDialog(true)}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}
        {uploading && (
          <Box
            width={1}
            height={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AppLoadingSpinner />
          </Box>
        )}
      </RatioLayout>

      {errorMessage && (
        <AppTypography mt={0.5} color="error.main" children={errorMessage} />
      )}

      <CropImageDialog
        key={ratio + ''}
        open={!!selectedImageBase64}
        ratio={!freeFormCrop && ratio}
        imageBase64={selectedImageBase64}
        imageType={selectedFile.current && selectedFile.current.type}
        onClose={handleHideCropImageDialog}
        onClickCancel={handleHideCropImageDialog}
        onClickConfirm={handleCroppedImage}
      />

      {/*{asset && ['video/mp4'].includes(asset.contentType) && (*/}
      {/*  <VideoDialog*/}
      {/*    src={asset.url}*/}
      {/*    open={openVideoDialog}*/}
      {/*    handleClose={() => setOpenVideoDialog(false)}*/}
      {/*  />*/}
      {/*)}*/}

      {withAssetDescription && !disabled && asset && asset.id && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: label ? 28 + 16 : 16,
              right: 16,
            }}
          >
            <Tooltip
              title={image ? '修改圖片 Alt 描述' : 'Edit Asset Description'}
            >
              <Fab
                aria-label={
                  image ? '修改圖片 Alt 描述' : 'Edit Asset Description'
                }
                color="primary"
                size="small"
                onClick={() => setOpenDescriptionDialog(true)}
              >
                <EditIcon />
              </Fab>
            </Tooltip>
          </Box>

          <TextareaDialog
            open={openDescriptionDialog}
            title={image ? '修改圖片 Alt 描述' : 'Edit Asset Description'}
            inputLabel={image ? '圖片 Alt 描述' : 'Description'}
            buttonTitle={'儲存'}
            cancelButtonTitle={'取消'}
            initialValue={asset && asset.description}
            onClickCancel={() => setOpenDescriptionDialog(false)}
            onClickConfirm={handleUpdateAssetDescription}
          />
        </>
      )}
    </Box>
  );
};

export default FormAssetUpload;
