import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';
import useAppService from '@hooks/use-app-service';
import { useEffect, useState } from 'react';
import { useDebounce } from '@hooks/use-debounce';
import FormInputField from '@components/forms/FormInputField';
import AppTypography from '@components/_common/AppTypography';
import AppButton from '@components/_common/AppButton';
import { deleteListItem } from '@utilities/common';
import TooltipIconButton from '@components/buttons/TooltipIconButton';
import ClearIcon from '@mui/icons-material/Clear';
import withFormControlWrapper from '@hocs/with-form-control-wrapper';

const Keyword = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  height: 38,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const KeywordAdd = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  width: 38,
  height: 38,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: '#F8F8F8',
  },
}));

const SuggestedKeyword = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

const AddSeoKeywordDialog = props => {
  const { open, initialValue, onClickCancel, onClickConfirm, ...otherProps } =
    props;
  const appService = useAppService();
  const [value, setValue] = useState('');
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const debouncedValue = useDebounce(value, 500);
  useEffect(() => {
    if (open) {
      if (initialValue) {
        setValue(initialValue);
      } else {
        setValue('');
      }
    } else {
      setTimeout(() => {
        setValue('');
        setSuggestedKeywords([]);
      }, 250);
    }
  }, [open, initialValue]);

  useEffect(() => {
    setSuggestedKeywords([]);

    if (debouncedValue !== '') {
      appService.seoKeyword
        .getSeoKeywordList({ search: debouncedValue })
        .then(res => {
          setSuggestedKeywords(res.items.slice(0, 10));
        }, appService.handleError);
    }
  }, [appService, debouncedValue]);

  return (
    <Dialog open={open} onClose={onClickCancel} {...otherProps}>
      <DialogTitle>新增 SEO 關鍵字</DialogTitle>
      <DialogContent style={{ minWidth: 400, overflow: 'visible' }}>
        <Box position="relative">
          <FormInputField
            label="SEO 關鍵字"
            value={value}
            onChange={e => setValue(e.target.value)}
            autoFocus
          />
          {suggestedKeywords.length > 0 && (
            <Box
              maxWidth={352}
              display="flex"
              flexWrap="wrap"
              mt={1}
              mx={-0.5}
              mb={-0.5}
            >
              {suggestedKeywords.map(seoKeyword => (
                <Box p={0.5} key={seoKeyword.id}>
                  <SuggestedKeyword
                    component="button"
                    type="button"
                    onClick={() => onClickConfirm(seoKeyword.keyword)}
                  >
                    <AppTypography>
                      {seoKeyword.keyword} ({seoKeyword.count})
                    </AppTypography>
                  </SuggestedKeyword>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="inherit">
          取消
        </Button>
        <AppButton
          children="新增"
          disabled={value.length === 0}
          onClick={() => onClickConfirm && onClickConfirm(value)}
        />
      </DialogActions>
    </Dialog>
  );
};

const FormSeoKeywords = ({ seoKeywords, onChange, disabled }) => {
  const [openCreateSeoKeywordDialog, setOpenCreateSeoKeywordDialog] =
    useState(false);

  const handleCreateSeoKeyword = seoKeyword => {
    setOpenCreateSeoKeywordDialog(false);
    if (!seoKeywords.includes(seoKeyword.trim())) {
      onChange([...seoKeywords, seoKeyword]);
    }
  };
  const handleDeleteSeoKeyword = index => e => {
    e.stopPropagation();
    const updatedSeoKeywords = deleteListItem(seoKeywords, index);
    onChange(updatedSeoKeywords);
  };

  return (
    <>
      <Box m={-1} display="flex" flexWrap="wrap">
        {seoKeywords.map((x, index) => (
          <Keyword key={x}>
            <AppTypography mr={1} variant="subtitle1" children={x} />
            {!disabled && (
              <TooltipIconButton
                title="Remove"
                size="small"
                Icon={ClearIcon}
                onClick={handleDeleteSeoKeyword(index)}
              />
            )}
          </Keyword>
        ))}
        {!disabled && (
          <KeywordAdd
            component="button"
            type="button"
            onClick={() => setOpenCreateSeoKeywordDialog(true)}
          >
            <AppTypography variant="h5" children="+" />
          </KeywordAdd>
        )}
      </Box>

      <AddSeoKeywordDialog
        open={openCreateSeoKeywordDialog}
        onClickCancel={() => setOpenCreateSeoKeywordDialog(false)}
        onClickConfirm={handleCreateSeoKeyword}
      />
    </>
  );
};

export default withFormControlWrapper(FormSeoKeywords);
