import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setErrorMessage } from '@redux/slices/app';

const useErrorMessage = () => {
  const dispatch = useDispatch();
  const value = useSelector(state => state.errorMessage);
  const setValue = useCallback(
    newValue => {
      dispatch(setErrorMessage(newValue));
    },
    [dispatch]
  );
  return {
    value,
    setValue,
  };
};

export default useErrorMessage;
