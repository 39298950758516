import BaseLayout from '@components/layouts/BaseLayout';
import { Grid } from '@mui/material';
import useFormUtilities from '@hooks/use-form-utilities';
import { getStatusChip } from '@constants/status';

const UserDetailsSummary = ({ user }) => {
  const { renderLabelChildren, renderLabelValue } = useFormUtilities();
  return (
    <BaseLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderLabelChildren({
            label: '狀態',
            children: getStatusChip('CmsUser', user.status, { mt: -0.5 }),
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLabelValue({ label: '名稱', value: user.name })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLabelValue({ label: '電郵地址', value: user.email })}
        </Grid>
        <Grid item xs={12}>
          {renderLabelValue({ label: '備註', value: user.remarks })}
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default UserDetailsSummary;
